import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import {
  createTemplate,
  deleteTemplate,
  getSets,
  updateTemplate,
} from "features/sets/api/setsApi";
import { SetsType } from "features/sets/types/SetsType";

const { Option } = Select;

const SetsScreen = () => {
  const [sets, setSets] = useState<SetsType[]>();
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const [form] = Form.useForm();
  const [pagination, setPagination] = useState<object>();
  const history = useHistory();
  const location = useLocation();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEdit(false);
    form.resetFields();
  };

  const edit = (record: Partial<SetsType> & { key: React.Key }) => {
    setIsModalVisible(true);
    setEdit(true);

    form.setFieldsValue({
      ...record,
      seriesArray: JSON.parse(record.seriesArray),
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
      editable: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "Series Array",
      dataIndex: "seriesArray",
      width: "35%",
      editable: true,
      render: (text: string) => JSON.parse(text).join(", "),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
      editable: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: SetsType) => {
        return (
          <>
            <Typography.Link
              onClick={() => edit(record)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Typography.Link
              onClick={async () => {
                try {
                  await deleteTemplate(record.id);
                } catch (e) {
                  // console.log(e);
                  message.error("Template has assigned sets.");
                }
                getSetsApi();
              }}
            >
              Delete
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const getSetsApi = useCallback(() => {
    setTableLoading(true);
    getSets(qs.parse(location.search))
      .then((res) => {
        console.log("======");
        console.log(res);
        setSets(res.rows);
        setPagination((prevPagination) => {
          return {
            ...prevPagination,
            total: res.total,
          };
        });
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, [location.search]);

  useEffect(() => {
    getSetsApi();
  }, [getSetsApi]);

  const handleTableChange = (pagination) => {
    const values = {
      ...qs.parse(location.search),
      page: pagination.current,
      per: pagination.pageSize,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  const seriesData: any = [];
  for (let i = 1; i <= 50; i++) {
    seriesData.push(
      <Option key={i} value={i}>
        {i}
      </Option>
    );
  }

  const onFinish = async (values: any) => {
    const { id = 0 } = values;
    setIsModalVisible(false);
    setEdit(false);

    id ? await updateTemplate(id, values) : await createTemplate(values);

    form.resetFields();

    getSetsApi();
  };

  return (
    <>
      <Modal
        title={isEdit ? "Update template" : "Add new set base template"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          initialValues={{
            name: "",
            seriesArray: [],
            status: "",
          }}
          onFinish={onFinish}
        >
          <Form.Item name="id" label="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="seriesArray" label="Series Array">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
            >
              {seriesData}
            </Select>
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select>
              <Select.Option value="published">Published</Select.Option>
              <Select.Option value="draft">Draft</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Form component={false}>
        <Button type="primary" style={{ float: "right" }} onClick={showModal}>
          Add Template
        </Button>

        <Table
          loading={tableLoading}
          dataSource={sets}
          columns={columns}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Form>
    </>
  );
};

export default memo(SetsScreen);
