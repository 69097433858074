import { useState } from "react";
import { Button, Modal, Form, Input, Select, message } from "antd";
import { STATUSES } from "features/customCards/consts/customCardsConsts";
const { TextArea } = Input;

const CustomCardStatusPopup = ({ visible, setVisible, withdraw, callback }) => {
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setSelectedStatus(null);
    setVisible(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      callback(values);
      setVisible(false);
    } catch (error: any) {
      if (error instanceof Error) {
        console.error("Error:", error.message);
        message.error(error.message);
      } else if (error.errors) {
        console.error("Validation Failed:", error);
        message.error("Validation Failed");
      } else {
        console.error("Unknown error:", error);
      }
    } finally {
      form.resetFields();
      setSelectedStatus(null);
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title="Review status change"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" name="customCardStatusForm">
          <Form.Item
            name="status"
            label="Status"
            rules={[
              { required: true, message: "Please select a review status!" },
            ]}
          >
            <Select
              placeholder="Select a review status"
              onChange={(value) =>
                setSelectedStatus(typeof value === "string" ? value : null)
              }
            >
              <Select.Option value={STATUSES.APPROVED}>Approved</Select.Option>
              <Select.Option value={STATUSES.REJECTED}>Rejected</Select.Option>
            </Select>
          </Form.Item>
          {selectedStatus === STATUSES.REJECTED ? (
            <Form.Item
              name="rejectReason"
              label="Reject reason"
              initialValue={withdraw?.details}
              rules={[
                {
                  required: selectedStatus === STATUSES.REJECTED,
                  message: "Please provide a reject reason!",
                },
              ]}
            >
              <TextArea placeholder="Enter your reject reason" rows={4} />
            </Form.Item>
          ) : null}
          <Form.Item>
            <Button
              size="middle"
              htmlType="submit"
              onClick={handleOk}
              loading={loading}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CustomCardStatusPopup;
