import React, { PureComponent } from "react";
import styles from "./AuthLayout.module.scss";
import { Row, Col } from "antd";

class AuthLayout extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <Row align="middle" justify="center" className={styles.wrapper}>
        <Col xs={20} className={styles.container}>
          <div className={styles.logoWrapper}>
            <img
              className={styles.logo}
              src={process.env.PUBLIC_URL + "/gfx/zoop_logo_darkblue.png"}
              alt=""
            />
          </div>
          {children}
        </Col>
      </Row>
    );
  }
}

export default AuthLayout;
