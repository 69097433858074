/** Private route imports */
import * as DASHBOARD_ROUTES from "features/dashboard/routers/router.dashboard";
import * as PROFILE_ROUTES from "features/identity/Profile/routers/router.profile";
import * as INFLUENCERS_ROUTES from "features/influencers/routers/router.influencers";
import * as APP_USERS_ROUTES from "features/appUsers/routers/router.appUsers";
import * as SETS_ROUTES from "features/sets/routers/router.sets";
import * as INFLUENCER_REVENUE_ROUTES from "features/influencerRevenue/routers/router.infrevenue";
import * as PLATFORM_ROUTES from "features/platform/routers/router.platform";
import * as PLATFORM_REVENUE_ROURTES from "features/revenue/routers/router.revenue";
import * as ADMINS_ROUTES from "features/admins/routers/router.admins";
import * as WITHDRAW_ROUTES from "features/withdraw/routers/router.withdraw";
import * as CUSTOM_CARDS_ROUTES from "features/customCards/routers/router.customCards";
import * as BANNERS_ROUTES from "features/banners/routers/router.banners";
/** Public route imports */
import * as AUTH_ROUTES from "features/identity/router.authentication";

/** Private Routes */
export const PRIVATE_ROUTES = {
  ...DASHBOARD_ROUTES,
  ...PROFILE_ROUTES,
  ...INFLUENCERS_ROUTES,
  ...APP_USERS_ROUTES,
  ...SETS_ROUTES,
  ...INFLUENCER_REVENUE_ROUTES,
  ...PLATFORM_ROUTES,
  ...PLATFORM_REVENUE_ROURTES,
  ...ADMINS_ROUTES,
  ...WITHDRAW_ROUTES,
  ...CUSTOM_CARDS_ROUTES,
  ...BANNERS_ROUTES,
};

/** Public Routes */
export const PUBLIC_ROUTES = { ...AUTH_ROUTES };

/** SiderMenu Items */
export const SIDER_MENU = [
  INFLUENCERS_ROUTES.INFLUENCERS_SCREEN,
  INFLUENCER_REVENUE_ROUTES.INFLUENCER_REVENUE_SCREEN,
  PLATFORM_ROUTES.PLATFORM_DASHBOARD_SCREEN,
  PLATFORM_REVENUE_ROURTES.REVENUE_SCREEN,
  WITHDRAW_ROUTES.WITHDRAW_SCREEN,
  CUSTOM_CARDS_ROUTES.CUSTOM_CARDS_SCREEN,
  BANNERS_ROUTES.BANNERS_SCREEN,
  ADMINS_ROUTES.ADMINS_SCREEN,
];
