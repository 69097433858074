import React from "react";
import { PageHeader as AntDPageHeader } from "antd";
import { PageHeaderProps } from "antd/lib/page-header";
import styles from "./PageHeader.module.scss";
import cx from "classnames";
import { BreadcrumbProps } from "antd/lib/breadcrumb";

interface IProps extends PageHeaderProps, BreadcrumbProps {}

const PageHeader: React.FC<IProps> = ({ ...rest }) => {
  return (
    <AntDPageHeader
      {...rest}
      className={cx(styles.pageHeader, {
        // [styles.collapsedWidth]: collapsed,
      })}
    >
      {rest.children}
    </AntDPageHeader>
  );
};

export default PageHeader;
