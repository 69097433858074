import { IMenuItemType } from "router/routerType";
import { DashboardOutlined } from "@ant-design/icons";
import Dashboard from "../screens/Dashboard/Dashboard";

/** Screen: Dashboard Page */
export const DASHBOARD_SCREEN: IMenuItemType = {
  id: "dashboard",
  path: "/dashboard",
  component: Dashboard,
  title: "Dashboard",
  icon: DashboardOutlined,
  permissions: [],
};
