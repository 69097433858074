import React, { memo } from "react";
import { Result } from "antd";
import { useTranslation } from "react-i18next";
import { LockFilled, QuestionCircleOutlined } from "@ant-design/icons";

export enum ClientErrors {
  FORBIDDEN = "403",
  NOT_FOUND = "404",
}

interface IProps {
  status?: ClientErrors;
}

/** 404 Not found screen */
const ErrorScreen = ({ status }: IProps) => {
  const { t } = useTranslation();
  if (status === ClientErrors.FORBIDDEN) {
    return (
      <Result
        icon={<LockFilled />}
        title={t("clientErrors.forbiddenTitle")}
        subTitle={t("clientErrors.forbidden")}
      />
    );
  } else {
    return (
      <Result
        icon={<QuestionCircleOutlined />}
        title={t("clientErrors.notFoundTitle")}
        subTitle={t("clientErrors.notFound")}
      />
    );
  }
};

export default memo(ErrorScreen);
