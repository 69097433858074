import { memo, useEffect, useState, useCallback, useContext } from "react";
import Table from "ui/Table/Table";
import Search from "ui/Search/Search";
import { Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";

import PageHeader from "ui/PageHeader/PageHeader";
import Wrapper from "ui/Wrapper/Wrapper";
import { getInfluencers } from "features/influencers/api/influencersApi";
import { InfluencerType } from "features/influencers/types/InfuencerType";
import Button from "ui/Buttons/Button";
import ManageInfluencerDrawer from "./components/ManageInfluencerDrawer/ManageInfluencerDrawer";
import { UserContext } from "features/identity/userContext";
import getInfluencersColumns from "./components/InfluencersColumns";

const InfluencersScreen = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const page = new URLSearchParams(location.search).get("page");
  const per = new URLSearchParams(location.search).get("per");
  const total = sessionStorage.getItem("influencers-total");
  const initialPaginationState =
    page && per && total
      ? {
          defaultCurrent: 1,
          current: parseInt(page),
          pageSize: parseInt(per),
          total: parseInt(total),
        }
      : {};

  const [influencers, setInfluencers] = useState<InfluencerType[]>();
  const [pagination, setPagination] = useState<object>(initialPaginationState);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const history = useHistory();
  const [isManageDrawerVisible, setIsManageDrawerVisible] = useState(false);

  const [drawerInfluencerManage, setDrawerInfluencerManage] =
    useState<InfluencerType>();

  const showDrawerManage = (influencer?: InfluencerType) => {
    setIsManageDrawerVisible(!isManageDrawerVisible);
    setTimeout(() => setDrawerInfluencerManage(influencer), 100);
  };

  const columns = getInfluencersColumns({ location, showDrawerManage, user });

  const getInfluencersApi = useCallback(() => {
    setTableLoading(true);
    let params = qs.parse(location.search);
    getInfluencers(params)
      .then((res) => {
        setInfluencers(res.rows);
        setPagination((prevPagination) => {
          sessionStorage.setItem("influencers-total", res.count);
          const current = parseInt(params.page as string);
          const pageSize = parseInt(params.per as string);
          const basePagination = {
            ...prevPagination,
            total: res.count,
          };

          const result =
            current && pageSize
              ? {
                  ...basePagination,
                  current,
                  pageSize,
                }
              : basePagination;
          return result;
        });
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, [location.search]);

  useEffect(() => {
    getInfluencersApi();
  }, [getInfluencersApi]);

  const handleTableChange = (pagination, filters, sorter) => {
    const { columnKey = null, order = null } = sorter;
    let values: any = {
      ...qs.parse(location.search),
      page: pagination.current,
      per: pagination.pageSize,
    };
    if (columnKey && order) {
      values = {
        ...values,
        columnKey,
        order,
      };
    } else if (columnKey && !order) {
      values = {
        ...values,
        columnKey,
        order: "",
      };
    }

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  return (
    <>
      <PageHeader
        title={"Influencers"}
        extra={
          <Button
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => console.log("Plus button")}
          >
            Create a new
          </Button>
        }
      />
      <Wrapper>
        <Card extra={<Search />}>
          <Table
            loading={tableLoading}
            columns={columns}
            dataSource={influencers}
            pagination={pagination}
            onChange={handleTableChange}
            exportableDataTableName={`Influencers`}
            showColumnPicker={true}
          />
        </Card>
      </Wrapper>

      <ManageInfluencerDrawer
        visible={isManageDrawerVisible}
        onClose={showDrawerManage}
        influencer={drawerInfluencerManage}
        showDrawer={showDrawerManage}
        refreshApi={getInfluencersApi}
      />
    </>
  );
};

export default memo(InfluencersScreen);
