import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import Drawer from "ui/Drawer/Drawer";
import { AppUserType } from "features/appUsers/types/AppUserType";
import Loading from "ui/Loading/Loading";
import { getAppUserTransfers } from "features/appUsers/api/appUsersApi";
import { errorResponseMessages } from "api/apiErrorHelper";
import { useLocation } from "react-router-dom";
import styles from "./TransfersListDrawer.module.scss";
import { Transfer } from "features/appUsers/types/Transfers";
import PageHeader from "ui/PageHeader/PageHeader";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const TransfersListDrawer = ({
  visible,
  refreshApi,
  onClose,
  appUser,
}: IProps) => {
  const [appUserTransferLoading, setAppUserTransferLoading] =
    useState<boolean>(false);
  const [appUserTransfer, setAppUserTransfer] = useState<Transfer[]>();
  const location = useLocation();

  const onCloseDrawer = () => {
    onClose && onClose();
  };

  const geAppUserOrderAPI = useCallback(() => {
    if (appUser) {
      setAppUserTransferLoading(true);
      getAppUserTransfers(appUser?.id)
        .then((res) => {
          setAppUserTransfer(res);
        })
        .catch((e) => {
          errorResponseMessages(e);
        })
        .finally(() => {
          setAppUserTransferLoading(false);
        });
    }
  }, [appUser, visible]);

  useEffect(() => {
    geAppUserOrderAPI();
  }, [geAppUserOrderAPI]);

  const columns: ColumnsType = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
      width: "5%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: "User Payment Method",
      width: "10%",
      children: [
        {
          title: "Name",
          dataIndex: "appUserPaymentMethod",
          key: "appUserPaymentMethod",
          render: (text) => (
            <>
              <Popover
                style={{ width: 500 }}
                content={text?.name}
                title="Name"
                trigger="hover"
              >
                {text?.name}
              </Popover>
            </>
          ),
          width: "5%",
        },
        {
          title: "Provider",
          dataIndex: "provider",
          key: "provider",
          render: (text) => <span>{text}</span>,
        },
        {
          title: "type",
          dataIndex: "appUserPaymentMethod",
          key: "appUserPaymentMethod",
          render: (text) => <span>{text?.type}</span>,
          width: "5%",
        },

        {
          title: "User Data",
          width: "10%",
          children: [
            {
              title: "Ip Address",
              dataIndex: "ip",
              key: "ip",
              render: (text) => <span>{text}</span>,
            },
          ],
        },
      ],
    },
    {
      title: "TrackingId",
      dataIndex: "trackingId",
      key: "trackingId",
      render: (text) => (
        <>
          <Popover
            style={{ width: 500 }}
            content={text}
            title="TrackingId"
            trigger="hover"
          >
            {text}
          </Popover>
        </>
      ),
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={"90%"}
    >
      <PageHeader title={"Transfers"} />

      <div className={styles.dropStatusHeadder}>
        {!appUserTransferLoading && (
          <>
            <Card>
              <Table
                loading={appUserTransferLoading}
                columns={columns}
                dataSource={appUserTransfer}
              />
            </Card>
          </>
        )}
        {appUserTransferLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(TransfersListDrawer);
