import React, { memo } from "react";
import { Card, Col, Row } from "antd";
import PageHeader from "ui/PageHeader/PageHeader";
import Wrapper from "ui/Wrapper/Wrapper";
import ProfileForm from "../../components/ProfileForm/ProfileForm";

const ProfileScreen = () => {
  return (
    <>
      <PageHeader
        title={"My Profile"}
        subTitle="Here you can update you user's data"
      />
      <Wrapper>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card>
              <ProfileForm />
            </Card>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default memo(ProfileScreen);
