import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Popover,
  Typography,
  Select,
  Col,
  Button,
  Row,
  Form,
  Input,
  Modal,
  message,
} from "antd";
import { LaptopOutlined, UserOutlined } from "@ant-design/icons";
import Loading from "ui/Loading/Loading";
import { Timeline } from "antd";
import { useParams } from "react-router-dom";
import styles from "./AppUsersDetails.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import moment from "moment";
import {
  fetchUsersProfile,
  updateStatusToUser,
  fetchUsersHistoryStatus,
} from "store/usersSlice";
const { Title, Text } = Typography;

const StatusHistory = ({ profile }) => {
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();
  const [loading, setAppUserProfileLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState(profile?.appUser?.status || "active");
  const blockReasonRef = useRef(profile?.appUser?.blockReasonRef);

  const [blockReason, setBlockReason] = useState(
    profile?.appUser?.blockReason || ""
  );

  const appUserStatusHistory = useSelector(
    (state: RootState) => state.users.detail.statusHistories.entities
  );
  const appUserUserStatusHistoryLoading = useSelector(
    (state: RootState) => state.users.detail.statusHistories.loading
  );

  const geAppUserStatusHistoryAPI = useCallback(() => {
    if (userId) {
      dispatch(fetchUsersHistoryStatus(userId));
    }
  }, [userId]);

  useEffect(() => {
    geAppUserStatusHistoryAPI();
  }, [geAppUserStatusHistoryAPI]);

  const onUpdateStatus = ({ profile }) => {
    setIsModalVisible(true);
    setEdit(true);

    form.setFieldsValue({
      ...profile,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEdit(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    setIsModalVisible(false);
    const { status, blockReason } = values;
    setAppUserProfileLoading(true);

    dispatch(
      updateStatusToUser({ appUserId: profile.appUserID, status, blockReason })
    )
      .then((res) => {
        form.resetFields();
        setBlockReason(blockReason);
        setStatus(status);
        dispatch(fetchUsersProfile(profile.appUserID));
      })
      .catch((e) => message.error("Couldn't Update Status"))
      .finally(() => {
        setAppUserProfileLoading(false);
      });
  };

  const modalBlockUser = (
    <>
      <Modal
        title={"Change User Status"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          initialValues={{
            status: status,
            blockReason: blockReason,
          }}
          onFinish={onFinish}
        >
          <Form.Item name="status" label="Status">
            <Select style={{ width: "100%" }} placeholder="Please select">
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="blocked">Blocked</Select.Option>
            </Select>
          </Form.Item>

          {/* todo eval active or block to show or not blocreason */}

          <Form.Item name="blockReason" label="Block Reason">
            <Input typeof="TextArea" ref={blockReasonRef} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  const cardShow = (e) => {
    return (
      <Card
        title={moment(e.createdAt).format("DD MMM YYYY : HH:mm:SS")}
        bordered={false}
      >
        <Row>
          <Col span={24}>
            <Text strong>Comment: </Text>
            <span>{e.details ? e.details : "-"}</span>
          </Col>
          <Col span={12}>
            <Text strong>Previous status: </Text>
            <span>{e.oldStatus ? e.oldStatus : "-"}</span>
          </Col>
          <Col span={12}>
            <Text strong>Current status : </Text>
            <span>{e.newStatus ? e.newStatus : "-"}</span>
          </Col>
          <Col span={12}>
            <Text strong>Changer : </Text>{" "}
            <span>{e.changerId ? e.changerId : "-"}</span>
          </Col>
          <Col span={12}>
            <Text strong>Changer Type </Text>:{" "}
            <span>{e.changerType ? e.changerType : "-"}</span>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <div className={styles.dropStatusHeadder}>
      {!appUserUserStatusHistoryLoading && appUserStatusHistory && (
        <>
          {modalBlockUser}
          <Row>
            <Col span={24}>
              <Title level={4}>Status History</Title>
            </Col>
          </Row>

          <Card>
            <Row align={"middle"} className={styles.changeRoleWrapper}>
              <Col span={6}>
                <Text strong>User: </Text>
                <Text>
                  {profile.firstName} {profile.lastName}
                </Text>
              </Col>
              <Col span={6}>
                <Text strong>Status: </Text>
                <Text>{profile.appUser.status}</Text>
              </Col>
              <Col span={6}> </Col>
              <Col span={6}>
                <Button size="middle" onClick={() => onUpdateStatus(profile)}>
                  change user status
                </Button>
              </Col>
            </Row>
          </Card>
          <Card>
            <Timeline>
              {appUserStatusHistory.map(function (e, index) {
                return (
                  <Timeline.Item
                    key={index}
                    dot={
                      e.changerType === "admin" ? (
                        <UserOutlined
                          type="origin"
                          style={{ fontSize: "12px" }}
                        />
                      ) : (
                        <LaptopOutlined
                          type="origin"
                          style={{ fontSize: "12px" }}
                        />
                      )
                    }
                    color={e.newStatus === "blocked" ? "red" : "green"}
                  >
                    <>
                      <Popover
                        style={{ width: 500 }}
                        content={cardShow(e)}
                        // title="Details"
                        trigger="hover"
                      >
                        <span>
                          {moment(e.createdAt).format("DD MMM YYYY : HH:mm:SS")}
                        </span>{" "}
                        - {e.details ? e.details : "-"}
                      </Popover>
                    </>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Card>
        </>
      )}
      {appUserUserStatusHistoryLoading && (
        <>
          <Loading isFullscreen={false} />
        </>
      )}
    </div>
  );
};

export default memo(StatusHistory);
