import { memo, useEffect, useState, useCallback } from "react";
import { Card, Col, Row, List } from "antd";
import { useLocation } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import Wrapper from "ui/Wrapper/Wrapper";
import { RevenueData } from "features/revenue/types/RevenueData";
import { getRevenue } from "features/revenue/api/revenueApi";
import ListItem from "ui/Lists/Items/ListItem/ListItem";
import Text from "ui/Typography/Text/Text";

const renderListItem = (text: string, value?: number | string) => (
  <ListItem type="primary">
    <Text>
      {text}: {value}
    </Text>
  </ListItem>
);

const RevenueScreen = () => {
  const [revenue, setRevenue] = useState<RevenueData>();
  const location = useLocation();

  const getRevenueApi = useCallback(() => {
    getRevenue()
      .then((res) => {
        console.log(res);
        setRevenue(res);
      })
      .catch((e) => {
        errorResponseMessages(e);
      });
  }, [location.search]);

  useEffect(() => {
    getRevenueApi();
  }, [getRevenueApi]);

  return (
    <>
      <PageHeader title={"Revenue"} />
      <Wrapper>
        <Card>
          <>
            <Row>
              <Col span={12}>
                <List size="default" itemLayout="vertical">
                  {renderListItem(
                    "Number of cards minted",
                    revenue?.numberOfCardsMinted
                  )}
                  {renderListItem(
                    "Number of NFTs sold on primary market",
                    revenue?.numberOfNFTSoldOnPrimaryMarket
                  )}
                  {renderListItem(
                    "Total Gross Revenue",
                    revenue?.totalGrossRevenue
                  )}
                  {renderListItem(
                    "Total amount of payment fees",
                    revenue?.paymentFees
                  )}
                  {renderListItem(
                    "Total amount of celebrities revenue",
                    revenue?.totalAmountOfCelebritiesRevenue
                  )}
                </List>
              </Col>
              <Col span={12}>
                <List size="default" itemLayout="vertical">
                  {renderListItem(
                    "Total amount of referrers revenue",
                    revenue?.referrersRevenue
                  )}
                  {renderListItem(
                    "Number or completed buy orders on secondary market",
                    revenue?.numberOfCompletedByuOrdersOnSecondaryMarket
                  )}
                  {renderListItem(
                    "Platform revenue from secondary",
                    revenue?.platformRevenueFromSecondary
                  )}
                  {renderListItem(
                    "Secondary marketplace volume",
                    revenue?.secondaryMarketplaceVolume
                  )}
                  {renderListItem(
                    "Net platform revenue",
                    revenue?.netPlatformRevenue
                  )}
                </List>
              </Col>
            </Row>
          </>
        </Card>
      </Wrapper>
    </>
  );
};

export default memo(RevenueScreen);
