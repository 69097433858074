import React from "react";
import { Typography } from "antd";
import { NO_VALUE } from "ui/uiConsts";

import styles from "./../../AppUsersDetail.module.scss";
const { Text } = Typography;

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const KYCStatus = ({ profile }) => {
  const applicant = profile?.appUser?.applicant;
  const kycStatus = profile?.kycStatus;

  const showDrawerKycTransactions = () => {
    if (profile?.appUser?.applicant) {
      const { applicantId = "", latestCheckId = "" } =
        profile?.appUser?.applicant;
      const URL_ONFRIDO = `https://dashboard.us.onfido.com/applicant/${applicantId}/check/${latestCheckId}/reports`;
      openInNewTab(URL_ONFRIDO);
    }
  };

  if (applicant && applicant.latestCheckId) {
    return (
      <Text className={styles.linkRedirect} onClick={showDrawerKycTransactions}>
        {kycStatus || "View status"}
      </Text>
    );
  }

  return <Text>{kycStatus || NO_VALUE}</Text>;
};

export default KYCStatus;
