import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";

import Loading from "ui/Loading/Loading";
import styles from "./AppUsersDetails.module.scss";

import { errorResponseMessages } from "api/apiErrorHelper";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchUsersCards } from "store/usersSlice";

const Cards = () => {
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();
  const appUserCards = useSelector(
    (state: RootState) => state.users.detail.cards.entities
  );
  const appUserCardsLoading = useSelector(
    (state: RootState) => state.users.detail.cards.loading
  );

  const geAppUserCardApi = useCallback(() => {
    if (userId) {
      dispatch(fetchUsersCards(userId)).catch((e) => {
        errorResponseMessages(e);
      });
    }
  }, [userId]);

  useEffect(() => {
    geAppUserCardApi();
  }, [geAppUserCardApi]);

  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "ISIN",
      dataIndex: "isin",
      key: "isin",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "AssetLabel",
      dataIndex: "assetLabel",
      key: "assetLabel",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "CardsNumber",
      dataIndex: "cardsNumber",
      key: "cardsNumber",
      width: "20%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      align: "center",
      width: "100",
      render: (text) => (
        <span>
          <Image width={50} src={text} />
        </span>
      ),
    },
  ];

  return (
    <div className={styles.dropStatusHeadder}>
      {!appUserCardsLoading && appUserCards && (
        <>
          <Card>
            <Table
              loading={appUserCardsLoading}
              columns={columns}
              dataSource={appUserCards}
            />
          </Card>
        </>
      )}
      {appUserCardsLoading && (
        <>
          <Loading isFullscreen={false} />
        </>
      )}
    </div>
  );
};

export default memo(Cards);
