import { IMenuItemType } from "router/routerType";
import { AndroidOutlined } from "@ant-design/icons";
import adminsScreen from "../screens/AdminsScreen/adminsScreen";

/** Screen: Dashboard Page */
export const ADMINS_SCREEN: IMenuItemType = {
  id: "admins",
  path: "/admins",
  component: adminsScreen,
  title: "Admins",
  icon: AndroidOutlined,
  permissions: [],
};
