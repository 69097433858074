import { memo, useEffect, useState } from "react";
import { Select } from "antd";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { STATUSES } from "features/customCards/consts/customCardsConsts";
const { Option } = Select;

const CustomCardsFilters = () => {
  const location = useLocation();
  const history = useHistory();
  const [reviewStatusFilter, setReviewStatusFilter] = useState("");

  const handleChangeStatusFilter = (value) => {
    setReviewStatusFilter(value);
    handleOnFilterChange({
      filter: value,
    });
  };

  useEffect(() => {
    const params = qs.parse(location.search);
    params?.filter && setReviewStatusFilter(params.filter as string);
  }, []);

  const handleOnFilterChange = (filters) => {
    const prevParams = qs.parse(location.search);
    const params = { ...prevParams, page: 1, ...filters };
    history.push({
      pathname: location.pathname,
      search: qs.stringify(params, { arrayFormat: "comma" }),
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 20,
        }}
      >
        <Select
          defaultValue="-"
          style={{ width: 250 }}
          onChange={handleChangeStatusFilter}
          value={reviewStatusFilter}
          size="large"
        >
          <Option value="">Filter by review status:</Option>
          <Option value={STATUSES.NEW}>New</Option>
          <Option value={STATUSES.APPROVED}>Approved</Option>
          <Option value={STATUSES.REJECTED}>Rejected</Option>
          <Option value={STATUSES.ALL}>All</Option>
        </Select>
      </div>
    </>
  );
};

export default memo(CustomCardsFilters);
