import { memo } from "react";

import { Button as AntdButton } from "antd";
import { ButtonProps as AntdButtonProps } from "antd/es/button";
import classnames from "classnames/bind";
import styles from "./Button.module.scss";

const cx = classnames.bind(styles);

interface ButtonProps extends Omit<AntdButtonProps, "href"> {}

export enum ButtonType {
  PRIMARY = "primary",
}

export enum ButtonShape {
  CIRCLE = "circle",
}

const Button = memo(({ className, type, ...rest }: ButtonProps) => {
  const classNames = cx(
    styles.button,
    {
      [styles.primary]: type === ButtonType.PRIMARY,
      // [styles.circle]: shape === ButtonShape.CIRCLE,
    },
    className
  );

  const buttonContent = <AntdButton className={classNames} {...rest} />;

  return buttonContent;
});

export default Button;
