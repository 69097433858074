import { IMenuItemType } from "router/routerType";
import { UserOutlined } from "@ant-design/icons";
import ProfileScreen from "../screens/ProfileScreen/ProfileScreen";

/** Screen: Questions Page */
export const MY_PROFILE_SCREEN: IMenuItemType = {
  id: "profile",
  path: "/my-profile",
  component: ProfileScreen,
  title: "My Profile",
  icon: UserOutlined,
  permissions: [],
};
