import React, { memo, useContext, useState } from "react";

import Form from "ui/Forms/Form";
import FormItem from "ui/Forms/FormItem";
import { Input, InputPassword } from "ui/Inputs/Inputs";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { UserContext } from "features/identity/userContext";
import { updateMe } from "../../api/profileApi";
import { errorResponseMessages } from "api/apiErrorHelper";
import { isEmpty } from "lodash";
const ProfileForm = () => {
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [passwordConfirmationRequired, setPasswordConfirmationRequired] =
    useState(false);

  const onFinish = (values) => {
    console.log(isEmpty(values.password));
    setLoading(true);
    updateMe({
      ...values,
      password: !isEmpty(values.password) ? values.password : undefined,
      passwordConfirmation: !isEmpty(values.passwordConfirmation)
        ? values.passwordConfirmation
        : undefined,
    })
      .then((res) => {
        setUser({ ...user, name: res.data.name });
        message.success("User data updated successfully!");
      })
      .catch((e) => errorResponseMessages(e))
      .finally(() => setLoading(false));
  };

  const onFinishFailed = (values) => {};

  const handlePasswordKeyUp = (e) => {
    setPasswordConfirmationRequired(!!e.target.value);
  };

  return (
    <Form
      layout={"vertical"}
      name="loginForms"
      initialValues={{ name: user?.name }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <FormItem
        label={"Name"}
        name={"name"}
        key={"name"}
        rules={[
          {
            required: true,
            message: "Please input your e-mail!",
          },
        ]}
      >
        <Input
          type={"text"}
          prefix={<UserOutlined />}
          placeholder={"Name"}
          size={"large"}
        />
      </FormItem>

      <FormItem
        label={"New password"}
        name={"password"}
        key={"password"}
        rules={[
          {
            required: false,
            message: "Please set your new password!",
          },
        ]}
      >
        <InputPassword
          prefix={<LockOutlined />}
          placeholder={"New password"}
          size="large"
          onKeyUp={(e) => handlePasswordKeyUp(e)}
        />
      </FormItem>

      <FormItem
        label={"Confirm with old password"}
        name={"passwordConfirmation"}
        key={"passwordConfirmation"}
        rules={[
          {
            required: passwordConfirmationRequired,
            message: "Please confirm with your old password!",
          },
        ]}
      >
        <InputPassword
          prefix={<LockOutlined />}
          placeholder={"Old password"}
          size="large"
        />
      </FormItem>

      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
};
export default memo(ProfileForm);
