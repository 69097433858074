import { IMenuItemType } from "router/routerType";
import { DashboardOutlined } from "@ant-design/icons";
import platformDashboard from "../screens/PlatformDashboardScreen/platformDashboardScreen";

/** Screen: Dashboard Page */
export const PLATFORM_DASHBOARD_SCREEN: IMenuItemType = {
  id: "platformDashboard",
  path: "/platformDashboard",
  component: platformDashboard,
  title: "Platform Dashboard",
  icon: DashboardOutlined,
  permissions: [],
};
