import { memo, useEffect, useState, useCallback } from "react";
import Table from "ui/Table/Table";
import { Card, Image } from "antd";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import Wrapper from "ui/Wrapper/Wrapper";
import { ColumnsType } from "antd/lib/table";
import { Button } from "antd";
import { InfuencerRevenue } from "features/influencerRevenue/types/InfuencerRevenue";
import { getInfluencersRevenue } from "features/influencerRevenue/api/influencersApi";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
const { RangePicker } = DatePicker;

const InfluencerRevenueScreen = () => {
  const startDates = [moment().startOf("month"), moment().endOf("month")];
  const [influencersRevenue, setInfluencersRevenue] =
    useState<InfuencerRevenue[]>();
  const [pagination, setPagination] = useState<object>();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [queryDates, setQueryDates] = useState<Moment[]>(startDates);
  const location = useLocation();
  const history = useHistory();
  const columns: ColumnsType = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Cards Editions",
      dataIndex: "counter",
      key: "counter",
      align: "center",
      width: "100",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Cards sold",
      dataIndex: "counterSold",
      key: "counterSold",
      align: "center",
      width: "100",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Total earnings",
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      align: "center",
      width: "100",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Referred By Id",
      dataIndex: "referredById",
      key: "referredById",
      align: "center",
      width: "100",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Referred by Name",
      dataIndex: "referredName",
      key: "referredName",
      align: "center",
      width: "100",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Referred by Email",
      dataIndex: "referredByEmail",
      key: "referredByEmail",
      align: "center",
      width: "100",
      render: (text) => <span>{text}</span>,
    },
  ];

  const getInfluencersRevenueApi = useCallback(() => {
    handleTableChange(pagination);
    if (!pagination && !location.search) return;
    setTableLoading(true);
    getInfluencersRevenue(qs.parse(location.search))
      .then((res) => {
        setInfluencersRevenue(res.rows);
        setPagination((prevPagination) => {
          return {
            ...prevPagination,
            total: res.count,
          };
        });
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, [location.search]);

  const handleTableChange = (pagination) => {
    const values = {
      ...qs.parse(location.search),
      page: pagination?.current,
      per: pagination?.pageSize,
      dateStart: queryDates[0].toDate().toDateString(),
      dateEnd: queryDates[1].toDate().toDateString(),
    };
    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  const onChange = (e) => {
    setQueryDates(e);
  };

  const onSearch = (e) => {
    handleTableChange(pagination);
  };

  useEffect(() => {
    getInfluencersRevenueApi();
  }, [getInfluencersRevenueApi]);

  return (
    <>
      <PageHeader title={"Influencer revenue statistic"} />
      <Wrapper>
        <Card
          extra={
            <div>
              <RangePicker
                onChange={onChange}
                defaultValue={[queryDates[0], queryDates[1]]}
                allowClear={false}
              />
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={onSearch}
              >
                Search
              </Button>
            </div>
          }
        >
          <Table
            loading={tableLoading}
            columns={columns}
            dataSource={influencersRevenue}
            pagination={pagination}
            onChange={handleTableChange}
            exportable={true}
            exportableDataTableName={`Influencer_revenue`}
            showColumnPicker={true}
          />
        </Card>
      </Wrapper>
    </>
  );
};

export default memo(InfluencerRevenueScreen);
