import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { PUBLIC_ROUTES } from "router/Router.config";
import AuthLayout from "./AuthLayout/AuthLayout";
import Login from "./Login/Login";
import SetNewPassword from "./SetNewPassword/SetNewPassword";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import SignUp from "./SignUp/SignUp";
import SetNewPasswordCode from "./SetNewPasswordCode/SetNewPasswordCode";

class AuthScreen extends PureComponent<RouteComponentProps> {
  render() {
    const { match } = this.props;

    return (
      <AuthLayout>
        {
          {
            [PUBLIC_ROUTES.SIGN_UP_SCREEN.path]: <SignUp />,
            [PUBLIC_ROUTES.LOGIN_SCREEN.path]: <Login />,
            [PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path]: <ForgotPassword />,
            [PUBLIC_ROUTES.SET_NEW_PASSWORD_SCREEN.path]: <SetNewPassword />,
            [PUBLIC_ROUTES.SET_NEW_PASSWORD_CODE_SCREEN.path]: (
              <SetNewPasswordCode />
            ),
          }[match.path]
        }
      </AuthLayout>
    );
  }
}

export default AuthScreen;
