import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as influencersAPI from "features/influencers/api/influencersApi";
import { InfluencerType } from "features/influencers/types/InfuencerType";

export const fetchInfluencers = createAsyncThunk(
  "influencers/fetchInfluencers",
  async (params?: object) => {
    const response = await influencersAPI.getInfluencers(params);
    return response;
  }
);
export const fetchInfluencersById = createAsyncThunk(
  "influencers/fetchInfluencersById",
  async (influencerID: string) => {
    const response = await influencersAPI.getInfluencer(influencerID);
    return response;
  }
);
export const fetchInfluencersByIdDetails = createAsyncThunk(
  "influencers/fetchInfluencersByIdDetails",
  async (influencerID: string) => {
    const response = await influencersAPI.getInfluencerDetails(influencerID);
    return response;
  }
);
export const fetchInfluencersByIdStatus = createAsyncThunk(
  "influencers/fetchInfluencersByIdStatus",
  async (influencerID: string) => {
    const response = await influencersAPI.getInfluencerDropStatus(influencerID);
    return response;
  }
);
export const restartDropProcessInfluencer = createAsyncThunk(
  "influencers/restartDropProcessInfluencer",
  async (influencerID: string) => {
    const response = await influencersAPI.setReStartDropProcessInfluencer(
      influencerID
    );
    return response;
  }
);

export const setdropStartDateInfluencer = createAsyncThunk(
  "influencers/setdropStartDateInfluencer",
  async ({ influencerID, data }: { influencerID: string; data: object }) => {
    const response = await influencersAPI.setDropStartDateInfluencer(
      influencerID,
      data
    );
    return response;
  }
);

interface InfluencersState {
  entities: {
    rows: InfluencerType[];
    count: number;
  };
  detail: {
    entities: any;
    profile: InfluencerType | null;
    loading: boolean;
    status: any;
  };
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState = {
  entities: {
    rows: [],
    count: 0,
  },
  detail: {
    entities: null,
    profile: null,
    loading: false,
    status: null,
  },
  loading: "idle",
} as InfluencersState;

// Then, handle actions in your reducers:
const influencersSlice = createSlice({
  name: "influencers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencers.fulfilled, (state, action) => {
        state.entities = action.payload;
      })
      .addCase(fetchInfluencersById.pending, (state, action) => {
        state.detail.loading = true;
      })
      .addCase(fetchInfluencersById.fulfilled, (state, action) => {
        state.detail.profile = action.payload;
        state.detail.loading = false;
      })
      .addCase(fetchInfluencersByIdStatus.fulfilled, (state, action) => {
        state.detail.status = action.payload;
      })
      .addCase(fetchInfluencersByIdDetails.fulfilled, (state, action) => {
        state.detail.entities = action.payload;
      });
  },
});

export default influencersSlice.reducer;
