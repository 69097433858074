import { DropProcessStatusType } from "features/influencers/types/DropProcesStatusType";
import styles from "./DropStatus.module.scss";
import ListItem from "ui/Lists/Items/ListItem/ListItem";
import Title from "ui/Typography/Title/Title";
import Text from "ui/Typography/Text/Text";
import Button from "ui/Buttons/Button";
import { List, Col, Row, DatePicker } from "antd";
import { useState } from "react";
import moment from "moment";

interface IProps {
  data?: DropProcessStatusType;
  changeDropStartDate;
  visible: boolean;
}

const DropStatus = ({ data, visible, changeDropStartDate }: IProps) => {
  let nrOfCards = false;
  let currentSeries = false;
  let isDropping = false;

  const getTextFromStatus = (status: string | undefined) => {
    if (status) {
      if (status.toLowerCase() === "notready") {
        return "No card images created";
      }
      if (status.toLowerCase() === "error")
        return "Card images configuration error";
      if (status.toLowerCase() === "ready") {
        nrOfCards = true;
        return "Ready for dropping";
      }
      if (status.toLowerCase() === "dropping") {
        isDropping = true;
        nrOfCards = true;
        currentSeries = true;
        return "Already dropping";
      }
      if (status.toLowerCase() === "stopped") {
        nrOfCards = true;
        currentSeries = true;
        return "Drops processed stopped (no more card images or need to restart)";
      }
    }
  };

  return (
    <>
      <List size="default" itemLayout="vertical">
        <Row>
          <Col span={24}>
            <ListItem type="primary">
              <Text strong={true}>Status:</Text>
              {getTextFromStatus(data?.dropStatus)}
            </ListItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {nrOfCards ? (
              <ListItem type="primary">
                <Text strong={true}>Nº of cards:</Text>
                {data?.numCards}
              </ListItem>
            ) : (
              " "
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {currentSeries ? (
              <ListItem type="primary">
                <Text strong={true}>Current Series</Text>
                {data?.currentSeriesDropping}
              </ListItem>
            ) : (
              " "
            )}
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            {isDropping ? (
              <ListItem type="primary">
                <Row>
                  <Col>
                    <Text strong={true}>Drop start date</Text>
                  </Col>
                  <Col>
                    <DatePicker
                      onChange={changeDropStartDate}
                      defaultValue={moment(data?.dropStartDate)}
                      showTime
                    ></DatePicker>
                  </Col>
                </Row>
              </ListItem>
            ) : (
              " "
            )}
          </Col>
        </Row>
      </List>
    </>
  );
};

export default DropStatus;
