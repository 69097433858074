import React, { memo, useState, useEffect, useContext, Suspense } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { PRIVATE_ROUTES, PUBLIC_ROUTES, SIDER_MENU } from "./Router.config";
import values from "lodash/values";
import Layout from "ui/Layout/Layout";
import ErrorScreen from "./ErrorScreen";
import { logout, isAuthenticated } from "features/identity/authHelper";
import { UserContext } from "features/identity/userContext";
import Loading from "ui/Loading/Loading";
import Permission from "features/identity/Permission/Permission";
import { APP_USERS_DETAIL_SCREEN } from "features/appUsers/routers/router.appUsers";
import { INFLUENCERS_DETAIL_SCREEN } from "features/influencers/routers/router.influencers";
import { BANNERS_DETAIL_SCREEN } from "features/banners/routers/router.banners";
import UserPool from "features/identity/UserPool";

const filteredRoutes = values(PRIVATE_ROUTES).filter((item) => item.component);

const Router = () => {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    /** Check on every render if set-new-password or
     * forgot-password in the path then clear tokens
     */
    if (
      location.pathname.includes(
        PUBLIC_ROUTES.SET_NEW_PASSWORD_SCREEN.path.split("/", 2)[1]
      ) ||
      location.pathname === PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path
    ) {
      logout(false);
      setUser({});
    }

    //TODO: FIX GET/ REFRESH USER FROM COGNITO
    // if (isAuthenticated() && !user.authenticated) {
    //   setLoading(true);
    //   getUser()
    //     .then((res) => {
    //       setUser({
    //         ...res.data,
    //         authenticated: true,
    //       });
    //     })
    //     .finally(() => setLoading(false));
    // }
  }, [location, setUser, user.authenticated]);

  useEffect(() => {
    if (isAuthenticated() && !user.name) {
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error(err);
            return;
          }

          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              console.error(err);
              return;
            }

            const emailAttribute = attributes?.find(
              (attr) => attr.getName() === "email"
            );
            const email = emailAttribute ? emailAttribute.getValue() : null;

            setUser({
              name: cognitoUser.getUsername(),
              email: email ? email : undefined,
              authenticated: true,
            });
          });
        });
      }
    }
  }, [isAuthenticated()]);

  if (loading) {
    return <Loading isFullscreen />;
  }

  return (
    <Suspense fallback={<Loading isFullscreen />}>
      {isAuthenticated() ? (
        <Switch>
          <Route path={APP_USERS_DETAIL_SCREEN.path}>
            <Layout item={APP_USERS_DETAIL_SCREEN} siderMenu={SIDER_MENU}>
              {<APP_USERS_DETAIL_SCREEN.component />}
            </Layout>
          </Route>
          <Route path={INFLUENCERS_DETAIL_SCREEN.path}>
            <Layout item={INFLUENCERS_DETAIL_SCREEN} siderMenu={SIDER_MENU}>
              {<INFLUENCERS_DETAIL_SCREEN.component />}
            </Layout>
          </Route>
          <Route path={BANNERS_DETAIL_SCREEN.path}>
            <Layout item={BANNERS_DETAIL_SCREEN} siderMenu={SIDER_MENU}>
              {<BANNERS_DETAIL_SCREEN.component />}
            </Layout>
          </Route>
          {filteredRoutes.map((item) => (
            <Route
              key={item.id}
              exact
              path={item.path}
              render={(props) => (
                <Permission
                  requiredPermissions={item.permissions}
                  userPermissions={user.permissions}
                >
                  <Layout {...props} item={item} siderMenu={SIDER_MENU}>
                    <item.component {...props} />
                  </Layout>
                </Permission>
              )}
            />
          ))}
          <Redirect
            exact
            from="/"
            to={PRIVATE_ROUTES.PLATFORM_DASHBOARD_SCREEN.path}
          />

          <Route
            path="*"
            render={(props) => (
              <Layout {...props} siderMenu={[]}>
                <ErrorScreen {...props} status={undefined} />
              </Layout>
            )}
          />
        </Switch>
      ) : (
        <Switch>
          {values(PUBLIC_ROUTES).map((item) => (
            <Route
              key={item.id}
              exact
              path={item.path}
              component={item.component}
            />
          ))}
          <Redirect to={PUBLIC_ROUTES.LOGIN_SCREEN.path} />
        </Switch>
      )}
    </Suspense>
  );
};

export default memo(Router);
