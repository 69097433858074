import { useState } from "react";
import { Button, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

// import { setAccessToken, setRefreshToken } from "features/identity/authHelper";
// import { errorResponseMessages } from "api/apiErrorHelper";
// import { UserContext } from "features/identity/userContext";

import { Input, InputPassword } from "ui/Inputs/Inputs";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
// import styles from "./SignUp.module.scss";

import UserPool from "../UserPool";
import { errorResponseMessages } from "api/apiErrorHelper";

const SignUp = () => {
  // const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (formData) => {
    setLoading(true);

    UserPool.signUp(formData.email, formData.password, [], [], (err, data) => {
      if (err) {
        errorResponseMessages(err);
        console.error(err);
      }
      console.log(data);
      setLoading(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Something went wrong!");
  };

  return (
    <Form
      name="loginForms"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <FormItem
        name={"email"}
        key={"email"}
        rules={[
          {
            required: true,
            type: "email",
            message: "Please input your e-mail!",
          },
        ]}
      >
        <Input
          type={"email"}
          prefix={<UserOutlined />}
          placeholder={"E-mail"}
          size={"large"}
        />
      </FormItem>

      <FormItem
        name={"password"}
        key={"password"}
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <InputPassword
          prefix={<LockOutlined />}
          placeholder={"Password"}
          size="large"
        />
      </FormItem>

      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Sign up
        </Button>
      </FormItem>
    </Form>
  );
};

export default SignUp;
