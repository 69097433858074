import { useState, useEffect, useCallback, useContext } from "react";
import {
  Typography,
  Col,
  Row,
  Modal,
  Button,
  Card,
  Spin,
  Select,
  Checkbox,
  Tooltip,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import styles from "./InfluencerDetail.module.scss";

import DropStatus from "./../ManageInfluencerDrawer/components/DropStatus/DropStatus";
import { DropProcessStatusType } from "features/influencers/types/DropProcesStatusType";
import {
  deleteInfluencer,
  getInfluencerDropStatus,
  setDropStartDateInfluencer,
  setReStartDropProcessInfluencer,
  setStartDropProcessInfluencer,
  updateInfluencer,
} from "features/influencers/api/influencersApi";
import { useHistory } from "react-router-dom";
import { NO_VALUE } from "ui/uiConsts";
import { emulateInfluencer } from "helpers/emulationHelper";
import { isSuperAdmin } from "features/identity/authHelper";
import { UserContext } from "features/identity/userContext";
import { goBack } from "helpers/navigationHelper";
import { errorResponseMessages } from "api/apiErrorHelper";

const { Option } = Select;

const { Title, Text } = Typography;

const INF_STATUSES = {
  new: "Just signed up",
  basicsFilled: "Filled in handle and name",
  profileCompleted: "Went through Setup Account",
  verified: "Passed KYC",
  blocked:
    "Manually set via admin. Profile is hidden but creator can still login and post",
  suspended: "Manually set via admin. Creator cannot login",
};

const getTooltipContent = (data) =>
  Object.entries(data).map(([key, value]) => {
    return (
      <p key={key}>
        <b>{key}:</b> {value}
      </p>
    );
  });

const CATEGORIES = [
  "Sport",
  "Beauty",
  "Fashion",
  "Business",
  "Tech &Gaming",
  "Health & Fitness",
  "Travel",
  "Music",
  "Social Figure",
  "Entertainment",
  "Baby",
];

const INF_LEVELS_MAPPING = {
  smaller: "Rising stars",
  bigger: "All starts",
  group: "Group",
};

const Details = ({ influencer, influencerStats, callback }) => {
  const { user } = useContext(UserContext);
  const [isModalManage, setModalManage] = useState(false);
  const [influencerDropStatusLoading, setInfluencerDropStatusLoading] =
    useState<boolean>(false);
  const [influencerDropStatusReady, setInfluencerDropStatusReady] =
    useState<boolean>(false);
  const [influencerDropRestartStatus, setInfluencerDropRestartStatus] =
    useState<boolean>(false);
  const [influencerStatus, setInfluencerStatus] =
    useState<DropProcessStatusType>();
  const [loading, setLoading] = useState(true);

  const [editableProps, setEditableProps] = useState({
    email: null,
    description: null,
    about: null,
    status: null,
    level: null,
    featured: false,
    category: null,
    paymentsEnabled: false,
    subscriptionsEnabled: false,
  });

  const history = useHistory();

  const handleInfluencerData = (influencer) => {
    if (influencer) {
      const {
        email,
        description,
        about,
        status,
        level,
        featured,
        category,
        paymentsEnabled,
        subscriptionsEnabled,
      } = influencer;
      setEditableProps({
        email,
        description,
        about,
        status,
        level,
        featured,
        category,
        paymentsEnabled,
        subscriptionsEnabled,
      });
    }
  };

  useEffect(() => {
    handleInfluencerData(influencer);
    setLoading(false);
  }, [influencer]);

  const handleEditableChange = (value, type) => {
    setEditableProps({
      ...editableProps,
      [type]: value,
    });
  };

  const featuredOnChange = (value) => {
    handleEditableChange(value.target.checked, "featured");
  };

  const paymentsEnabledOnChange = (value) => {
    handleEditableChange(value.target.checked, "paymentsEnabled");
  };

  const subscriptionsEnabledOnChange = (value) => {
    handleEditableChange(value.target.checked, "subscriptionsEnabled");
  };

  const emailOnChange = (value) => {
    handleEditableChange(value, "email");
  };

  const descriptionOnChange = (value) => {
    handleEditableChange(value, "description");
  };

  const aboutOnChange = (value) => {
    handleEditableChange(value, "about");
  };

  const statusOnChange = (value) => {
    const id = influencer.appUserId;
    if (value === "blocked" && id) {
      Modal.confirm({
        title: "Confirm Block",
        content: `Are you sure you want to block this influencer and related app user:${id}?`,
        centered: true,
        onOk: () => {
          handleEditableChange(value, "status");
        },
      });
    } else if (value !== "blocked" && id && influencer.status === "blocked") {
      Modal.confirm({
        title: "Confirm Unblock",
        content: `Are you sure you want to unblock this influencer and related app user:${id}?`,
        centered: true,
        onOk: () => {
          handleEditableChange(value, "status");
        },
      });
    } else {
      handleEditableChange(value, "status");
    }
  };

  const levelOnChange = (value) => {
    handleEditableChange(value, "level");
  };

  const categoryOnChange = (value) => {
    handleEditableChange(value, "category");
  };

  const handleSaveEdited = () => {
    setLoading(true);
    updateInfluencer(influencer.id, editableProps)
      .then(() => {
        callback().then(() => setLoading(false));
      })
      .catch((error) => {
        handleInfluencerData(influencer);
        const e = error?.response?.data || error;
        errorResponseMessages(e);
        setLoading(false);
      });
  };

  const handleGoBack = () => {
    goBack(history, "/influencers", "influencer-previous-params");
  };

  const handleDelete = () => {
    const content = `Are you sure you want to delete this influencer${
      influencer.appUserId
        ? " and related app user id:" + influencer.appUserId
        : ""
    } ?`;
    Modal.confirm({
      title: "Confirm Deletion",
      content,
      onOk: () => {
        setLoading(true);
        deleteInfluencer(influencer.id).then(() => {
          setLoading(false);
          handleGoBack();
        });
      },
      centered: true,
    });
  };

  const getInfluencerStatusApi = useCallback(() => {
    if (isModalManage && influencer) {
      setInfluencerDropStatusLoading(true);
      getInfluencerDropStatus(influencer.id)
        .then((res) => {
          setInfluencerStatus(res);
          if (res?.dropStatus.toLowerCase() === "ready") {
            setInfluencerDropStatusReady(true);
          } else {
            setInfluencerDropStatusReady(false);
            if (res?.dropStatus.toLowerCase() === "stopped") {
              if (res?.numCards > res?.currentSeriesDropping) {
                setInfluencerDropRestartStatus(true);
              }
            } else {
              setInfluencerDropRestartStatus(false);
            }
          }
        })
        .finally(() => setInfluencerDropStatusLoading(false));
    }
  }, [influencer, isModalManage]);

  const setReStartDropProcessApi = (influencer) => {
    if (influencer) {
      setInfluencerDropStatusLoading(true);
      setReStartDropProcessInfluencer(influencer.id)
        .then((res) => {
          setInfluencerDropRestartStatus(false);
        })
        .finally(() => {
          setInfluencerDropStatusLoading(false);
          refreshDrawerApi();
        });
    }
  };

  const setStartDropProcessApi = (influencer) => {
    if (influencer) {
      setInfluencerDropStatusLoading(true);
      setStartDropProcessInfluencer(influencer.id)
        .then((res) => {
          setInfluencerDropStatusReady(false);
        })
        .finally(() => {
          setInfluencerDropStatusLoading(false);
          refreshDrawerApi();
        });
    }
  };

  const changeDropStartDate = async (value) => {
    if (influencer) {
      await setDropStartDateInfluencer(influencer.id, {
        dropStartDate: value.toString(),
      });
    }
  };

  const refreshDrawerApi = () => {
    getInfluencerStatusApi();
  };

  useEffect(() => {
    getInfluencerStatusApi();
  }, [getInfluencerStatusApi]);

  const manageModal = () => {
    return (
      <Modal
        title={"Manage Influencer"}
        visible={isModalManage}
        onCancel={() => setModalManage(false)}
        footer={null}
      >
        <>
          <Row>
            <Col>
              <DropStatus
                data={influencerStatus}
                visible={true}
                changeDropStartDate={changeDropStartDate}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className={styles.modalButtons}>
              <>
                <Button
                  style={{
                    width: "180px",
                  }}
                  type="dashed"
                  size="large"
                  shape="round"
                  loading={influencerDropStatusLoading}
                  disabled={!influencerDropStatusReady}
                  onClick={() => {
                    setStartDropProcessApi(influencer);
                  }}
                >
                  Start drops process
                </Button>

                <Button
                  style={{
                    width: "200px",
                  }}
                  type="dashed"
                  size="large"
                  shape="round"
                  loading={influencerDropStatusLoading}
                  disabled={!influencerDropRestartStatus}
                  onClick={() => {
                    setReStartDropProcessApi(influencer);
                  }}
                >
                  Restart drops process
                </Button>
              </>
            </Col>
          </Row>
        </>
      </Modal>
    );
  };

  const handleChangeManager = () => {
    setModalManage(true);
  };

  const manageDetails = () => {
    if (loading) {
      return <Spin />;
    } else {
      if (influencer) {
        return (
          <>
            <Row className={styles.block}>
              <Col span={6}>
                <div className={styles.infoBlock}>
                  <Text type="secondary">ISIN</Text>
                  <Text>{influencer?.isin || NO_VALUE}</Text>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Handle</Text>
                  <Text>{influencer?.handle || NO_VALUE}</Text>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Email</Text>
                  <Text editable={{ onChange: emailOnChange }}>
                    {editableProps?.email}
                  </Text>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Description</Text>
                  <Text editable={{ onChange: descriptionOnChange }}>
                    {editableProps?.description}
                  </Text>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">About</Text>
                  <Text editable={{ onChange: aboutOnChange }}>
                    {editableProps?.about}
                  </Text>
                </div>
              </Col>

              <Col span={6}>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Record Date</Text>
                  <Text>{influencerStats?.recordDate || NO_VALUE}</Text>
                </div>

                <div className={styles.infoBlock}>
                  <Text type="secondary">Capitalization</Text>
                  <Text>{influencerStats?.capitalization || NO_VALUE}</Text>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Holders Total</Text>
                  <Text>{influencerStats?.holdersTotal || NO_VALUE}</Text>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Cards Total</Text>
                  <Text>{influencerStats?.cardsTotal || NO_VALUE}</Text>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Top Sale Price</Text>
                  <Text>{influencerStats?.topSalePrice || NO_VALUE}</Text>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Stripe status:</Text>
                  <Text>{influencer?.stripeStatus || NO_VALUE}</Text>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Category</Text>
                  <Select
                    value={editableProps?.category || NO_VALUE}
                    style={{ width: 120 }}
                    onChange={categoryOnChange}
                  >
                    {CATEGORIES.map((category) => (
                      <Option key={category} value={category}>
                        {category}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">
                    Status
                    <Tooltip title={getTooltipContent(INF_STATUSES)}>
                      <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </Text>
                  <Select
                    style={{ width: 120 }}
                    onChange={statusOnChange}
                    value={editableProps?.status || NO_VALUE}
                  >
                    {Object.keys(INF_STATUSES).map((status) => (
                      <Option key={status} value={status}>
                        {status}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Type</Text>
                  <Select
                    value={editableProps?.level || NO_VALUE}
                    style={{ width: 120 }}
                    onChange={levelOnChange}
                  >
                    {Object.keys(INF_LEVELS_MAPPING).map((levelKey) => (
                      <Option key={levelKey} value={levelKey}>
                        {INF_LEVELS_MAPPING[levelKey]}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Featured</Text>
                  <Checkbox
                    checked={editableProps?.featured}
                    onChange={featuredOnChange}
                  />
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Payments Enabled</Text>
                  <Checkbox
                    checked={editableProps?.paymentsEnabled}
                    onChange={paymentsEnabledOnChange}
                  />
                </div>
                <div className={styles.infoBlock}>
                  <Text type="secondary">Subscriptions Enabled</Text>
                  <Checkbox
                    checked={editableProps?.subscriptionsEnabled}
                    onChange={subscriptionsEnabledOnChange}
                  />
                </div>
              </Col>
            </Row>
            <Card>
              <Row align={"middle"}>
                <Col span={24}>
                  <Button
                    disabled={!isSuperAdmin()}
                    className={styles.handleButton}
                    onClick={() => emulateInfluencer(influencer, user)}
                  >
                    Emulate influencer
                  </Button>

                  <Button
                    className={styles.handleButton}
                    size="middle"
                    onClick={() => handleChangeManager()}
                  >
                    Manage influencer
                  </Button>

                  <Button
                    className={styles.handleButton}
                    onClick={() => handleSaveEdited()}
                  >
                    Save edited
                  </Button>

                  <Link
                    to={
                      influencer?.appUserId
                        ? `/app-users/${influencer?.appUserId}/details`
                        : "#"
                    }
                    className={`${styles.handleButton} ant-btn ${
                      !influencer?.appUserId ? styles.disabled : ""
                    }`}
                  >
                    {"App user profile"}
                  </Link>

                  <Button
                    className={styles.handleButton}
                    danger
                    onClick={() => handleDelete()}
                  >
                    Delete influencer
                  </Button>
                </Col>
              </Row>
            </Card>
          </>
        );
      } else {
        return (
          <Col span={24}>
            <Title level={4}>Influencer not found</Title>
          </Col>
        );
      }
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>Details</Title>
        </Col>
      </Row>
      {manageModal()}
      {manageDetails()}
    </>
  );
};

export default Details;
