import React, { memo, useEffect, useState } from "react";
import styles from "./SearchAppUsers.module.scss";
import { Input } from "antd";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";

const { Search: AntDSearch } = Input;

interface IProps {
  onSearch?: (value) => void;
}

const SearchAppUsers = ({ onSearch }: IProps) => {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState("");

  useEffect(() => {
    const params = qs.parse(location.search);
    params?.searchterm && setValue(params.searchterm as string);
  }, []);

  const handleOnSearch = ({ value, ...rest }) => {
    const prevParams = qs.parse(location.search);
    const params = { ...prevParams, searchterm: value, page: 1, ...rest };
    history.push({
      pathname: location.pathname,
      search: qs.stringify(params, { arrayFormat: "comma" }),
    });
  };

  return (
    <AntDSearch
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className={styles.search}
      placeholder="input search text"
      enterButton="Search"
      size="large"
      onSearch={(value) => handleOnSearch({ value })}
    />
  );
};

export default memo(SearchAppUsers);
