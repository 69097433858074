import React, { memo, useEffect, useState } from "react";
import styles from "./Search.module.scss";
import { Input, Select } from "antd";
import qs from "query-string";
import { useLocation, useHistory } from "react-router-dom";

const { Search: AntDSearch } = Input;
const { Option } = Select;

interface IProps {
  onSearch?: (value) => void;
}

const Search = ({ onSearch }: IProps) => {
  const location = useLocation();
  const history = useHistory();
  const [featured, setFeatured] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [level, setLevel] = useState("");

  const handleChangeFeatured = (value) => {
    setFeatured(value);
    handleOnSearch({
      value: searchValue,
      featured: value,
    });
  };

  const handleChangeLevel = (value) => {
    setLevel(value);
    handleOnSearch({
      value: searchValue,
      level: value,
    });
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const params = qs.parse(location.search);
    params?.searchterm && setSearchValue(params.searchterm as string);
    params?.featured && setFeatured(params.featured as string);
    params?.level && setLevel(params.level as string);
  }, []);

  const handleOnSearch = ({ value, ...rest }) => {
    const prevParams = qs.parse(location.search);
    const params = { ...prevParams, searchterm: value, page: 1, ...rest };
    history.push({
      pathname: location.pathname,
      search: qs.stringify(params, { arrayFormat: "comma" }),
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 20,
        }}
      >
        <Select
          defaultValue="-"
          style={{ width: 200 }}
          onChange={handleChangeFeatured}
          value={featured}
          size="large"
        >
          <Option value="">Filter featured:</Option>
          <Option value="featured">Featured</Option>
          <Option value="notFeatured">Not Featured</Option>
        </Select>
        <Select
          defaultValue="-"
          style={{ width: 200 }}
          onChange={handleChangeLevel}
          value={level}
          size="large"
        >
          <Option value="">Filter by level:</Option>
          <Option value="smaller">Rising stars</Option>
          <Option value="bigger">All stars</Option>
          <Option value="group">Group</Option>
        </Select>

        <AntDSearch
          value={searchValue}
          className={styles.search}
          placeholder="input search text"
          enterButton="Search"
          size="large"
          onSearch={(value) => handleOnSearch({ value, featured, level })}
          onChange={handleInputChange}
        />
      </div>
    </>
  );
};

export default memo(Search);
