import { memo, useEffect, useState, useCallback, useContext } from "react";
import Table from "ui/Table/Table";
import SearchAppUsers from "ui/SearchAppUsers/SearchAppUsers";
import {
  PlusOutlined,
  CheckSquareTwoTone,
  CloseSquareOutlined,
} from "@ant-design/icons";
import { Card, Menu, Popover, Typography } from "antd";
import Button from "ui/Buttons/Button";
import qs from "query-string";
import { useLocation, useHistory, Link } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import Wrapper from "ui/Wrapper/Wrapper";
import { getAppUsers } from "features/appUsers/api/appUsersApi";
import { AppUserType } from "features/appUsers/types/AppUserType";
import { ColumnsType } from "antd/lib/table";
import PaymentDrawer from "./components/PaymentsDrawer/PaymentsDrawer";
import CardsHoldingDrawer from "./components/CardsHoldingDrawer/CardsHoldingDrawer";
import OrdersHistoryDrawer from "./components/OrdersHistoryDrawer/OrdersHistoryDrawer";
import TransactionDrawer from "./components/TransactionsListDrawer/TransactionsDrawer";
import TransfersListDrawer from "./components/TransfersListDrawer/TransfersListDrawer";
import AppUserProfileDrawer from "./components/AppUserProfileDrawer/AppUserProfileDrawer";
import { NO_VALUE } from "ui/uiConsts";
import { UserContext } from "features/identity/userContext";
import { emulateAppUser } from "helpers/emulationHelper";

const { Text } = Typography;

const AppUsersScreen = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const page = new URLSearchParams(location.search).get("page");
  const per = new URLSearchParams(location.search).get("per");
  const total = sessionStorage.getItem("app-users-total");
  const initialPaginationState =
    page && per && total
      ? {
          defaultCurrent: 1,
          current: parseInt(page),
          pageSize: parseInt(per),
          total: parseInt(total),
        }
      : {};

  const [appUsers, setAppUsers] = useState<AppUserType[]>();
  const [pagination, setPagination] = useState<object>(initialPaginationState);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [isCardsDrawerVisible, setCardsDrawerVisible] = useState(false);
  const [isOrdersDrawerVisible, setOrdersDrawerVisible] = useState(false);
  const [isTransactionsDrawerVisible, setTransactionsDrawerVisible] =
    useState(false);
  const [isPaymentsDrawerVisible, setPaymentsDrawerVisible] = useState(false);
  const [isTransfersListDrawer, setTransfersListDrawerVisible] =
    useState(false);
  const [isAppUserProfileDrawerVisible] = useState(false);

  const [TransactionsDrawer, setTransactionsDrawer] = useState<AppUserType>();
  const [OrdersDrawer, setOrdersDrawer] = useState<AppUserType>();
  const [CardsDrawer, setCardsDrawer] = useState<AppUserType>();
  const [PaymentsDrawer, setPaymentsDrawer] = useState<AppUserType>();
  const [transfersListDrawer, setTransfersListDrawer] = useState<AppUserType>();
  const [appUserProfileDrawer] = useState<AppUserType>();

  const columns: ColumnsType = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
      render: (text, record: any) => {
        const { id } = record;
        return (
          <>
            <Popover
              style={{ width: 300 }}
              content={text}
              title="Email"
              trigger="hover"
            >
              <Link
                to={`app-users/${id}/details`}
                onClick={() =>
                  sessionStorage.setItem(
                    "app-user-previous-params",
                    location.search
                  )
                }
              >
                {text || NO_VALUE}
              </Link>
            </Popover>
          </>
        );
      },
    },
    {
      title: "App User Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text || NO_VALUE}</span>,
      width: "10%",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text) => (
        <>
          <Popover
            style={{ width: 200 }}
            content={text}
            title="Username"
            trigger="hover"
          >
            {text || NO_VALUE}
          </Popover>
        </>
      ),
      width: "14%",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text) => (
        <>
          <Popover
            style={{ width: 200 }}
            content={text}
            title="Name"
            trigger="hover"
          >
            {text || NO_VALUE}
          </Popover>
        </>
      ),
      width: "10%",
    },
    {
      title: "Verified",
      dataIndex: "emailVerified",
      key: "emailVerified",
      render: (text) => (
        <span>
          {text ? (
            <CheckSquareTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseSquareOutlined />
          )}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text || NO_VALUE}</span>,
      width: "10%",
    },
    {
      title: "Block Reason",
      dataIndex: "blockReason",
      key: "BlockReason",
      render: (text) => (
        <>
          <Popover
            style={{ width: 200 }}
            content={text}
            title="Block Reason"
            trigger="hover"
          >
            {text || "Not Blocked"}
          </Popover>
        </>
      ),
      width: "15%",
    },
    {
      title: "Is Vip",
      dataIndex: "isVip",
      key: "isVip",
      render: (text) => <span>{text ? "Yes" : "No"}</span>,
      width: "2%",
    },
    {
      title: "Wallet $",
      children: [
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (text) => <span>{text || NO_VALUE}</span>,
          width: "12%",
        },
        {
          title: "Locked",
          dataIndex: "amountLocked",
          key: "amountLocked",
          render: (text) => <span>{text || NO_VALUE}</span>,
          width: "12%",
        },
      ],
    },
    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      width: "5%",
      align: "center",
      render: (appUser: AppUserType) => (
        <Menu mode="horizontal">
          <Menu.Item
            key="showDrawerPayments"
            onClick={() => showDrawerPayments(appUser)}
          >
            <Text>Payment Methods</Text>
          </Menu.Item>
          <Menu.Item
            key="showTransfersListDrawer"
            onClick={() => showTransfersListDrawer(appUser)}
          >
            <Text>Transfers</Text>
          </Menu.Item>
          <Menu.Item
            key="showDrawerCards"
            onClick={() => showDrawerCards(appUser)}
          >
            <Text>Cards</Text>
          </Menu.Item>
          <Menu.Item
            key="showDrawerOrders"
            onClick={() => showDrawerOrders(appUser)}
          >
            <Text>Orders</Text>
          </Menu.Item>
          <Menu.Item
            key="showDrawerTransactions"
            onClick={() => showDrawerTransactions(appUser)}
          >
            <Text>Transactions</Text>
          </Menu.Item>
          <Menu.Item
            key="showAppUserProfileDrawer"
            onClick={() => showAppUserProfileDrawer(appUser)}
          >
            <Text>App User Profile</Text>
          </Menu.Item>
          <Menu.Item
            key="showDrawerKycTransactions"
            disabled={
              !(
                appUser?.applicant &&
                appUser?.applicant?.applicantId &&
                appUser?.applicant?.latestCheckId
              )
            }
            onClick={() => showDrawerKycTransactions(appUser)}
          >
            <Text>Show KYC Checks</Text>
          </Menu.Item>
          <Menu.Item
            key="emulateAppUser"
            onClick={() => emulateAppUser(appUser, user)}
          >
            <Text>Emulate</Text>
          </Menu.Item>
        </Menu>
      ),
    },
  ];

  const showDrawerCards = (appUser?: AppUserType) => {
    setCardsDrawerVisible(!isCardsDrawerVisible);
    setTimeout(() => setCardsDrawer(appUser), 100);
  };
  const showDrawerOrders = (appUser?: AppUserType) => {
    setOrdersDrawerVisible(!isOrdersDrawerVisible);
    setTimeout(() => setOrdersDrawer(appUser), 100);
  };

  const showDrawerTransactions = (appUser?: AppUserType) => {
    setTransactionsDrawerVisible(!isTransactionsDrawerVisible);
    setTimeout(() => setTransactionsDrawer(appUser), 100);
  };

  const showDrawerPayments = (appUser?: AppUserType) => {
    setPaymentsDrawerVisible(!isPaymentsDrawerVisible);
    setTimeout(() => setPaymentsDrawer(appUser), 100);
  };

  const showTransfersListDrawer = (appUser?: AppUserType) => {
    setTransfersListDrawerVisible(!isTransfersListDrawer);
    setTimeout(() => setTransfersListDrawer(appUser), 100);
  };

  const showAppUserProfileDrawer = (appUser?: AppUserType) => {
    if (!appUser) return;

    history.push(`/app-users/${appUser.id}`, { appUser });

    // setAppUserProfileDrawerVisible(!isAppUserProfileDrawerVisible);
    // setTimeout(() => setTAppUserProfileDrawerDrawer(appUser), 100);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const showDrawerKycTransactions = (appUser?: AppUserType) => {
    if (appUser?.applicant) {
      const { applicantId = "", latestCheckId = "" } = appUser?.applicant;
      const URL_ONFRIDO = `https://dashboard.us.onfido.com/applicant/${applicantId}/check/${latestCheckId}/reports`;
      openInNewTab(URL_ONFRIDO);
    }
  };

  const getAppUsersApi = useCallback(() => {
    setTableLoading(true);
    let params = qs.parse(location.search);
    getAppUsers(params)
      .then((res) => {
        setAppUsers(res.rows);
        setPagination((prevPagination) => {
          sessionStorage.setItem("app-users-total", res.count);
          const current = parseInt(params.page as string);
          const pageSize = parseInt(params.per as string);
          const basePagination = {
            ...prevPagination,
            total: res.count,
          };
          const result =
            current && pageSize
              ? {
                  ...basePagination,
                  current,
                  pageSize,
                }
              : basePagination;
          return result;
        });
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, [location.search]);

  useEffect(() => {
    getAppUsersApi();
  }, [getAppUsersApi]);

  const handleTableChange = (pagination) => {
    const values = {
      ...qs.parse(location.search),
      page: pagination.current,
      per: pagination.pageSize,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
  };

  return (
    <>
      <PageHeader
        title={"App Users"}
        extra={
          <Button
            type="primary"
            size={"large"}
            icon={<PlusOutlined />}
            onClick={() => console.log("Plus button")}
          >
            Create a new
          </Button>
        }
      />
      <Wrapper>
        <Card
          extra={
            <>
              <SearchAppUsers />
            </>
          }
        >
          <Table
            loading={tableLoading}
            columns={columns}
            dataSource={appUsers}
            pagination={pagination}
            onChange={handleTableChange}
            exportableDataTableName={`App_Users`}
            showColumnPicker={true}
          />
        </Card>
      </Wrapper>

      <PaymentDrawer
        visible={isPaymentsDrawerVisible}
        onClose={showDrawerPayments}
        appUser={PaymentsDrawer}
        showDrawer={showDrawerPayments}
        refreshApi={getAppUsersApi}
      />
      <CardsHoldingDrawer
        visible={isCardsDrawerVisible}
        onClose={showDrawerCards}
        appUser={CardsDrawer}
        showDrawer={showDrawerCards}
        refreshApi={getAppUsersApi}
      />
      <TransactionDrawer
        visible={isTransactionsDrawerVisible}
        onClose={showDrawerTransactions}
        appUser={TransactionsDrawer}
        showDrawer={showDrawerTransactions}
        refreshApi={getAppUsersApi}
      />
      <OrdersHistoryDrawer
        visible={isOrdersDrawerVisible}
        onClose={showDrawerOrders}
        appUser={OrdersDrawer}
        showDrawer={showDrawerOrders}
        refreshApi={getAppUsersApi}
      />
      <TransfersListDrawer
        visible={isTransfersListDrawer}
        onClose={showTransfersListDrawer}
        appUser={transfersListDrawer}
        showDrawer={showTransfersListDrawer}
        refreshApi={getAppUsersApi}
      />
      <AppUserProfileDrawer
        visible={isAppUserProfileDrawerVisible}
        onClose={showAppUserProfileDrawer}
        appUser={appUserProfileDrawer}
        showDrawer={showAppUserProfileDrawer}
        refreshApi={getAppUsersApi}
      />
    </>
  );
};

export default memo(AppUsersScreen);
