import React, { memo, useCallback, useEffect, useState } from "react";
import { message, Image } from "antd";
import { AppUserProfile } from "../../../types/AppUserProfile";
import { AppUserType } from "features/appUsers/types/AppUserType";
import styles from "./AppUserProfileDrawer.module.scss";
import Drawer from "ui/Drawer/Drawer";
import Title from "ui/Typography/Title/Title";
import { getAppUserProfile, updateRoleToUser } from "../../../api/appUsersApi";
import Button from "ui/Buttons/Button";
import Loading from "ui/Loading/Loading";
import { Card, Col, Row, List, Popover, Anchor, Select } from "antd";
import ListItem from "ui/Lists/Items/ListItem/ListItem";
import Text from "ui/Typography/Text/Text";
import { CheckSquareTwoTone, CloseSquareOutlined } from "@ant-design/icons";
const { Link } = Anchor;
const { Option } = Select;

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const AppUserProfileDrawer = ({
  visible,
  refreshApi,
  onClose,
  appUser,
}: IProps) => {
  const onCloseDrawer = () => {
    onClose && onClose();
  };
  const [role, setRole] = useState("customer");
  const [appUserProfile, setAppUserProfile] = useState<AppUserProfile>();
  const [appUserProfileLoading, setAppUserProfileLoading] =
    useState<boolean>(false);

  const getInfluencerStatusApi = useCallback(() => {
    if (appUser) {
      setAppUserProfileLoading(true);
      getAppUserProfile(appUser?.id)
        .then((res) => {
          setAppUserProfile(res);
          setRole(res.appUser.role);

          setAppUserProfileLoading(false);
        })
        .catch((e) => message.error("Couldn't load Status"))
        .finally(() => setAppUserProfileLoading(false));
    }
  }, [appUser, visible]);

  const handleChangeAppUserRole = (event) => {
    updateRoleToUser(appUserProfile?.appUserID, role).then((res) => {
      setAppUserProfileLoading(true);
      getAppUserProfile(appUser?.id)
        .then((res) => {
          setAppUserProfile(res);
          setRole(res.appUser.role);

          setAppUserProfileLoading(false);
        })
        .catch((e) => message.error("Couldn't load Status"))
        .finally(() => setAppUserProfileLoading(false));
    });
  };
  useEffect(() => {
    getInfluencerStatusApi();
  }, [getInfluencerStatusApi]);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const showGeoIpLookup = (ipAddress?: string) => {
    if (ipAddress) {
      const URL_GEOIPLOOKUP = `https://geoiplookup.net/ip/${ipAddress}`;
      openInNewTab(URL_GEOIPLOOKUP);
    }
  };

  const showInHederaExplorer = (walletAddress?: string) => {
    if (walletAddress) {
      const URL_GEOIPLOOKUP = `https://hederaexplorer.io/search-details/account/${walletAddress}`;
      openInNewTab(URL_GEOIPLOOKUP);
    }
  };

  const handleChange = (value: string) => {
    setRole(value);
  };
  return (
    <>
      <Drawer
        placement="right"
        closable
        onClose={onCloseDrawer}
        visible={visible}
        width={700}
      >
        <br></br>
        <br></br>
        <div className={styles.dropStatusHeader}>
          {!appUserProfileLoading && (
            <>
              <Card>
                <>
                  <Row>
                    <Col span={24}>
                      <div className={styles.itemDataWrapper}>
                        {appUserProfile && (
                          <Image
                            width={50}
                            className={styles.influencerImage}
                            src={appUserProfile?.userPicCroppedUrl}
                          />
                        )}
                        <Title level={3} className={styles.influencerName}>
                          {appUserProfile?.firstName} {appUserProfile?.lastName}
                        </Title>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <List size="default" itemLayout="vertical">
                        <ListItem type="primary">
                          <Text>AppUserId: {appUserProfile?.appUserID} </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>Email: {appUserProfile?.email}</Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            Email Verified:{" "}
                            {appUserProfile?.emailVerified ? (
                              <CheckSquareTwoTone twoToneColor="52c41a" />
                            ) : (
                              <CloseSquareOutlined twoToneColor="df0707" />
                            )}
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            Is Completed:{"  "}
                            {appUserProfile?.isCompleted}
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>Username: {appUserProfile?.username}</Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            ipAddress:
                            <Anchor
                              style={{
                                display: "inline-flex",
                              }}
                              affix={false}
                              onClick={() =>
                                showGeoIpLookup(appUserProfile?.ipAddress)
                              }
                            >
                              <>
                                <Link title={appUserProfile?.ipAddress}></Link>
                              </>
                            </Anchor>
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            Referral Status: {appUserProfile?.referralStatus}
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>Language: {appUserProfile?.language}</Text>
                        </ListItem>
                      </List>
                      <ListItem type="primary">
                        <Text>Country: {appUserProfile?.country}</Text>
                      </ListItem>
                    </Col>
                    <Col span={12}>
                      <List size="default" itemLayout="vertical">
                        <ListItem type="primary">
                          <Text>Role: {appUserProfile?.appUser?.role} </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            Status: {appUserProfile?.appUser?.status}{" "}
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            Block Reason: {appUserProfile?.appUser?.blockReason}{" "}
                          </Text>
                        </ListItem>
                        {/* <ListItem type="primary">
                          <Text>
                            Phone Number: {appUserProfile?.appUser?.phoneNumber}{" "}
                          </Text>
                        </ListItem> */}
                        <ListItem type="primary">
                          <Text>
                            Category: {appUserProfile?.appUser?.category}{" "}
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            Has Connected Wallet:{" "}
                            {appUserProfile?.appUser?.hasConnectedWallet ? (
                              <CheckSquareTwoTone twoToneColor="52c41a" />
                            ) : (
                              <CloseSquareOutlined twoToneColor="#df0707" />
                            )}
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            Wallet Address:{" "}
                            <Anchor
                              style={{
                                display: "inline-flex",
                              }}
                              affix={false}
                              onClick={() =>
                                showInHederaExplorer(
                                  appUserProfile?.appUser?.walletAddress
                                )
                              }
                            >
                              <>
                                <Link
                                  title={appUserProfile?.appUser?.walletAddress}
                                ></Link>
                              </>
                            </Anchor>
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>
                            External Wallet Address:{" "}
                            {appUserProfile?.appUser?.externalWalletAddress}{" "}
                          </Text>
                        </ListItem>
                        <ListItem type="primary">
                          <Text>Is Vip: {appUserProfile?.appUser?.isVip} </Text>
                        </ListItem>
                      </List>
                    </Col>
                  </Row>
                  <Row align={"middle"}>
                    <Col span={24} flex={"auto"}>
                      <Text>
                        change user role{" "}
                        <Select
                          defaultValue={role}
                          style={{ width: 200 }}
                          size="middle"
                          onChange={handleChange}
                        >
                          <Select.Option value="customer">
                            Customer
                          </Select.Option>
                          <Select.Option value="moderator">
                            Moderator
                          </Select.Option>
                        </Select>
                      </Text>
                    </Col>
                    <Col span={6}>
                      {" "}
                      <Button size="middle" onClick={handleChangeAppUserRole}>
                        change
                      </Button>
                    </Col>
                  </Row>
                </>
              </Card>
            </>
          )}
          {appUserProfileLoading && (
            <>
              <Loading isFullscreen={false} />
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default memo(AppUserProfileDrawer);
