// Auth helper for storing and getting token data from local storage
import jwt_decode, { JwtPayload } from "jwt-decode";
import { LOGIN_SCREEN } from "features/identity/router.authentication";
import UserPool from "features/identity/UserPool";

const ACCESS_TOKEN = "ii-admin-panel-access-token";
const REFRESH_TOKEN = "ii-admin-panel-refresh-token";
const ID_TOKEN = "ii-admin-panel-id-token";
const LEVEL = "user-level";
const SUPER_LEVEL = "superAdmin";

export const isAuthenticated = () => {
  return getAccessToken() != null;
};

export const isSuperAdmin = () => {
  const currentLevel = getUserLevel();
  return currentLevel === SUPER_LEVEL;
};

export const setAccessToken = (token: string) => {
  return localStorage.setItem(ACCESS_TOKEN, token);
};

export const setIdToken = (token: string) => {
  return localStorage.setItem(ID_TOKEN, token);
};

export const getIdToken = () => {
  return localStorage.getItem(ID_TOKEN);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setRefreshToken = (token: string) => {
  return localStorage.setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setUserLevel = (level: string) => {
  return localStorage.setItem(LEVEL, level);
};

export const getUserLevel = () => {
  return localStorage.getItem(LEVEL);
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(LEVEL);
};

export const logout = (isRedirect = true) => {
  UserPool?.getCurrentUser()?.signOut();
  clearTokens();
  if (isRedirect) {
    window.location.href = LOGIN_SCREEN.path;
  }
};

export const getTokens = () => {
  const accessToken = getAccessToken();
  const idToken = getIdToken();

  return (
    accessToken && {
      //TODO: FIX TOKEN SWAP
      accessToken: idToken,
      expireAt: idToken && jwt_decode<JwtPayload>(accessToken)?.exp,
      refreshToken: getRefreshToken(),
      idToken: getIdToken(),
    }
  );
};

export const handleUserLevel = (user) => {
  user.getUserAttributes(function (err, attributes) {
    if (err) {
      console.error(err);
      return;
    }

    if (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        if (attributes[i].getName() === "custom:level") {
          const level = attributes[i].getValue();
          setUserLevel(level);
          break;
        }
      }
    }
  });
};
