import { memo, useEffect, useState, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import { CustomCardData } from "features/customCards/types/CustomCardData";
import {
  getCustomCards,
  updateCustomCardStatus,
} from "features/customCards/api/customCardsApi";
import Wrapper from "ui/Wrapper/Wrapper";
import Table from "ui/Table/Table";
import CustomCardsFilters from "../components/CustomCardsFilters";
import { Card } from "antd";
import qs from "query-string";

import getCustomCardsColumns from "../components/CustomCardsColumns";
import CustomCardStatusPopup from "../components/CustomCardStatusModal";

const WithdrawScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const [cards, setCards] = useState<CustomCardData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<object>({
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
  });
  const [isCustomCardStatusPopupVisible, setIsCustomCardStatusPopupVisible] =
    useState(false);
  const [currentCard, setCurrentCard] = useState<any>(null);

  const getCustomCardsApi = useCallback(() => {
    setTableLoading(true);
    let params = qs.parse(location.search);
    getCustomCards(params)
      .then((res) => {
        const { cards } = res;
        setCards(cards);
        setPagination((prevPagination) => {
          const current = parseInt(params.page as string);
          const pageSize = parseInt(params.per as string);
          const basePagination = {
            ...prevPagination,
            total: res.total,
          };

          const result =
            current && pageSize
              ? {
                  ...basePagination,
                  current,
                  pageSize,
                }
              : basePagination;
          return result;
        });
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, [location.search]);

  const handleTableChange = (pagination) => {
    let values: any = {
      ...qs.parse(location.search),
      page: pagination.current,
      per: pagination.pageSize,
    };
    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
    setPagination(pagination);
  };

  useEffect(() => {
    getCustomCardsApi();
  }, [getCustomCardsApi]);

  const columns = getCustomCardsColumns({
    setCurrentCard,
    setIsCustomCardStatusPopupVisible,
  });

  const handleCustomCardStatusUpdate = (values) => {
    const { status, rejectReason } = values;
    updateCustomCardStatus(currentCard?.id, { status, rejectReason })
      .then(() => {
        getCustomCardsApi();
      })
      .catch((e) => {
        let error = e;
        const message = e?.response?.data?.message;
        if (message) {
          error = { message };
        }
        errorResponseMessages(error);
      });
  };

  return (
    <>
      <PageHeader title="Limited edition" />
      <Wrapper>
        <Card extra={<CustomCardsFilters />}>
          <Table
            loading={tableLoading}
            columns={columns}
            dataSource={cards}
            exportableDataTableName="Limited edition"
            showColumnPicker={true}
            onChange={handleTableChange}
            pagination={pagination}
          />
        </Card>
      </Wrapper>
      <CustomCardStatusPopup
        visible={isCustomCardStatusPopupVisible}
        setVisible={setIsCustomCardStatusPopupVisible}
        withdraw={currentCard}
        callback={handleCustomCardStatusUpdate}
      />
    </>
  );
};

export default memo(WithdrawScreen);
