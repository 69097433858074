import axios, { AxiosRequestConfig } from "axios";
import { defaultSettings as ENV } from "./config";
import qs from "query-string";
import { ResponseStatus } from "./apiConst";
import {
  setAccessToken,
  setIdToken,
  setRefreshToken,
  getIdToken,
  logout,
} from "features/identity/authHelper";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import UserPool from "features/identity/UserPool";

/** Excluded endpoints for authorization */
// const anonymousEndpoints = [Endpoints.LOGIN];

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  // const isAnonymous = anonymousEndpoints.includes(request.url || "");

  const idToken = getIdToken();

  if (!idToken) {
    logout();
    return Promise.reject(ResponseStatus.UNAUTHORIZED);
  }

  request.headers["Authorization"] = `Bearer ${idToken}`;
  return request;
};

/**
 * Refresh expired tokens
 *
 * @param failedRequest
 * @returns
 */
function refreshExpiredTokens(failedRequest): Promise<any> {
  return new Promise((resolve, reject) => {
    let cognitoUser = UserPool?.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, res) => {
        if (err) {
          logout();
          reject(err);
        } else {
          const refresh_token = res.getRefreshToken();
          cognitoUser?.refreshSession(refresh_token, (err, session) => {
            if (session) {
              const { idToken, accessToken, refreshToken } = session;
              if (err) {
                logout();
                reject(err);
              } else {
                setAccessToken(accessToken.jwtToken);
                setIdToken(idToken.jwtToken);
                setRefreshToken(refreshToken.token);
                failedRequest.response.config.headers["Authorization"] =
                  "Bearer " + idToken.jwtToken;
                resolve(failedRequest);
              }
            }
          });
        }
      });
    } else {
      logout();
    }
  });
}

/**
 * Setup an API instance
 */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: {
    "Content-Type": "application/json",
    "ii-api-version": "3",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "index" });
  },
});

/** Add interceptor */
api.interceptors.request.use(authInterceptor);
createAuthRefreshInterceptor(api, refreshExpiredTokens);
