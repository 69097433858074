import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, message, Image, Col, Row, List } from "antd";
import { InfluencerType } from "features/influencers/types/InfuencerType";
import styles from "./ManageInfluencerDrawer.module.scss";
import Drawer from "ui/Drawer/Drawer";
import Title from "ui/Typography/Title/Title";
import ListItem from "ui/Lists/Items/ListItem/ListItem";
import { DropProcessStatusType } from "features/influencers/types/DropProcesStatusType";
import {
  getInfluencerDropStatus,
  setStartDropProcessInfluencer,
  setReStartDropProcessInfluencer,
  setDropStartDateInfluencer,
} from "features/influencers/api/influencersApi";
import DropStatus from "./components/DropStatus/DropStatus";
import Button from "ui/Buttons/Button";
import Loading from "ui/Loading/Loading";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  influencer?: InfluencerType;
  showDrawer?: () => void;
}

const ManageInfluencerDrawer = ({
  visible,
  refreshApi,
  onClose,
  influencer,
}: IProps) => {
  const onCloseDrawer = () => {
    onClose && onClose();
  };
  const [influencerDropStatusLoading, setInfluencerDropStatusLoading] =
    useState<boolean>(false);
  const [influencerDropStatusReady, setInfluencerDropStatusReady] =
    useState<boolean>(false);
  const [influencerDropRestartStatus, setInfluencerDropRestartStatus] =
    useState<boolean>(false);

  const [influencerStatus, setInfluencerStatus] =
    useState<DropProcessStatusType>();

  const getInfluencerStatusApi = useCallback(() => {
    if (visible && influencer) {
      setInfluencerDropStatusLoading(true);
      getInfluencerDropStatus(influencer.id)
        .then((res) => {
          setInfluencerStatus(res);
          if (res?.dropStatus.toLowerCase() === "ready") {
            setInfluencerDropStatusReady(true);
          } else {
            setInfluencerDropStatusReady(false);
            if (res?.dropStatus.toLowerCase() === "stopped") {
              if (res?.numCards > res?.currentSeriesDropping) {
                setInfluencerDropRestartStatus(true);
              }
            } else {
              setInfluencerDropRestartStatus(false);
            }
          }
        })
        .catch((e) => message.error("Couldn't load Status"))
        .finally(() => setInfluencerDropStatusLoading(false));
    }
  }, [influencer, visible]);

  const setReStartDropProcessApi = (influencer) => {
    if (influencer) {
      setInfluencerDropStatusLoading(true);
      setReStartDropProcessInfluencer(influencer.id)
        .then((res) => {
          setInfluencerDropRestartStatus(false);
        })
        .catch((e) => message.error("Couldn't start the drop process"))
        .finally(() => {
          setInfluencerDropStatusLoading(false);
          refreshDrawerApi();
        });
    }
  };

  const setStartDropProcessApi = (influencer) => {
    if (influencer) {
      setInfluencerDropStatusLoading(true);
      setStartDropProcessInfluencer(influencer.id)
        .then((res) => {
          setInfluencerDropStatusReady(false);
        })
        .catch((e) => message.error("Couldn't start the drop process"))
        .finally(() => {
          setInfluencerDropStatusLoading(false);
          refreshDrawerApi();
        });
    }
  };

  const changeDropStartDate = async (value) => {
    if (influencer) {
      console.log("value", value.toString());
      await setDropStartDateInfluencer(influencer.id, {
        dropStartDate: value.toString(),
      });
    }
  };

  const refreshDrawerApi = () => {
    getInfluencerStatusApi();
    refreshApi && refreshApi();
  };

  useEffect(() => {
    getInfluencerStatusApi();
  }, [getInfluencerStatusApi]);

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={800}
    >
      <>
        <Card>
          <Row>
            <Col span={24}>
              <div className={styles.itemDataWrapper}>
                {influencer && (
                  <Image
                    width={50}
                    className={styles.influencerImage}
                    src={influencer?.profileImageUrl}
                  />
                )}
                <Title level={3} className={styles.influencerName}>
                  {influencer?.name}
                </Title>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={styles.dropStatusHeader}>
                <DropStatus
                  data={influencerStatus}
                  visible={influencerDropStatusLoading}
                  changeDropStartDate={changeDropStartDate}
                ></DropStatus>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <List size="default" itemLayout="vertical">
                <ListItem type="primary">
                  {!influencerDropStatusLoading && (
                    <>
                      <Button
                        style={{
                          width: "180px",
                        }}
                        type="dashed"
                        size="large"
                        shape="round"
                        loading={influencerDropStatusLoading}
                        disabled={!influencerDropStatusReady}
                        onClick={() => {
                          setStartDropProcessApi(influencer);
                        }}
                      >
                        Start drops process
                      </Button>

                      <Button
                        style={{
                          width: "200px",
                        }}
                        type="dashed"
                        size="large"
                        shape="round"
                        loading={influencerDropStatusLoading}
                        disabled={!influencerDropRestartStatus}
                        onClick={() => {
                          setReStartDropProcessApi(influencer);
                        }}
                      >
                        Restart drops process
                      </Button>
                    </>
                  )}
                  {influencerDropStatusLoading && (
                    <>
                      <Loading isFullscreen={false} />
                    </>
                  )}
                </ListItem>
              </List>
            </Col>
          </Row>
        </Card>
      </>

      <div className={styles.dropStatusHeader}></div>
    </Drawer>
  );
};

export default memo(ManageInfluencerDrawer);
