import { IMenuItemType } from "router/routerType";
import { FormOutlined } from "@ant-design/icons";
import SetsScreen from "../screens/SetsScreen";

/** Screen: Dashboard Page */
export const SETS_SCREEN: IMenuItemType = {
  id: "sets",
  path: "/sets",
  component: SetsScreen,
  title: "Basic Sets",
  icon: FormOutlined,
  permissions: [],
};
