import React, { useContext } from "react";
import { Layout, message, Popconfirm } from "antd";
import styles from "./Header.module.scss";
import cx from "classnames";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { UserContext } from "features/identity/userContext";
import { logout } from "features/identity/authHelper";
import { MY_PROFILE_SCREEN } from "features/identity/Profile/routers/router.profile";
import { useHistory } from "react-router-dom";

const { Header: AntDHeader } = Layout;

interface IProps {
  /** collapsed header */
  collapsed: boolean;
}

const Header = ({ collapsed }: IProps) => {
  const { setUser } = useContext(UserContext);
  const history = useHistory();

  const handleSignOut = () => {
    try {
      logout();
      setUser({});
      message.success("You've logged out successfully!");
    } catch {
      message.error("Couldn't log out!");
    }
  };

  return (
    <AntDHeader
      className={cx(styles.header, {
        [styles["header--collapsed"]]: collapsed,
      })}
    >
      <div
        className={styles.headerUserName}
        onClick={() => history.push(MY_PROFILE_SCREEN.path)}
      >
        <UserOutlined className={styles.userIcon} />
        Administrator
      </div>

      <Popconfirm
        title={"Are you sure sign out?"}
        onConfirm={handleSignOut}
        okText="Yes"
        cancelText="No"
      >
        <div className={styles.signOutButton}>
          <span className={styles.signOutButtonText}>Sign Out</span>
          <LogoutOutlined className={styles.signOutButtonIcon} />
        </div>
      </Popconfirm>
    </AntDHeader>
  );
};

export default Header;
