import { Link } from "react-router-dom";
import { Menu, Image, Typography } from "antd";
import { InfluencerType } from "features/influencers/types/InfuencerType";
import { ColumnsType } from "antd/lib/table";
import { NO_VALUE } from "ui/uiConsts";
import { emulateInfluencer } from "helpers/emulationHelper";
import { isSuperAdmin } from "features/identity/authHelper";

const { Text } = Typography;

interface Props {
  location: any;
  showDrawerManage: (influencer?: InfluencerType) => void;
  user: any;
}

const getInfluencersColumns = ({
  location,
  showDrawerManage,
  user,
}: Props): ColumnsType<any> => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text, record: any, index) => {
        const { id } = record;
        return (
          <Link
            to={`/influencers/${id}/details`}
            onClick={() =>
              sessionStorage.setItem(
                "influencer-previous-params",
                location.search
              )
            }
          >
            {text ? text : NO_VALUE}
          </Link>
        );
      },
    },
    {
      title: "Handle",
      dataIndex: "handle",
      key: "handle",
      width: 100,
      render: (text) => <span>{text || NO_VALUE}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      render: (text) => <span>{text ? text.toUpperCase() : NO_VALUE}</span>,
    },
    {
      title: "Last activity date",
      dataIndex: "lastActivityDate",
      key: "lastActivityDate",
      align: "center",
      width: 300,
      render: (dateStr) => {
        let formattedDate = NO_VALUE;
        if (dateStr) {
          const date = new Date(dateStr);

          const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZoneName: "short",
          };
          formattedDate = date.toLocaleString("en-US", options);
        }

        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Posts",
      dataIndex: "postCount",
      key: "postCount",
      align: "center",
      width: 90,
      sorter: (a: any, b: any) => {
        const aNum = parseInt(a.postCount);
        const bNum = parseInt(b.postCount);
        return aNum - bNum;
      },
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Followers",
      dataIndex: "followerCount",
      key: "followerCount",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => {
        const aNum = parseInt(a.followerCount);
        const bNum = parseInt(b.followerCount);
        return aNum - bNum;
      },
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Image",
      dataIndex: "profileImageUrl",
      key: "profileImageUrl",
      align: "center",
      width: 100,
      render: (text) => (
        <span>
          <Image width={50} src={text} />
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: "5%",
      align: "center",
      render: (influencer: InfluencerType) => (
        <Menu mode="horizontal">
          <Menu.Item key="1" onClick={() => showDrawerManage(influencer)}>
            <Text>Manage</Text>
          </Menu.Item>
          <Menu.Item
            disabled={!isSuperAdmin()}
            key="2"
            onClick={() => emulateInfluencer(influencer, user)}
          >
            <Text>Emulate</Text>
          </Menu.Item>
        </Menu>
      ),
    },
  ];
};

export default getInfluencersColumns;
