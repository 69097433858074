import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";
import { AdminsData } from "features/admins/types/AdminsData";

export const createAdmin = async (data?: object) => {
  const response = await api.post(Endpoints.ADMINS, data);
  return response;
};

export const getAdmins = (params?: object) => {
  return api
    .get(Endpoints.ADMINS, {
      params: { ...params },
    })
    .then((response) => response.data);
};

export const getAllAdmins = async () => {
  let allAdmins: AdminsData[] = [];
  let paginationToken = null;

  do {
    let params: any = {};
    if (paginationToken) {
      params.token = paginationToken;
    }

    const response = await getAdmins(params);

    allAdmins = allAdmins.concat(response.users);
    paginationToken = response.paginationToken;
  } while (paginationToken);

  return allAdmins.sort((a, b) => a.email.localeCompare(b.email));
};
