import { memo, useEffect, useState, useCallback } from "react";
// import Table from "ui/Table/Table";
import { Card, Col, Row, List } from "antd";
import { useLocation } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import Wrapper from "ui/Wrapper/Wrapper";
import { PlatformStats } from "features/platform/types/PlatformStats";
import { getPlatformStats } from "features/platform/api/platformApi";
import ListItem from "ui/Lists/Items/ListItem/ListItem";
import Text from "ui/Typography/Text/Text";

const PlatformDashboardScreen = () => {
  const [platformStats, setPlatformStats] = useState<PlatformStats>();
  const location = useLocation();

  const getPlatformStatsApi = useCallback(() => {
    getPlatformStats()
      .then((res) => {
        console.log(res);
        setPlatformStats(res);
      })
      .catch((e) => {
        errorResponseMessages(e);
      });
  }, [location.search]);

  useEffect(() => {
    getPlatformStatsApi();
  }, [getPlatformStatsApi]);

  return (
    <>
      <PageHeader title={"Platform Dashboard"} />
      <Wrapper>
        <Card>
          <>
            <Row>
              <Col span={12}>
                <List size="default" itemLayout="vertical">
                  <ListItem type="primary">
                    <Text>
                      Total Users Signed Up: {platformStats?.totalUsersSignedUp}{" "}
                    </Text>
                  </ListItem>
                  <ListItem type="primary">
                    <Text>
                      Completed Profile: {platformStats?.totalUsersCompletedKyc}{" "}
                    </Text>
                  </ListItem>
                  <ListItem type="primary">
                    <Text>
                      Total number of influencers added:{" "}
                      {platformStats?.totalInfluencerAdded}
                    </Text>
                  </ListItem>
                  <ListItem type="primary">
                    <List
                      size="default"
                      itemLayout="vertical"
                      header={<Text>Numbers by the level:</Text>}
                    >
                      <ListItem type="secondary">
                        <Text>
                          - All stars: {platformStats?.byLevelAllStars}
                        </Text>
                      </ListItem>
                      <ListItem type="secondary">
                        <Text>
                          - Rising stars: {platformStats?.byLevelRisingStars}
                        </Text>
                      </ListItem>
                      <ListItem type="secondary">
                        <Text>- Groups: {platformStats?.byLevelGroups}</Text>
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Col>
              <Col span={12}>
                <List size="default" itemLayout="vertical">
                  <ListItem type="primary">
                    <Text>
                      Secondary market open orders:{" "}
                      {platformStats?.secondaryMarketOpenOrders}{" "}
                    </Text>
                  </ListItem>
                  <ListItem type="primary">
                    <Text>
                      Secondary Market settled orders:{" "}
                      {platformStats?.secondaryMarketSettledOrders}{" "}
                    </Text>
                  </ListItem>
                  <ListItem type="primary">
                    <Text>
                      Total barters:{"  "}
                      {platformStats?.countBarter}
                    </Text>
                  </ListItem>
                  <ListItem type="primary">
                    <Text>
                      Total number of posts: {platformStats?.totalPosts}
                    </Text>
                  </ListItem>
                  <ListItem type="primary">
                    <Text>
                      Total number of comments: {platformStats?.totalComments}
                    </Text>
                  </ListItem>
                  <ListItem type="primary">
                    <Text>
                      Total number of reactions: {platformStats?.totalReactions}
                    </Text>
                  </ListItem>
                </List>
              </Col>
            </Row>
          </>
        </Card>
      </Wrapper>
    </>
  );
};

export default memo(PlatformDashboardScreen);
