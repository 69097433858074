import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";

export const getInfluencersRevenue = async (params?: object) => {
  const endpoint = Endpoints.INFLUENCER + "/stats";
  return api
    .get(endpoint, {
      params: { ...params },
    })
    .then((response) => response.data);
};
