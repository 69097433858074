import { CognitoUserPool } from "amazon-cognito-identity-js";
import { awsCognitoSettings } from "api/config";

const userPoolId = awsCognitoSettings.USERPOOL_ID;
const clientId = awsCognitoSettings.APP_CLIENT_ID;

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};

export default new CognitoUserPool(poolData);
