import { InfluencerType } from "features/influencers/types/InfuencerType";
import { AppUserType } from "features/appUsers/types/AppUserType";
import {
  getRefreshToken,
  getAccessToken,
  getIdToken,
} from "features/identity/authHelper";
import { defaultSettings as ENV, awsCognitoSettings } from "api/config";

const userPoolId = awsCognitoSettings.USERPOOL_ID;
const clientId = awsCognitoSettings.APP_CLIENT_ID;

export const emulateInfluencer = (influencer: InfluencerType, user) => {
  const { id } = influencer;
  const url = `${
    ENV.APP_URL
  }?emulateId=${id}&refreshToken=${getRefreshToken()}&username=${user.name}`;
  console.log("INFL EMULATION URL:", url);
  window.open(url, "_blank");
};

export const emulateAppUser = (appUser: AppUserType, user) => {
  const { id } = appUser;
  const url = `${
    ENV.APP_MAIN_URL
  }/home?emulateId=${id}&userPoolId=${userPoolId}&clientId=${clientId}&refreshToken=${getRefreshToken()}&accessToken=${getAccessToken()}&idToken=${getIdToken()}&username=${
    user.name
  }`;
  console.log("USER EMULATION URL:", url);
  window.open(url, "_blank");
};
