import React, { memo } from "react";
import styles from "./Loading.module.scss";
import { Spin } from "antd";
import cx from "classnames";

interface IProps {
  isFullscreen?: boolean;
}

const Loading = ({ isFullscreen = false }: IProps) => {
  return (
    <div className={cx({ [styles.container]: isFullscreen })}>
      <Spin />
    </div>
  );
};

export default memo(Loading);
