import { IMenuItemType } from "router/routerType";
import { UserSwitchOutlined } from "@ant-design/icons";
import InfluencersScreen from "../screens/InfluencersScreen/InfluencersScreen";
import InfluencerDetail from "../screens/InfluencersScreen/components/InfluencerDetail/InfluencerDetail";

/** Screen: Dashboard Page */
export const INFLUENCERS_SCREEN: IMenuItemType = {
  id: "influencers",
  path: "/influencers",
  component: InfluencersScreen,
  title: "Influencers",
  icon: UserSwitchOutlined,
  permissions: [],
};
export const INFLUENCERS_DETAIL_SCREEN: IMenuItemType = {
  id: "influencersDetail",
  path: `/influencers/:influencerId`,
  component: InfluencerDetail,
  title: "App Users Detail",
  icon: UserSwitchOutlined,
  permissions: [],
};
