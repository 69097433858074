import { memo, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import { AdminsData } from "features/admins/types/AdminsData";
import { getAllAdmins } from "features/admins/api/adminsApi";
import Wrapper from "ui/Wrapper/Wrapper";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import AdminCreationPopup from "features/admins/screens/components/AdminCreationPopup";

const AdminsScreen = () => {
  const [admins, setAdmins] = useState<AdminsData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<object>({
    simple: true,
    current: 1,
    pageSize: 10,
  });

  const getAdminsApi = useCallback(() => {
    setTableLoading(true);
    getAllAdmins()
      .then((res) => {
        setAdmins(res);
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, []);

  const handleTableChange = (pagination) => {
    console.log(pagination);
    setPagination(pagination);
  };

  useEffect(() => {
    getAdminsApi();
  }, [getAdminsApi]);

  const columns: ColumnsType = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (text) => <span>{text || "-"}</span>,
    },
  ];

  return (
    <>
      <PageHeader title={"Admins"} />
      <Wrapper>
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={admins}
          exportableDataTableName={`Admins`}
          showColumnPicker={true}
          onChange={handleTableChange}
          pagination={pagination}
        />
        <AdminCreationPopup successCallback={getAdminsApi} />
      </Wrapper>
    </>
  );
};

export default memo(AdminsScreen);
