import { memo, useEffect, useState, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import { WithdrawData } from "features/withdraw/types/WithdrawData";
import {
  getWithdraws,
  updateWithdraw,
} from "features/withdraw/api/withdrawApi";
import Wrapper from "ui/Wrapper/Wrapper";
import Table from "ui/Table/Table";
import qs from "query-string";

import getWithdrawColumns from "../components/WithdrawColumns";
import WithdrawDetailsPopup from "../components/WithdrawDetailsModal";

const WithdrawScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const [withdraws, setWithdraws] = useState<WithdrawData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<object>({
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
  });
  const [isDetailsPopupVisible, setIsDetailsPopupVisible] = useState(false);
  const [currentWithdraw, setCurrentWithdraw] = useState<any>(null);

  const getWithdrawApi = useCallback(() => {
    setTableLoading(true);
    let params = qs.parse(location.search);
    getWithdraws(params)
      .then((res) => {
        const { docs } = res;
        setWithdraws(docs);
        setPagination((prevPagination) => {
          const current = parseInt(params.page as string);
          const pageSize = parseInt(params.per as string);
          const basePagination = {
            ...prevPagination,
            total: res.total,
          };

          const result =
            current && pageSize
              ? {
                  ...basePagination,
                  current,
                  pageSize,
                }
              : basePagination;
          return result;
        });
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, [location.search]);

  const handleTableChange = (pagination) => {
    console.log(pagination);
    let values: any = {
      ...qs.parse(location.search),
      page: pagination.current,
      per: pagination.pageSize,
    };
    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });
    setPagination(pagination);
  };

  useEffect(() => {
    getWithdrawApi();
  }, [getWithdrawApi]);

  const columns = getWithdrawColumns({
    getWithdrawApi,
    setCurrentWithdraw,
    setIsDetailsPopupVisible,
  });

  const handleDetailsUpdate = (values) => {
    const { withdrawDetails } = values;
    updateWithdraw(currentWithdraw?.id, { details: withdrawDetails })
      .then(() => {
        getWithdrawApi();
      })
      .catch((e) => {
        errorResponseMessages(e);
      });
  };

  return (
    <>
      <PageHeader title={"Withdraws"} />
      <Wrapper>
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={withdraws}
          exportableDataTableName={`Withdraws`}
          showColumnPicker={true}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </Wrapper>
      <WithdrawDetailsPopup
        visible={isDetailsPopupVisible}
        setVisible={setIsDetailsPopupVisible}
        withdraw={currentWithdraw}
        callback={handleDetailsUpdate}
      />
    </>
  );
};

export default memo(WithdrawScreen);
