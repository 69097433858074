import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import Button from "ui/Buttons/Button";
import Table from "ui/Table/Table";
import Drawer from "ui/Drawer/Drawer";
import { AppUserType } from "features/appUsers/types/AppUserType";
import Loading from "ui/Loading/Loading";
import { getNftTransactionsPerAppUser } from "features/appUsers/api/appUsersApi";
import { errorResponseMessages } from "api/apiErrorHelper";
import { useLocation } from "react-router-dom";
import { AppUserNftTransaction } from "features/appUsers/types/AppUserTransaction";
import styles from "./TransactionsDrawer.module.scss";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const TransactionsDrawer = ({
  visible,
  refreshApi,
  onClose,
  appUser,
}: IProps) => {
  const [appUserTransactionLoading, setAppUserNftTransactionLoading] =
    useState<boolean>(false);
  const [appUserTransaction, setAppUserNftTransactions] =
    useState<AppUserNftTransaction[]>();
  const location = useLocation();

  const onCloseDrawer = () => {
    onClose && onClose();
  };

  const geAppUserOrderAPI = useCallback(() => {
    if (appUser) {
      setAppUserNftTransactionLoading(true);
      getNftTransactionsPerAppUser(appUser?.id)
        .then((res) => {
          console.log(res);
          setAppUserNftTransactions(res);
        })
        .catch((e) => {
          errorResponseMessages(e);
        })
        .finally(() => {
          setAppUserNftTransactionLoading(false);
        });
    }
  }, [appUser, visible]);

  useEffect(() => {
    geAppUserOrderAPI();
  }, [geAppUserOrderAPI]);

  const showDrawerKycTransactions = (url?: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns: ColumnsType = [
    {
      title: (
        <>
          <Popover style={{ width: 200 }} content={"Token Id"} trigger="hover">
            Token Id
          </Popover>
        </>
      ),
      dataIndex: "tokenId",
      key: "tokenId",
      render: (text) => <span>{text}</span>,
    },
    {
      title: (
        <>
          <Popover
            style={{ width: 200 }}
            content={"Serial Code"}
            trigger="hover"
          >
            Serial Code
          </Popover>
        </>
      ),
      dataIndex: "serialCode",
      key: "serialCode",
      render: (text) => (
        <>
          <Popover
            style={{ width: 200 }}
            content={text}
            title="Name"
            trigger="hover"
          >
            {text}
          </Popover>
        </>
      ),
    },
    {
      title: (
        <>
          <Popover
            style={{ width: 200 }}
            content={"Current Owner Id"}
            trigger="hover"
          >
            Current Owner Id
          </Popover>
        </>
      ),
      dataIndex: "currentOwnerId",
      key: "currentOwnerId",
      render: (text) => <span>{text}</span>,
    },
    {
      title: (
        <>
          <Popover
            style={{ width: 200 }}
            content={"Previous Owner Id"}
            trigger="hover"
          >
            Previous Owner Id
          </Popover>
        </>
      ),
      dataIndex: "previousOwnerId",
      key: "previousOwnerId",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Card",
      children: [
        {
          title: "id",
          dataIndex: "card",
          key: "card",
          render: (text) => (
            <Button
              style={{
                display: "inline-flex",
                width: 70,
              }}
              type="primary"
              shape="round"
              disabled={!text.shareableUrl}
              onClick={() => showDrawerKycTransactions(text.shareableUrl)}
            >
              <>
                <Popover
                  style={{ width: 200 }}
                  content={"Redirect to card public profile"}
                  trigger="hover"
                >
                  {text.id}
                </Popover>
              </>
            </Button>
          ),
          width: "8%",
        },
        {
          title: (
            <>
              <Popover
                style={{ width: 200 }}
                content={"Card asset label"}
                trigger="hover"
              >
                Asset Label
              </Popover>
            </>
          ),
          dataIndex: "card",
          key: "card",
          render: (text) => <span>{text.assetLabel}</span>,
          width: "8%",
        },
        {
          title: (
            <>
              <Popover
                style={{ width: 200 }}
                content={"Card status"}
                trigger="hover"
              >
                status
              </Popover>
            </>
          ),
          dataIndex: "card",
          key: "card",
          render: (text) => <span>{text.status}</span>,
          width: "8%",
        },
      ],
    },
    {
      title: "Influencer",
      children: [
        {
          title: (
            <>
              <Popover
                style={{ width: 200 }}
                content={"Influencer name"}
                trigger="hover"
              >
                name
              </Popover>
            </>
          ),
          dataIndex: "card",
          key: "card",
          width: "10%",
          render: (text) => (
            <>
              <Popover
                style={{ width: 200 }}
                content={text?.influencer?.name}
                title="Name"
                trigger="hover"
              >
                {text?.influencer?.name}
              </Popover>
            </>
          ),
        },
      ],
    },
    {
      title: "Transactions",
      children: [
        {
          title: (
            <>
              <Popover
                style={{ width: 200 }}
                content={"Transaction Id"}
                trigger="hover"
              >
                Transaction Id
              </Popover>
            </>
          ),
          dataIndex: "transaction",
          key: "transaction",
          render: (text) => <span>{text.transactionId}</span>,
        },
        {
          title: (
            <>
              <Popover
                style={{ width: 200 }}
                content={"Order Id"}
                trigger="hover"
              >
                Order Id
              </Popover>
            </>
          ),
          dataIndex: "transaction",
          key: "transaction",
          render: (text) => <span>{text.orderId}</span>,
        },
        {
          title: (
            <>
              <Popover
                style={{ width: 200 }}
                content={"Transaction type"}
                trigger="hover"
              >
                Type
              </Popover>
            </>
          ),
          dataIndex: "transaction",
          key: "transaction",
          render: (text) => <span>{text.type}</span>,
        },
        {
          title: (
            <>
              <Popover
                style={{ width: 200 }}
                content={"Value USD"}
                trigger="hover"
              >
                Value USD
              </Popover>
            </>
          ),
          dataIndex: "transaction",
          key: "transaction",
          render: (text) => <span>{text.valueUSD}</span>,
        },
        {
          title: (
            <>
              <Popover
                style={{ width: 200 }}
                content={"Transaction status"}
                trigger="hover"
              >
                status
              </Popover>
            </>
          ),
          dataIndex: "transaction",
          key: "transaction",
          render: (text) => <span>{text.status}</span>,
        },
      ],
    },
  ];

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={"95%"}
    >
      <div className={styles.dropStatusHeadder}>
        {!appUserTransactionLoading && (
          <>
            <Card>
              <Table
                // title={() => <span>Transaction</span>}
                // bordered
                loading={appUserTransactionLoading}
                columns={columns}
                dataSource={appUserTransaction}
                scroll={{
                  y: 750,
                }}
              />
            </Card>
          </>
        )}
        {appUserTransactionLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(TransactionsDrawer);
