import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";

export const getSets = (params?: object) => {
  return api
    .get(Endpoints.SETS, {
      params: { ...params },
    })
    .then((response) => response.data);
};

export const deleteTemplate = (id) => {
  const url = Endpoints.SETS + "/" + id;
  return api.delete(url).then((response) => response.data);
};

export const updateTemplate = (id, data) => {
  const url = Endpoints.SETS + "/" + id;
  return api.patch(url, data).then((response) => response.data);
};

export const createTemplate = (data) => {
  const url = Endpoints.SETS;
  return api.post(url, data).then((response) => response.data);
};
