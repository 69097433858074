import { memo, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { errorResponseMessages } from "api/apiErrorHelper";
import PageHeader from "ui/PageHeader/PageHeader";
import { NO_VALUE } from "ui/uiConsts";
import { BannersData } from "features/banners/types/BannersData";
import { getBanners } from "features/banners/api/bannersApi";
import Wrapper from "ui/Wrapper/Wrapper";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import BannerPopup from "features/banners/screens/components/BannerPopup/BannerPopup";
import { FileImageOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Image, Tooltip } from "antd";

import styles from "./BannersScreen.module.scss";

const formatText = (text) => <span>{text || NO_VALUE}</span>;

const formatLink = (id = null) => {
  return (
    <Link
      to={`/banners/${id}/details`}
      target="_blank"
      rel="noopener noreferrer"
      className={id ? "" : styles.disabled}
    >
      {id ? id : NO_VALUE}
    </Link>
  );
};

const formatImage = (url) => {
  if (!url) {
    return <FileImageOutlined />;
  }

  return (
    <Image
      preview={{
        mask: (
          <Tooltip title="Preview">
            <ZoomInOutlined />
          </Tooltip>
        ),
      }}
      width={50}
      height={50}
      src={url}
      alt="Banner image"
    />
  );
};

const BannersScreen = () => {
  const [banners, setBanners] = useState<BannersData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const getBannersApi = useCallback(() => {
    setTableLoading(true);
    getBanners()
      .then((res) => {
        console.log(res);
        setBanners(res);
      })
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => setTableLoading(false));
  }, []);

  useEffect(() => {
    getBannersApi();
  }, [getBannersApi]);

  const columns: ColumnsType = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      render: formatLink,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: formatText,
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
      render: formatText,
    },
    {
      title: "Desktop Image",
      dataIndex: "src",
      key: "src",
      align: "center",
      width: 150,
      render: formatImage,
    },
    {
      title: "Mobile Image",
      dataIndex: "srcMobile",
      key: "srcMobile",
      align: "center",
      width: 150,
      render: formatImage,
    },
  ];

  return (
    <>
      <PageHeader title={"Banners"} />
      <Wrapper>
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={banners}
          exportableDataTableName={`Banners`}
          showColumnPicker={true}
        />
        <BannerPopup banner={null} successCallback={getBannersApi} />
      </Wrapper>
    </>
  );
};

export default memo(BannersScreen);
