import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";

export const getInfluencers = (params?: object) => {
  return api
    .get(Endpoints.INFLUENCERS, {
      params: { ...params },
    })
    .then((response) => response.data);
};

export const listInfluencer = (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/list";
  return api.post(endpoint).then((response) => response.data);
};

export const getInfluencerDropStatus = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/status";
  return api.get(endpoint).then((response) => response.data);
};

export const setStartDropProcessInfluencer = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/drop";
  const response = await api.post(endpoint);
  return response.data;
};

export const getInfluencerDetails = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/details";
  return api.get(endpoint).then((response) => response.data);
};

export const getInfluencer = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID;
  return api.get(endpoint).then((response) => response.data);
};

export const updateInfluencer = async (influencerID, data) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID;
  return api.put(endpoint, data).then((response) => response.data);
};

export const deleteInfluencer = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID;
  return api.delete(endpoint).then((response) => response.data);
};

export const setReStartDropProcessInfluencer = async (influencerID) => {
  const endpoint = Endpoints.INFLUENCER + "/" + influencerID + "/progress-drop";
  const response = await api.post(endpoint);
  return response.data;
};

export const setDropStartDateInfluencer = async (influencerID, data) => {
  const endpoint =
    Endpoints.INFLUENCER + "/" + influencerID + "/drop-start-date";
  const response = await api.post(endpoint, data);
  return response.data;
};

export const setPresignedUrlInfluencerImage = async (influencerID) => {
  const endpoint =
    Endpoints.INFLUENCER + "/" + influencerID + "/generate-upload-s3-url";
  const response = await api.post(endpoint);
  return response.data;
};
