import { useState, useEffect } from "react";
import { Button, Modal, Form, Input, message } from "antd";

const { TextArea } = Input;

const WithdrawDetailsPopup = ({ visible, setVisible, withdraw, callback }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      callback(values);
      setVisible(false);
    } catch (error: any) {
      if (error instanceof Error) {
        console.error("Error:", error.message);
        message.error(error.message);
      } else if (error.errors) {
        console.error("Validation Failed:", error);
        message.error("Validation Failed");
      } else {
        console.error("Unknown error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (withdraw) {
      form.setFieldsValue({
        withdrawDetails: withdraw?.details,
      });
    }
  }, [withdraw, form]);

  return (
    <div>
      <Modal
        title="Withdraw Details"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" name="withdraw_details_form">
          <Form.Item
            name="withdrawDetails"
            label="Withdraw Details"
            initialValue={withdraw?.details}
          >
            <TextArea placeholder="Enter your withdraw details" rows={4} />
          </Form.Item>
          <Form.Item>
            <Button
              size="middle"
              htmlType="submit"
              onClick={handleOk}
              loading={loading}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default WithdrawDetailsPopup;
