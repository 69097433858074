import { Typography, Col, Row, Button } from "antd";
import { NO_VALUE } from "ui/uiConsts";
import BannerPopup from "../BannerPopup/BannerPopup";
import styles from "../../BannerDetailsScreen/BannerDetails.module.scss";
import moment from "moment";

const { Title, Text } = Typography;

const TIME_FORMAT = "DD MMM YYYY : HH:mm:SS";

const Details = ({ banner, updateCallback, deleteCallback }) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>Details</Title>
        </Col>
      </Row>
      <Row className={styles.block}>
        <Col span={10}>
          <div className={styles.infoBlock}>
            <Text type="secondary">Name</Text>
            <Text>{banner?.name || NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Path</Text>
            <Text>{banner?.path || NO_VALUE}</Text>
          </div>
        </Col>

        <Col span={10}>
          <div className={styles.infoBlock}>
            <Text type="secondary">Updated At</Text>
            <Text>
              {banner.updatedAt
                ? moment(banner.updatedAt).format(TIME_FORMAT)
                : NO_VALUE}
            </Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Created At</Text>
            <Text>
              {banner.createdAt
                ? moment(banner.createdAt).format(TIME_FORMAT)
                : NO_VALUE}
            </Text>
          </div>
        </Col>
      </Row>
      <Row>
        <BannerPopup banner={banner} successCallback={updateCallback} />
        <Button
          className={styles.deleteButton}
          danger
          onClick={() => deleteCallback()}
        >
          Delete Banner
        </Button>
      </Row>
    </>
  );
};
export default Details;
