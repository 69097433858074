import React, { PropsWithChildren } from "react";
import { Form as AntdForm } from "antd";
import { FormProps } from "antd/lib/form";

interface IProps extends FormProps {
  /** Form fields */
  children: any;
}

const Form = ({ children, ...rest }: PropsWithChildren<IProps>) => {
  return <AntdForm {...rest}>{children}</AntdForm>;
};

export default Form;
