import packageJson from "../../package.json";

const env: any = process.env;

export const defaultSettings = {
  VERSION: packageJson.version,
  API_HOST: env.REACT_APP_API_HOST,
  APP_URL: env.REACT_APP_INFL_URL,
  APP_MAIN_URL: env.REACT_APP_MAIN_URL,
};

export const awsCognitoSettings = {
  USERPOOL_ID: env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
  APP_CLIENT_ID: env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
};
