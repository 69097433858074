import { Link } from "react-router-dom";
import { Menu, Typography, Image, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FileImageOutlined, ZoomInOutlined } from "@ant-design/icons";
import { NO_VALUE } from "ui/uiConsts";
import styles from "./CustomCardsColumns.module.scss";

const { Text } = Typography;

const formatTime = (dateStr) => {
  let formattedDate = NO_VALUE;
  if (dateStr) {
    const date = new Date(dateStr);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    formattedDate = date.toLocaleString("en-US", options);
  }

  return <span>{formattedDate}</span>;
};

const formatText = (text) => <span>{text || NO_VALUE}</span>;

const formatLink = (influencer) => {
  const { id } = influencer || {};
  return (
    <Link
      to={`/influencers/${id}/details`}
      target="_blank"
      rel="noopener noreferrer"
      className={id ? "" : styles.disabled}
    >
      {id ? id : NO_VALUE}
    </Link>
  );
};

const formatImage = (url) => {
  if (!url) {
    return <FileImageOutlined />;
  }

  return (
    <Image
      preview={{
        mask: (
          <Tooltip title="Preview">
            <ZoomInOutlined />
          </Tooltip>
        ),
      }}
      width={50}
      height={50}
      src={url}
      alt="Custom card image"
    />
  );
};

const getCustomCardsColumns = ({
  setCurrentCard,
  setIsCustomCardStatusPopupVisible,
}): ColumnsType<any> => {
  return [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      render: formatText,
    },
    {
      title: "Influencer",
      dataIndex: "influencer",
      key: "influencer",
      align: "center",
      width: 150,
      render: formatLink,
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "image",
      align: "center",
      width: 150,
      render: formatImage,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: formatText,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: formatText,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: formatText,
    },
    {
      title: "Review status",
      dataIndex: "reviewStatus",
      key: "reviewStatus",
      align: "center",
      width: 150,
      render: formatText,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 300,
      render: formatTime,
    },
    {
      title: "Actions",
      key: "actions",
      width: "5%",
      align: "center",
      render: (card: any) => {
        return (
          <Menu mode="horizontal">
            <Menu.Item
              key="1"
              onClick={() => {
                setCurrentCard(card);
                setIsCustomCardStatusPopupVisible(true);
              }}
            >
              <Text>Change review status</Text>
            </Menu.Item>
          </Menu>
        );
      },
    },
  ];
};

export default getCustomCardsColumns;
