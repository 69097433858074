import { IMenuItemType } from "router/routerType";
import { TransactionOutlined } from "@ant-design/icons";
import withdrawScreen from "../screens/WithdrawScreen/withdrawScreen";

/** Screen: Dashboard Page */
export const WITHDRAW_SCREEN: IMenuItemType = {
  id: "withdraw",
  path: "/withdraws",
  component: withdrawScreen,
  title: "Withdraws",
  icon: TransactionOutlined,
  permissions: [],
};
