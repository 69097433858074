import React from "react";
import { Layout } from "antd";
import styles from "./Content.module.scss";
import cx from "classnames";

const { Content: AntDContent } = Layout;

interface IProps {
  collapsed: boolean;
  children: any;
}

const Content = ({ collapsed, children }: IProps) => {
  return (
    <AntDContent
      className={cx(styles.contentWrapper, {
        [styles.collapsedWidth]: collapsed,
      })}
    >
      {children}
    </AntDContent>
  );
};

export default Content;
