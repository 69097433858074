import { Link } from "react-router-dom";
import { Menu, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { NO_VALUE } from "ui/uiConsts";
import styles from "./WithdrawColumns.module.scss";
import { updateWithdraw } from "features/withdraw/api/withdrawApi";
import { errorResponseMessages } from "api/apiErrorHelper";

const { Text } = Typography;

const formatTime = (dateStr) => {
  let formattedDate = NO_VALUE;
  if (dateStr) {
    const date = new Date(dateStr);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    formattedDate = date.toLocaleString("en-US", options);
  }

  return <span>{formattedDate}</span>;
};

const formatText = (text) => <span>{text || NO_VALUE}</span>;

const formatLink = (text) => {
  return (
    <Link
      to={`/app-users/${text}/details`}
      target="_blank"
      rel="noopener noreferrer"
      className={text ? "" : styles.disabled}
    >
      {text ? text : NO_VALUE}
    </Link>
  );
};

const updateStatus = (id: number, status: string, callback: Function) => {
  updateWithdraw(id, { status })
    .then(() => {
      callback();
    })
    .catch((e) => {
      errorResponseMessages(e);
    });
};

const getInfluencersColumns = ({
  getWithdrawApi,
  setCurrentWithdraw,
  setIsDetailsPopupVisible,
}): ColumnsType<any> => {
  return [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      render: formatText,
    },
    {
      title: "App user",
      dataIndex: "appUserId",
      key: "appUserId",
      align: "center",
      width: 100,
      render: formatLink,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: formatText,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: formatText,
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      render: formatText,
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: formatText,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 300,
      render: formatTime,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 300,
      render: formatTime,
    },
    {
      title: "Actions",
      key: "actions",
      width: "5%",
      align: "center",
      render: (withdraw: any) => {
        const { status } = withdraw;
        const isDisabled = status !== "pending_approval";
        return (
          <Menu mode="horizontal">
            <Menu.Item
              disabled={isDisabled}
              key="1"
              onClick={() =>
                updateStatus(withdraw.id, "approved", getWithdrawApi)
              }
            >
              <Text>Approve</Text>
            </Menu.Item>
            <Menu.Item
              disabled={isDisabled}
              key="2"
              onClick={() =>
                updateStatus(withdraw.id, "denied", getWithdrawApi)
              }
            >
              <Text>Reject</Text>
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => {
                setCurrentWithdraw(withdraw);
                setIsDetailsPopupVisible(true);
              }}
            >
              <Text>Comment</Text>
            </Menu.Item>
          </Menu>
        );
      },
    },
  ];
};

export default getInfluencersColumns;
