import React, { useEffect, useReducer } from "react";
import isEmpty from "lodash/isEmpty";

export const USER_KEY = "user";

type UserContextDef = {
  name?: string;
  email?: string;
  permissions: {
    permission1: boolean;
    permission2: boolean;
    permission3: boolean;
  };
  authenticated: boolean;
  authenticationError?: string;
};

type UserContextState = {
  user: UserContextDef;
  setUser: React.Dispatch<Partial<UserContextDef>>;
};

const reducer = (user: UserContextDef, setUser: Partial<UserContextDef>) => {
  if (isEmpty(setUser)) {
    return {
      name: "",
      email: "",
      authenticated: false,
      permissions: {
        permission1: false,
        permission2: false,
        permission3: false,
      },
    };
  }

  return { ...user, ...setUser };
};

const initialState = {};

const sessionUser = sessionStorage.getItem(USER_KEY);
const sessionState = sessionUser ? JSON.parse(sessionUser) : null;

const UserContext = React.createContext({} as UserContextState);

function UserProvider({ children }) {
  const [user, setUser] = useReducer(reducer, sessionState || initialState);

  useEffect(() => {
    sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
