import { IMenuItemType } from "router/routerType";
import { DollarOutlined } from "@ant-design/icons";
import revenueScreen from "../screens/RevenueScreen/revenueScreen";

/** Screen: Dashboard Page */
export const REVENUE_SCREEN: IMenuItemType = {
  id: "revenue",
  path: "/revenue",
  component: revenueScreen,
  title: "Revenue",
  icon: DollarOutlined,
  permissions: [],
};
