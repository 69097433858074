import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Card,
  Popover,
  Typography,
  Select,
  Col,
  Button,
  Row,
  Form,
  Input,
  Modal,
  message,
} from "antd";
import {
  LaptopOutlined,
  UserOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import Loading from "ui/Loading/Loading";
import { Timeline } from "antd";
import { useParams } from "react-router-dom";
import styles from "./AppUsersDetails.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import moment from "moment";
import {
  fetchUsersProfile,
  fetchUsersKycHistory,
  updateKYCStatus,
} from "store/usersSlice";
import { NO_VALUE } from "ui/uiConsts";
import KYCStatus from "./KycStatus";
import { UserContext } from "features/identity/userContext";
const { Title, Text } = Typography;

const TIME_FORMAT = "DD MMM YYYY : HH:mm:SS";

const StatusHistoryCard = ({ event }) => (
  <Card bordered={false}>
    <Row gutter={16}>
      <Col span={24}>
        <Text strong>Created at: </Text>
        <span>{moment(event.createdAt).format(TIME_FORMAT)}</span>
      </Col>

      <Col span={24}>
        <Text strong>Updated at: </Text>
        <span>{moment(event.createdAt).format(TIME_FORMAT)}</span>
      </Col>

      <Col span={24}>
        <Text strong>Previous Status: </Text>
        <span>{event.oldStatus || NO_VALUE}</span>
      </Col>

      <Col span={24}>
        <Text strong>Current Status: </Text>
        <span>{event.newStatus || NO_VALUE}</span>
      </Col>

      <Col span={24}>
        <Text strong>Updated by: </Text>
        <span>{event.updatedBy || NO_VALUE}</span>
      </Col>

      <Col span={24}>
        <Text strong>Comment: </Text>
        <span>{event.comment || NO_VALUE}</span>
      </Col>
    </Row>
  </Card>
);

const TimelineLabel = ({ createdAt, updatedAt }) => {
  return (
    <div>
      {moment(createdAt).format(TIME_FORMAT)}
      <ArrowRightOutlined style={{ margin: "0 8px" }} />
      {moment(updatedAt).format(TIME_FORMAT)}
    </div>
  );
};

const KYCHistory = ({ profile }) => {
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();
  const { user } = useContext(UserContext);

  const [isModalKYCVisible, setIsModalKYCVisible] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const [kycStatus, setKycStatus] = useState(profile?.kycStatus || NO_VALUE);
  const [comment, setComment] = useState();

  const appUserStatusHistory = useSelector(
    (state: RootState) => state.users.detail.kycHistory.entities
  );
  const appUserUserStatusHistoryLoading = useSelector(
    (state: RootState) => state.users.detail.kycHistory.loading
  );

  const geAppUserStatusHistoryAPI = useCallback(() => {
    if (userId) {
      dispatch(fetchUsersKycHistory(userId));
    }
  }, [userId]);

  useEffect(() => {
    geAppUserStatusHistoryAPI();
  }, [geAppUserStatusHistoryAPI]);

  const handleCancelKyc = () => {
    setIsModalKYCVisible(false);
    setEdit(false);
    form.resetFields();
  };

  const onUpdateKYCStatus = ({ profile }) => {
    setIsModalKYCVisible(true);
    setEdit(true);

    form.setFieldsValue({
      ...profile,
    });
  };

  const onFinishKYC = async (values: any) => {
    setIsModalKYCVisible(false);
    const { kycStatus, comment } = values;

    dispatch(
      updateKYCStatus({
        appUserId: profile.appUserID,
        newStatus: kycStatus,
        comment,
        updatedBy: user?.email || "admin",
      })
    )
      .then((res) => {
        form.resetFields();
        setKycStatus(kycStatus);
        setComment(comment);
        dispatch(fetchUsersProfile(profile.appUserID));
      })
      .catch((e) => message.error("Couldn't update the KYC status"));
  };

  const modalKYCStatus = (
    <>
      <Modal
        title={"Change KYC status"}
        visible={isModalKYCVisible}
        onCancel={handleCancelKyc}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          initialValues={{
            kycStatus,
            comment,
          }}
          onFinish={onFinishKYC}
        >
          <Form.Item name="kycStatus" label="Kyc status">
            <Select style={{ width: "100%" }} placeholder="Please select">
              <Select.Option value="REQUIRED">REQUIRED</Select.Option>
              <Select.Option value="ACCEPT">ACCEPT</Select.Option>
              <Select.Option value="DENY">DENY</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="comment" label="Comment">
            <Input.TextArea rows={10} ref={comment} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 5, span: 18 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  return (
    <div className={styles.dropStatusHeadder}>
      {appUserUserStatusHistoryLoading ? (
        <Loading isFullscreen={false} />
      ) : (
        <>
          {modalKYCStatus}
          <Row>
            <Col span={24}>
              <Title level={4}>KYC History</Title>
            </Col>
          </Row>
          <Card>
            <Row align={"middle"} className={styles.changeRoleWrapper}>
              <Col span={6}>
                <Text strong>User: </Text>
                <Text>
                  {profile.firstName} {profile.lastName}
                </Text>
              </Col>
              <Col span={6}>
                <Text strong>Status: </Text>
                <KYCStatus profile={profile} />
              </Col>
              <Col span={6}></Col>
              <Col span={6}>
                <Button
                  size="middle"
                  onClick={() => onUpdateKYCStatus(profile)}
                  disabled={appUserStatusHistory?.length === 0}
                >
                  Change Kyc Status
                </Button>
              </Col>
            </Row>
          </Card>
          <Card>
            <Timeline mode="left">
              {appUserStatusHistory?.map((event, idx) => (
                <Timeline.Item
                  key={idx}
                  dot={
                    event.updatedBy !== "system" ? (
                      <UserOutlined
                        type="origin"
                        style={{ fontSize: "12px" }}
                      />
                    ) : (
                      <LaptopOutlined
                        type="origin"
                        style={{ fontSize: "12px" }}
                      />
                    )
                  }
                  color={
                    event.newStatus === "ACCEPT"
                      ? "green"
                      : event.newStatus === "DENY"
                      ? "red"
                      : "blue"
                  }
                  label={
                    <TimelineLabel
                      createdAt={event.createdAt}
                      updatedAt={event.updatedAt}
                    />
                  }
                >
                  <Popover
                    overlayStyle={{ width: "400px" }}
                    content={<StatusHistoryCard event={event} />}
                    trigger="hover"
                  >
                    {event.oldStatus ? event.oldStatus : NO_VALUE}{" "}
                    <ArrowRightOutlined />{" "}
                    {event.newStatus ? event.newStatus : NO_VALUE}
                    <span style={{ marginLeft: "10px", fontStyle: "italic" }}>
                      by: {event.updatedBy}
                    </span>
                  </Popover>
                </Timeline.Item>
              ))}
            </Timeline>
          </Card>
        </>
      )}
    </div>
  );
};

export default memo(KYCHistory);
