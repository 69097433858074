import { IMenuItemType } from "router/routerType";
import { BarChartOutlined } from "@ant-design/icons";
import SetsScreen from "../screens/influencerRevenueScreen/influencerRevenueScreen";

/** Screen: Dashboard Page */
export const INFLUENCER_REVENUE_SCREEN: IMenuItemType = {
  id: "influencerRevenue",
  path: "/stats",
  component: SetsScreen,
  title: "Influencer Revenue",
  icon: BarChartOutlined,
  permissions: [],
};
