import { IMenuItemType } from "router/routerType";
import { UserSwitchOutlined } from "@ant-design/icons";
import AppUsersScreen from "../screens/AppUsersScreen";
import AppUsersDetail from "../screens/AppUsersDetail";

/** Screen: Dashboard Page */
export const APP_USERS_SCREEN: IMenuItemType = {
  id: "appUsers",
  path: "/app-users",
  component: AppUsersScreen,
  title: "App Users",
  icon: UserSwitchOutlined,
  permissions: [],
};

export const APP_USERS_DETAIL_SCREEN: IMenuItemType = {
  id: "appUsersDetail",
  path: `/app-users/:userId`,
  component: AppUsersDetail,
  title: "App Users Detail",
  icon: UserSwitchOutlined,
  permissions: [],
};
