import React, { memo, PropsWithChildren } from "react";
import { Drawer as AntDDrawer, DrawerProps } from "antd";

interface IProps extends DrawerProps {}

const Drawer = ({ children, ...rest }: PropsWithChildren<IProps>) => {
  return (
    <div>
      <AntDDrawer
        {...rest}
        // TODO: conditional style
        bodyStyle={{
          paddingRight: "50px",
          paddingLeft: "50px",
          paddingTop: "70px",
          paddingBottom: "80px",
          backgroundColor: "#F9F9FA",
        }}
      >
        {children}
      </AntDDrawer>
    </div>
  );
};

export default memo(Drawer);
