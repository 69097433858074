import { useState, useRef, useEffect, useContext } from "react";
import {
  Typography,
  Select,
  Col,
  Row,
  Modal,
  Form,
  Input,
  Button,
  Card,
  message,
} from "antd";
import styles from "./../../AppUsersDetail.module.scss";
import {
  fetchUsersProfile,
  updateRoleToUser,
  updateStatusToUser,
} from "store/usersSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useLocation, Link } from "react-router-dom";
import { NO_VALUE } from "ui/uiConsts";
import { emulateAppUser } from "helpers/emulationHelper";
import { UserContext } from "features/identity/userContext";
import KYCStatus from "./KycStatus";

const { Title, Text } = Typography;

const Details = ({ profile }) => {
  const { user } = useContext(UserContext);
  const [role, setRole] = useState(profile?.appUser?.role || "customer");
  const [status, setStatus] = useState(profile?.appUser?.status || "active");
  const blockReasonRef = useRef(profile?.appUser?.blockReasonRef);
  const [blockReason, setBlockReason] = useState(
    profile?.appUser?.blockReason || ""
  );
  const [form] = Form.useForm();
  const [formRole] = Form.useForm();
  const [isEdit, setEdit] = useState(false);
  const [loading, setAppUserProfileLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalRoleVisible, setIsModalRoleVisible] = useState(false);

  const location: any = useLocation();

  const dispatch: AppDispatch = useDispatch();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    setRole(profile?.appUser?.role || "customer");
  }, [profile]);

  const showGeoIpLookup = (ipAddress?: string) => {
    if (ipAddress) {
      const URL_GEOIPLOOKUP = `https://geoiplookup.net/ip/${ipAddress}`;
      openInNewTab(URL_GEOIPLOOKUP);
    }
  };

  const showInHederaExplorer = (walletAddress?: string) => {
    if (walletAddress) {
      const URL_GEOIPLOOKUP = `https://hederaexplorer.io/search-details/account/${walletAddress}`;
      openInNewTab(URL_GEOIPLOOKUP);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEdit(false);
    form.resetFields();
  };

  const handleCancelRole = () => {
    setIsModalRoleVisible(false);
    setEdit(false);
    formRole.resetFields();
  };

  const onUpdateStatus = ({ profile }) => {
    setIsModalVisible(true);
    setEdit(true);

    form.setFieldsValue({
      ...profile,
    });
  };

  const onUpdateRole = ({ profile }) => {
    setIsModalRoleVisible(true);
    setEdit(true);

    form.setFieldsValue({
      ...profile,
    });
  };

  const onFinish = async (values: any) => {
    setIsModalVisible(false);
    const { status, blockReason } = values;
    setAppUserProfileLoading(true);

    dispatch(
      updateStatusToUser({ appUserId: profile.appUserID, status, blockReason })
    )
      .then((res) => {
        form.resetFields();
        setBlockReason(blockReason);
        setStatus(status);
        dispatch(fetchUsersProfile(profile.appUserID));
      })
      .catch((e) => message.error("Couldn't update the Status"))
      .finally(() => {
        setAppUserProfileLoading(false);
      });
  };

  const onFinishRole = async (values: any) => {
    setIsModalRoleVisible(false);
    const { role } = values;

    dispatch(updateRoleToUser({ appUserId: profile.appUserID, role }))
      .then((res) => {
        formRole.resetFields();
        setRole(role);
        dispatch(fetchUsersProfile(profile.appUserID));
      })
      .catch((e) => message.error("Couldn't update the Status"))
      .finally(() => {
        setAppUserProfileLoading(false);
      });
  };

  const modalBlockUser = (
    <>
      <Modal
        title={"Change User Status"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          initialValues={{
            status: status,
            blockReason: blockReason,
          }}
          onFinish={onFinish}
        >
          <Form.Item name="status" label="Status">
            <Select style={{ width: "100%" }} placeholder="Please select">
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="blocked">Blocked</Select.Option>
            </Select>
          </Form.Item>

          {/* todo eval active or block to show or not blocreason */}

          <Form.Item name="blockReason" label="Block Reason">
            <Input typeof="TextArea" ref={blockReasonRef} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  const modalChangeUserRole = (
    <>
      <Modal
        title={"Change User Role"}
        visible={isModalRoleVisible}
        onCancel={handleCancelRole}
        footer={null}
      >
        <Form
          form={formRole}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          initialValues={{
            role: role,
          }}
          onFinish={onFinishRole}
        >
          <Form.Item name="role" label="Role">
            <Select style={{ width: "100%" }} placeholder="Please select">
              <Select.Option value="customer">Customer</Select.Option>
              <Select.Option value="moderator">Moderator</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Change
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
  return (
    <>
      {modalBlockUser}
      {modalChangeUserRole}
      <Row>
        <Col span={24}>
          <Title level={4}>Details</Title>
        </Col>
      </Row>
      <Row className={styles.block}>
        <Col span={5}>
          <div className={styles.infoBlock}>
            <Text type="secondary">Full name</Text>
            <Text>
              {profile?.firstName || NO_VALUE} {profile?.lastName || NO_VALUE}
            </Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Date of birth</Text>
            <Text>{profile?.dateOfBirth}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">User Status</Text>
            <Text>{profile?.appUser?.status || NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Has connected Wallet</Text>
            <Text>{profile?.appUser?.hasConnectedWallet ? "Yes" : "No"}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Show KYC status</Text>
            <KYCStatus profile={profile} />
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Last KYC check result</Text>
            <Text>
              {profile?.appUser?.applicant?.lastCheckResult || NO_VALUE}
            </Text>
          </div>
        </Col>

        <Col span={5}>
          <div className={styles.infoBlock}>
            <Text type="secondary">IP Address</Text>
            <Text
              onClick={() => showGeoIpLookup(profile.ipAddress)}
              className={styles.linkRedirect}
            >
              {profile?.ipAddress || NO_VALUE}
            </Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Role</Text>
            <Text>{profile?.appUser?.role || NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Email</Text>
            <Text>{profile?.email || NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Email Verified</Text>
            <Text>{profile?.emailVerified ? "Verified" : "Not verified"}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Is completed</Text>
            <Text>{profile?.isCompleted ? "Yes" : "No"}</Text>
          </div>
        </Col>
        <Col span={5}>
          <div className={styles.infoBlock}>
            <Text type="secondary">Wallet balance</Text>
            <Text>{NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Wallet address</Text>
            <Text
              className={styles.linkRedirect}
              onClick={() =>
                showInHederaExplorer(profile?.appUser?.walletAddress)
              }
            >
              {profile?.appUser?.walletAddress || NO_VALUE}
            </Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Rewards points</Text>
            <Text>{NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Vip</Text>
            <Text>{profile?.appUser?.isVip ? "Yes" : "No"}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Referral Status</Text>
            <Text>{profile?.referralStatus || NO_VALUE}</Text>
          </div>
        </Col>
        <Col span={5}>
          <div className={styles.infoBlock}>
            <Text type="secondary">Country</Text>
            <Text>{profile?.country || NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Address</Text>
            <Text>{profile?.addressLine1 || NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">ZIP / Postal code</Text>
            <Text>{profile?.zipPostcode || NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Language</Text>
            <Text>{profile?.language || NO_VALUE}</Text>
          </div>
          <div className={styles.infoBlock}>
            <Text type="secondary">Status</Text>
            <Text>{status || NO_VALUE}</Text>
          </div>

          <div className={styles.infoBlock}>
            <Text type="secondary">Block reason</Text>
            <Text>{blockReason || NO_VALUE}</Text>
          </div>
        </Col>
        <Col span={4}>
          <div className={styles.infoBlock}>
            <Text type="secondary">Notes</Text>
            <Text>{profile?.notes || NO_VALUE}</Text>
          </div>
        </Col>
      </Row>
      <Card>
        <Row align={"middle"}>
          <Col span={24}>
            <Button
              className={styles.handleButton}
              onClick={() => emulateAppUser(profile?.appUser, user)}
            >
              Emulate user
            </Button>
            <Button
              className={styles.handleButton}
              size="middle"
              onClick={() => onUpdateRole(profile)}
            >
              Change user role
            </Button>

            <Button
              className={styles.handleButton}
              size="middle"
              onClick={() => onUpdateStatus(profile)}
            >
              Change user status
            </Button>

            <Link
              to={
                profile?.influencer?.id
                  ? `/influencers/${profile?.influencer?.id}/details`
                  : "#"
              }
              className={`${styles.handleButton} ant-btn ${
                !profile?.influencer?.id ? styles.disabled : ""
              }`}
            >
              {"Influencer profile"}
            </Link>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Details;
