import React, { useState, useEffect } from "react";
import { Select, Button, Modal, Form, Input, message } from "antd";
import {
  createBannerContent,
  updateBannerContent,
} from "features/banners/api/bannersApi";
const { Option } = Select;

export const LANGUAGES_LIST = {
  english: "en-GB",
  spanish: "es-ES",
  portugues: "pt-BR",
  french: "fr-FR",
  turkish: "tr-TR",
  korean: "ko-KR",
  japanese: "ja-JP",
  chinese: "zh-CN",
  chineseTraditional: "zh-TW",
};

const validateMaxLineBreaks = (maxLineBreaks) => ({
  validator: (_, value) => {
    const lineBreaks = (value.match(/\n/g) || []).length;
    if (lineBreaks >= maxLineBreaks) {
      return Promise.reject(
        `Input must have no more than ${maxLineBreaks} line breaks!`
      );
    }
    return Promise.resolve();
  },
  message: `Input must have no more than ${maxLineBreaks} line breaks!`,
});

const BannerContentPopup = ({
  content,
  visible,
  setVisible,
  banner,
  type,
  successCallback,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      lang: content?.languageCode || "",
      headline: content?.headline || "",
      body: content?.body || "",
      cta: content?.cta || "",
    });
  }, [content, form]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const updatedValues = { ...values, platform: type };
      const res = content
        ? await updateBannerContent(banner?.id, content?.id, updatedValues)
        : await createBannerContent(banner?.id, updatedValues);
      if (res.status === 200) {
        successCallback();
      }
      form.resetFields();
      setVisible(false);
    } catch (error: any) {
      if (error instanceof Error) {
        console.error("Error:", error.message);
        message.error(error.message);
      } else if (error.errors) {
        console.error("Validation Failed:", error);
        message.error("Validation Failed");
      } else {
        console.error("Unknown error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title={`Banner Content ${content ? "Update" : "Creation"}`}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" name="banner_creation_form">
          <Form.Item
            name="lang"
            label="Language"
            rules={[
              {
                required: true,
                message: "Please select a language!",
              },
            ]}
          >
            <Select placeholder="Select a language" showSearch>
              {Object.entries(LANGUAGES_LIST).map(([key, value]) => (
                <Option key={key} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="headline"
            label="Headline"
            rules={[
              {
                required: true,
                message: "Please input the headline!",
              },
              {
                max: 50,
                message: "Headline must be no longer than 50 characters!",
              },
              validateMaxLineBreaks(2),
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="body"
            label="Body"
            rules={[
              {
                required: true,
                message: "Please input the body content!",
              },
              {
                max: 200,
                message: "Body must be no longer than 200 characters!",
              },
              validateMaxLineBreaks(3),
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="cta"
            label="CTA"
            rules={[
              {
                required: true,
                message: "Please input the CTA text!",
              },
              {
                max: 50,
                message: "CTA must be no longer than 50 characters!",
              },
              validateMaxLineBreaks(2),
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              size="middle"
              htmlType="submit"
              onClick={handleOk}
              loading={loading}
            >
              {content ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BannerContentPopup;
