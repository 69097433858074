import React, { memo, useCallback, useEffect, useState } from "react";
import { message, List, Image, Popover, Card } from "antd";
import Drawer from "ui/Drawer/Drawer";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import { AppUserType } from "features/appUsers/types/AppUserType";
import Button from "ui/Buttons/Button";
import Loading from "ui/Loading/Loading";
import styles from "./PaymentsDrawer.module.scss";
import { Payment } from "features/appUsers/types/Payment";
import { getPaymentsMethodsAppUser } from "features/appUsers/api/appUsersApi";
import PageHeader from "ui/PageHeader/PageHeader";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const PaymentDrawer = ({ visible, refreshApi, onClose, appUser }: IProps) => {
  const [appUserPaymentsMethodsLoading, setAppUserPaymentsMethodsLoading] =
    useState<boolean>(false);

  const [appUserPaymentsMethods, setAppUserPaymentsMethods] =
    useState<Payment[]>();
  const onCloseDrawer = () => {
    onClose && onClose();
  };

  const getAppUserPaymentMethodApi = useCallback(() => {
    if (visible && appUser) {
      setAppUserPaymentsMethodsLoading(true);
      getPaymentsMethodsAppUser(appUser?.id)
        .then((res) => {
          setAppUserPaymentsMethods(res);
        })
        .catch((e) => message.error("Couldn't load Status"))
        .finally(() => setAppUserPaymentsMethodsLoading(false));
    }
  }, [appUser, visible]);

  useEffect(() => {
    getAppUserPaymentMethodApi();
  }, [getAppUserPaymentMethodApi]);

  const columns: ColumnsType = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
      width: "2%",
    },
    {
      title: "Payment Method Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <>
          <Popover
            style={{ width: 500 }}
            content={text}
            title="Payment Method Name"
            trigger="hover"
          >
            {text}
          </Popover>
        </>
      ),
      width: "8%",
    },
    {
      title: "Is Default",
      dataIndex: "isDefault",
      key: "isDefault",
      render: (text) => <span>{text ? "Yes" : "No"}</span>,
      width: "3%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => (
        <>
          <Popover
            style={{ width: 500 }}
            content={text}
            title="Type"
            trigger="hover"
          >
            {text}
          </Popover>
        </>
      ),
      width: "10%",
    },
    {
      title: "Metod name",
      dataIndex: "methodInfo",
      key: "methodInfo",
      width: "5%",
      render: (text) => (
        <>
          <Popover
            style={{ width: 500 }}
            content={text.name}
            title="plaidAccountId"
            trigger="hover"
          >
            {text.name}
          </Popover>
        </>
      ),
    },
    {
      title: "User Account",
      width: "10%",
      children: [
        {
          title: "Amount",
          dataIndex: "appUserAccount",
          key: "appUserAccount",
          render: (text) => <span>{text?.amount}</span>,
          width: "5%",
        },
        {
          title: "Amount Locked",
          dataIndex: "appUserAccount",
          key: "appUserAccount",
          render: (text) => <span>{text?.amountLocked}</span>,
          width: "5%",
        },
      ],
    },
  ];

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={"90%"}
    >
      <PageHeader title={"Payments"} />

      <div className={styles.dropStatusHeadder}>
        {!appUserPaymentsMethodsLoading && (
          <>
            <Card>
              <Table
                loading={appUserPaymentsMethodsLoading}
                columns={columns}
                dataSource={appUserPaymentsMethods}
              />
            </Card>
          </>
        )}
        {appUserPaymentsMethodsLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(PaymentDrawer);
