import React, { memo } from "react";
// import { Table as AntDTable } from "antd";
import { Table as AntDTable } from "ant-table-extensions";
import { TableProps } from "antd/lib/table";
import styles from "./Table.module.scss";

interface IProps extends TableProps<object> {
  columns?: any[];
  exportable?: boolean;
  exportableDataTableName?: string;
  showColumnPicker?: boolean;
}

const Table = ({
  columns,
  pagination,
  exportable = false,
  exportableDataTableName,
  showColumnPicker,
  ...rest
}: IProps) => {
  return (
    <AntDTable
      columns={columns}
      className={styles.table}
      pagination={
        !!pagination && {
          ...pagination,
          position: ["bottomCenter"],
        }
      }
      rowKey={"id"}
      scroll={{ x: true }}
      exportable={!!exportable}
      exportableProps={
        exportable
          ? {
              showColumnPicker: true,
              fileName: `${exportableDataTableName}_${new Date().toISOString()}`,
            }
          : undefined
      }
      {...rest}
    />
  );
};

export default memo(Table);
