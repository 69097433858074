/** Api url for endpoint URL */
const API_URL = "/admin";
/** Version parameter for endpoint URL */
const API_VER_URL = API_URL;

/** Private endpoint URLs */
const USERS_URL = "/users";
// const DASHBOARD_URL = "/dashboard";
const INFLUENCERS_URL = "/influencers";
const INFLUENCER_URL = "/influencer";
const APP_USERS_URL = "/app-users";
const APP_USER_URL = "/app-user";
const CURRENCIES_URL = "/currencies";
const SETS_URL = "/sets-base-templates";
const PLATFORM_URL = "/platform";
const REVENUE_URL = "/revenue/report";
const ADMINS_URL = "/admin-users";
const WITHDRAWS_URL = "/withdraw-requests";
const CUSTOM_CARDS_URL = "/custom-cards";
const CUSTOM_CARDS_REVIEW_URL = "/card-reviews";
const WITHDRAW_URL = "/withdraw-request";
export const MARKET_PAIRS_URL = "/market-pairs";
export const DROPS_URL = "/drops";
export const ALLOCATION_URL = "/allocation";
const BANNERS_URL = "/banners";
/**
 * Enum with all api endpoints
 * @readonly
 * @enum {string}
 */
export const Endpoints = Object.freeze({
  LOGIN: API_VER_URL + USERS_URL + "/login",
  // LOGOUT: API_VER_URL + DASHBOARD_URL + USERS_URL + "/logout",
  // RESET_PASSWORD: API_VER_URL + DASHBOARD_URL + USERS_URL + "/password/forgot",
  // SET_NEW_PASSWORD: API_VER_URL + DASHBOARD_URL + USERS_URL + "/password/reset",
  // REFRESH_TOKEN: API_VER_URL + DASHBOARD_URL + USERS_URL + "/me/token",
  USERS_ME: API_VER_URL + USERS_URL + "/me",
  INFLUENCERS: API_VER_URL + INFLUENCERS_URL,
  INFLUENCER: API_VER_URL + INFLUENCER_URL,
  APP_USERS: API_VER_URL + APP_USERS_URL,
  APP_USER: API_VER_URL + APP_USER_URL,
  CURRENCIES: API_VER_URL + CURRENCIES_URL,
  MARKET_PAIRS: API_VER_URL + MARKET_PAIRS_URL,
  DROPS: API_VER_URL + DROPS_URL,
  SETS: API_VER_URL + SETS_URL,
  PLATFORM: API_VER_URL + PLATFORM_URL,
  REVENUE: API_VER_URL + REVENUE_URL,
  ADMINS: API_VER_URL + ADMINS_URL,
  WITHDRAWS: API_VER_URL + APP_USER_URL + WITHDRAWS_URL,
  WITHDRAW: API_VER_URL + APP_USER_URL + WITHDRAW_URL,
  CUSTOM_CARDS: API_VER_URL + CUSTOM_CARDS_URL,
  CUSTOM_CARDS_REVIEW: API_VER_URL + CUSTOM_CARDS_REVIEW_URL,
  BANNERS: API_VER_URL + BANNERS_URL,
});

/**
 * API response status codes enum
 * @readonly
 * @enum {number}
 */
export const ResponseStatus = Object.freeze({
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
});

/**
 * Default amount of record per page from API
 *
 */
export const DEFAULT_RESULTS_PER_PAGE = 20;

export const DEFAULT_API_PARAMS = {
  limit: DEFAULT_RESULTS_PER_PAGE,
  page: 1,
};
