import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Input, message } from "antd";
import { createBanner, updateBanner } from "features/banners/api/bannersApi";

import styles from "./BannerPopup.module.scss";

const BannerPopup = ({ banner, successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: banner?.name || "",
      path: banner?.path || "",
    });
  }, [banner, form]);

  const showPopup = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const res = banner
        ? await updateBanner(banner.id, values)
        : await createBanner(values);
      if (res.status === 200) {
        successCallback();
      }
      form.resetFields();
      setVisible(false);
    } catch (error: any) {
      if (error instanceof Error) {
        console.error("Error:", error.message);
        message.error(error.message);
      } else if (error.errors) {
        console.error("Validation Failed:", error);
        message.error("Validation Failed");
      } else {
        console.error("Unknown error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button size="middle" onClick={showPopup} className={styles.createBtn}>
        {banner ? "Update" : "Create"} Banner Container
      </Button>
      <Modal
        title={`Banner ${banner ? "Update" : "Creation"}`}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" name="banner_creation_form">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input the banner name!",
              },
              {
                type: "string",
                min: 3,
                message: "The name must be at least 3 characters long!",
              },
              {
                pattern: /^[a-zA-Z0-9\s]*$/,
                message:
                  "The name can only contain letters, numbers, and spaces!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="path"
            label="Path"
            rules={[
              {
                required: true,
                message:
                  "Please input the banner relative path! (e.g. /amysoul)",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              size="middle"
              htmlType="submit"
              onClick={handleOk}
              loading={loading}
            >
              {banner ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BannerPopup;
