import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";

import Loading from "ui/Loading/Loading";

import { useParams } from "react-router-dom";
import styles from "./AppUsersDetails.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchUsersTransfers } from "store/usersSlice";

const Transfers = () => {
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();

  const appUserTransfer = useSelector(
    (state: RootState) => state.users.detail.transfers.entities
  );
  const appUserTransferLoading = useSelector(
    (state: RootState) => state.users.detail.transfers.loading
  );

  const geAppUserOrderAPI = useCallback(() => {
    if (userId) {
      dispatch(fetchUsersTransfers(userId));
    }
  }, [userId]);

  useEffect(() => {
    geAppUserOrderAPI();
  }, [geAppUserOrderAPI]);

  const columns: ColumnsType = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
      width: "5%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text}</span>,
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "appUserPaymentMethod",
      key: "appUserPaymentMethod",
      render: (text) => (
        <>
          <Popover
            style={{ width: 500 }}
            content={text?.name}
            title="Name"
            trigger="hover"
          >
            {text?.name}
          </Popover>
        </>
      ),
      width: "5%",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "type",
      dataIndex: "appUserPaymentMethod",
      key: "appUserPaymentMethod",
      render: (text) => <span>{text?.type}</span>,
      width: "5%",
    },
    {
      title: "TrackingId",
      dataIndex: "trackingId",
      key: "trackingId",
      render: (text) => (
        <>
          <Popover
            style={{ width: 500 }}
            content={text}
            title="TrackingId"
            trigger="hover"
          >
            {text}
          </Popover>
        </>
      ),
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <div className={styles.dropStatusHeadder}>
      {!appUserTransferLoading && appUserTransfer && (
        <>
          <Card>
            <Table
              loading={appUserTransferLoading}
              columns={columns}
              dataSource={appUserTransfer}
            />
          </Card>
        </>
      )}
      {appUserTransferLoading && (
        <>
          <Loading isFullscreen={false} />
        </>
      )}
    </div>
  );
};

export default memo(Transfers);
