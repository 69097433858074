import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";

import Loading from "ui/Loading/Loading";

import { useParams } from "react-router-dom";
import styles from "./AppUsersDetails.module.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchUsersOrders } from "store/usersSlice";

const Orders = () => {
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();

  const appUserOrder = useSelector(
    (state: RootState) => state.users.detail.orders.entities
  );
  const appOrdersLoading = useSelector(
    (state: RootState) => state.users.detail.orders.loading
  );

  const geAppUserOrderAPI = useCallback(() => {
    if (userId) {
      dispatch(fetchUsersOrders(userId));
    }
  }, [userId]);

  useEffect(() => {
    geAppUserOrderAPI();
  }, [geAppUserOrderAPI]);

  const columns: ColumnsType = [
    {
      title: "Id",
      dataIndex: "id",
      key: "naidme",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "CardId",
      dataIndex: "card",
      key: "card",
      render: (card) => <span>{card.id}</span>,
    },
    {
      title: "TokenId",
      dataIndex: "tokenId",
      key: "tokenId",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "totalValue",
      dataIndex: "totalValue",
      key: "totalValue",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (text) => (
        <span>{moment(text).format("DD MMM YYYY : HH:mm:SS")}</span>
      ),
    },
  ];

  return (
    <div className={styles.dropStatusHeadder}>
      {!appOrdersLoading && appUserOrder && (
        <>
          <Card>
            <Table
              loading={appOrdersLoading}
              columns={columns}
              dataSource={appUserOrder}
            />
          </Card>
        </>
      )}
      {appOrdersLoading && (
        <>
          <Loading isFullscreen={false} />
        </>
      )}
    </div>
  );
};

export default memo(Orders);
