import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import * as userAPI from "features/appUsers/api/appUsersApi";
import { AppUserCards } from "features/appUsers/types/AppUserCards";
import { AppUserOrder } from "features/appUsers/types/AppUserOrder";
import { AppUserProfile } from "features/appUsers/types/AppUserProfile";
import { AppUserNftTransaction } from "features/appUsers/types/AppUserTransaction";
import { AppUserType } from "features/appUsers/types/AppUserType";
import { Payment } from "features/appUsers/types/Payment";
import { StatusHistory } from "features/appUsers/types/StatusHistory";
import { KycHistory } from "features/appUsers/types/KycHistory";
import { Transfer } from "features/appUsers/types/Transfers";

type TEntity<T> = {
  entities: T | null;
  loading: boolean;
};

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (params?: object) => {
    const response = await userAPI.getAppUsers(params);
    return response;
  }
);

export const fetchUsersProfile = createAsyncThunk(
  "users/fetchUsersProfile",
  async (appUserId?: string | number) => {
    const response = await userAPI.getAppUserProfile(appUserId);
    return response;
  }
);

export const fetchUsersNftTransactions = createAsyncThunk(
  "users/fetchUsersNftTransactions",
  async (appUserId?: string | number) => {
    const response = await userAPI.getNftTransactionsPerAppUser(appUserId);
    return response;
  }
);
export const fetchUsersCards = createAsyncThunk(
  "users/fetchUsersCards",
  async (appUserId?: string | number) => {
    const response = await userAPI.getCardsPerAppUser(appUserId);
    return response;
  }
);

export const fetchUsersPayments = createAsyncThunk(
  "users/fetchUsersPayments",
  async (appUserId?: string | number) => {
    const response = await userAPI.getPaymentsMethodsAppUser(appUserId);
    return response;
  }
);

export const fetchUsersTransfers = createAsyncThunk(
  "users/fetchUsersTransfers",
  async (appUserId?: string | number) => {
    const response = await userAPI.getAppUserTransfers(appUserId);
    return response;
  }
);
export const fetchUsersOrders = createAsyncThunk(
  "users/fetchUsersOrders",
  async (appUserId?: string | number) => {
    const response = await userAPI.getOrdersPerAppUser(appUserId);
    return response;
  }
);

export const updateRoleToUser = createAsyncThunk(
  "users/updateRoleToUser",
  async ({ appUserId, role }: { appUserId: string | number; role: string }) => {
    const response = await userAPI.updateRoleToUser(appUserId, role);
    return response;
  }
);

export const updateStatusToUser = createAsyncThunk(
  "users/updateStatusToUser",
  async ({
    appUserId,
    status,
    blockReason,
  }: {
    appUserId: string | number;
    status: string;
    blockReason: string;
  }) => {
    const response = await userAPI.updateStatusToUser(
      appUserId,
      status,
      blockReason
    );
    return response;
  }
);
export const fetchUsersHistoryStatus = createAsyncThunk(
  "users/fetchUsersHistoryStatus",
  async (appUserId?: string | number) => {
    const response = await userAPI.getUserStatusHistory(appUserId);
    return response;
  }
);
export const fetchUsersKycHistory = createAsyncThunk(
  "users/fetchUsersKycHistory",
  async (appUserId?: string | number) => {
    const response = await userAPI.getUserKycHistory(appUserId);
    return response;
  }
);
export const updateKYCStatus = createAsyncThunk(
  "users/updateKYCStatus",
  async ({
    appUserId,
    newStatus,
    comment,
    updatedBy,
  }: {
    appUserId: string | number;
    newStatus: string;
    comment: string;
    updatedBy: string;
  }) => {
    const response = await userAPI.updateKYCStatus(
      appUserId,
      newStatus,
      comment,
      updatedBy
    );
    return response;
  }
);
interface UsersState {
  entities: {
    rows: AppUserType[];
    count: number;
  };
  detail: {
    entities: AppUserProfile | null;
    loading: boolean;
    nftTransactions: TEntity<AppUserNftTransaction[]>;
    cards: TEntity<AppUserCards[]>;
    payments: TEntity<Payment[]>;
    transfers: TEntity<Transfer[]>;
    orders: TEntity<AppUserOrder[]>;
    statusHistories: TEntity<StatusHistory[]>;
    kycHistory: TEntity<KycHistory[]>;
  };
}

const initialState: UsersState = {
  entities: {
    rows: [],
    count: 0,
  },
  detail: {
    entities: null,
    loading: false,
    nftTransactions: {
      entities: null,
      loading: false,
    },
    cards: {
      entities: null,
      loading: false,
    },
    payments: {
      entities: null,
      loading: false,
    },
    transfers: {
      entities: null,
      loading: false,
    },
    orders: {
      entities: null,
      loading: false,
    },
    statusHistories: {
      entities: null,
      loading: false,
    },
    kycHistory: {
      entities: null,
      loading: false,
    },
  },
};

// Then, handle actions in your reducers:
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearDetail(state) {
      state.detail = initialState.detail;
    },
    updateRole(state, action) {
      state = {
        detail: {
          ...state.detail,
          entities: {
            ...state.detail.entities,
            appUser: {
              ...state.detail.entities?.appUser,
              role: action.payload,
            },
          },
        },
      } as UsersState;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.entities = action.payload;
      })
      .addCase(fetchUsersProfile.pending, (state, action) => {
        state.detail.loading = true;
      })
      .addCase(fetchUsersProfile.rejected, (state, action) => {
        state.detail.loading = false;
      })
      .addCase(fetchUsersProfile.fulfilled, (state, action) => {
        state.detail.entities = action.payload;
        state.detail.loading = false;
      })
      .addCase(fetchUsersNftTransactions.pending, (state, action) => {
        state.detail.nftTransactions.loading = true;
      })
      .addCase(fetchUsersNftTransactions.rejected, (state, action) => {
        state.detail.nftTransactions.loading = false;
      })
      .addCase(fetchUsersNftTransactions.fulfilled, (state, action) => {
        state.detail.nftTransactions.entities = action.payload;
        state.detail.nftTransactions.loading = false;
      })
      .addCase(fetchUsersCards.pending, (state, action) => {
        state.detail.cards.loading = true;
      })
      .addCase(fetchUsersCards.rejected, (state, action) => {
        state.detail.cards.loading = false;
      })
      .addCase(fetchUsersCards.fulfilled, (state, action) => {
        state.detail.cards.entities = action.payload;
        state.detail.cards.loading = false;
      })
      .addCase(fetchUsersPayments.pending, (state, action) => {
        state.detail.payments.loading = true;
      })
      .addCase(fetchUsersPayments.rejected, (state, action) => {
        state.detail.payments.loading = false;
      })
      .addCase(fetchUsersPayments.fulfilled, (state, action) => {
        state.detail.payments.entities = action.payload;
        state.detail.payments.loading = false;
      })
      .addCase(fetchUsersTransfers.pending, (state, action) => {
        state.detail.transfers.loading = true;
      })
      .addCase(fetchUsersTransfers.rejected, (state, action) => {
        state.detail.transfers.loading = false;
      })
      .addCase(fetchUsersTransfers.fulfilled, (state, action) => {
        state.detail.transfers.entities = action.payload;
        state.detail.transfers.loading = false;
      })
      .addCase(fetchUsersOrders.pending, (state, action) => {
        state.detail.orders.loading = true;
      })
      .addCase(fetchUsersOrders.rejected, (state, action) => {
        state.detail.orders.loading = false;
      })
      .addCase(fetchUsersOrders.fulfilled, (state, action) => {
        state.detail.orders.entities = action.payload;
        state.detail.orders.loading = false;
      })
      .addCase(fetchUsersHistoryStatus.pending, (state, action) => {
        state.detail.statusHistories.loading = true;
      })
      .addCase(fetchUsersHistoryStatus.rejected, (state, action) => {
        state.detail.statusHistories.loading = false;
      })
      .addCase(fetchUsersHistoryStatus.fulfilled, (state, action) => {
        state.detail.statusHistories.entities = action.payload;
        state.detail.statusHistories.loading = false;
      })
      .addCase(fetchUsersKycHistory.pending, (state, action) => {
        state.detail.kycHistory.loading = true;
      })
      .addCase(fetchUsersKycHistory.rejected, (state, action) => {
        state.detail.kycHistory.loading = false;
      })
      .addCase(fetchUsersKycHistory.fulfilled, (state, action) => {
        state.detail.kycHistory.entities = action.payload;
        state.detail.kycHistory.loading = false;
      });
  },
});

export const { clearDetail, updateRole } = usersSlice.actions;
export default usersSlice.reducer;
