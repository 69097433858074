import { message } from "antd";

export const errorResponseMessages = (e: any) => {
  if (e?.response?.data?.validationErrors) {
    const errors = e.response?.data?.validationErrors;
    const values = Object.values(errors);

    values.forEach((item) => {
      Array.isArray(item) &&
        item.forEach((element) => {
          message.error(element);
        });
    });
  } else {
    const error = e.message;
    message.error(error);
  }
};
