import React, { useState } from "react";
import { Button, Modal, Form, Input, Select, message } from "antd";
import { createAdmin } from "features/admins/api/adminsApi";

const { Option } = Select;

const AdminCreationPopup = ({ successCallback }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const showPopup = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const res = await createAdmin(values);
      if (res.status === 204) {
        successCallback();
      }
      form.resetFields();
      setVisible(false);
    } catch (error: any) {
      if (error instanceof Error) {
        console.error("Error:", error.message);
        message.error(error.message);
      } else if (error.errors) {
        console.error("Validation Failed:", error);
        message.error("Validation Failed");
      } else {
        console.error("Unknown error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button size="middle" onClick={showPopup}>
        Create Admin
      </Button>
      <Modal
        title="Admin Creation"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" name="admin_creation_form">
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input the admin email!",
              },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input the admin password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="level"
            label="Level"
            rules={[
              {
                required: true,
                message: "Please select the admin level!",
              },
            ]}
          >
            <Select placeholder="Select a level">
              <Option value="admin">Admin</Option>
              <Option value="superAdmin">Super Admin</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              size="middle"
              htmlType="submit"
              onClick={handleOk}
              loading={loading}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminCreationPopup;
