import React, { memo } from "react";
import { Card, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";

import styles from "./InfluencerDetail.module.scss";

const Cards = ({ cards }) => {
  const columns: ColumnsType = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      align: "center",
      width: 200,
      render: (text) => (
        <span>
          <Image width={50} src={text} />
        </span>
      ),
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      width: 50,
      align: "center",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "Drop date",
      dataIndex: "dropPhase2StartDate",
      key: "dropDate",
      render: (text) => <span>{text || "-"}</span>,
    },
    {
      title: "LatestPrice",
      dataIndex: "latestPrice",
      key: "latestPrice",
      width: 200,
      align: "center",
      render: (text) => <span>{text || "-"}</span>,
    },
  ];

  return (
    <div className={styles.dropStatusHeadder}>
      <>
        <Card>
          <Table columns={columns} dataSource={cards} rowKey="series" />
        </Card>
      </>
    </div>
  );
};

export default memo(Cards);
