import { memo, useEffect, useState, useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  NavLink,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";

import { Typography, Spin, Col, Row, Button, message } from "antd";
import { useHistory } from "react-router-dom";

import styles from "./InfluencerDetail.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";

import Details from "./Details";
import {
  fetchInfluencersById,
  fetchInfluencersByIdDetails,
} from "store/influencersSlice";
import Cards from "./Cards";
import UploaderCardImage from "ui/UploaderCardImage/uploaderCardImage";
import { InfluencerPresignedType } from "features/influencers/types/InfluencerPresignedType";
import { setPresignedUrlInfluencerImage } from "features/influencers/api/influencersApi";
import { goBack } from "helpers/navigationHelper";

const { Title, Text } = Typography;

const InfluencerDetail = () => {
  let match = useRouteMatch();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { influencerId } = useParams<{ influencerId: string }>();

  const detail = useSelector(
    (state: RootState) => state.influencers.detail.entities
  );
  const influencer = useSelector(
    (state: RootState) => state.influencers.detail.profile
  );
  const [loading, setLoading] = useState(true);

  const [influencerPresignedUrl, setInfluencerPresignedUrl] =
    useState<InfluencerPresignedType>();

  const getInfluencerPresignedUrl = useCallback(async () => {
    try {
      if (influencer) {
        const res = await setPresignedUrlInfluencerImage(influencer.id);
        setInfluencerPresignedUrl(res);
      }
    } catch (e) {
      message.error("Couldn't fetch the data from influencer PresignedUrl");
    }
  }, [influencer]);

  useEffect(() => {
    getInfluencerPresignedUrl();
  }, [getInfluencerPresignedUrl]);
  const handleInfluencerLoading = () => {
    dispatch(fetchInfluencersById(influencerId));
    return dispatch(fetchInfluencersByIdDetails(influencerId));
  };

  useEffect(() => {
    setLoading(true);
    handleInfluencerLoading().then(() => {
      setLoading(false);
    });
  }, [influencerId]);

  if (loading) {
    return (
      <div className={`${styles.detailContainer} ${styles.spinCenter}`}>
        <Spin />
      </div>
    );
  }

  const handleGoBack = () => {
    goBack(history, "/influencers", "influencer-previous-params");
  };

  return (
    <div className={styles.detailContainer}>
      <Button className={styles.backBtn} onClick={handleGoBack}>
        <ArrowLeftOutlined />
        <Text>All influencers</Text>
      </Button>
      <div className={styles.profileWrapper}>
        <img src={influencer?.profileImageUrl} />
        <Title level={2}>{influencer?.name}</Title>
      </div>
      <Row>
        <Col span={24} className={styles.linkWrapper}>
          <NavLink
            to={`${match.url}/details`}
            className={(isActive) => (isActive ? styles.active : styles.link)}
          >
            Details
          </NavLink>
          <NavLink
            to={`${match.url}/cards`}
            className={(isActive) => (isActive ? styles.active : styles.link)}
          >
            Cards
          </NavLink>
        </Col>
      </Row>
      <Switch>
        <Route path={`${match.path}/details`}>
          <Details
            influencer={influencer}
            influencerStats={detail?.influencerStats}
            callback={handleInfluencerLoading}
          />
        </Route>
        <Route path={`${match.path}/cards`}>
          <Cards cards={detail?.cards} />
          <UploaderCardImage
            uploadData={influencerPresignedUrl}
            doneCallback={handleInfluencerLoading}
            dimensions={{ width: 1000, height: 1400 }}
          />
        </Route>
        <Redirect from="/" to={`${match.url}/details`} />
      </Switch>
    </div>
  );
};

export default memo(InfluencerDetail);
