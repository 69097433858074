import { memo, useEffect, useState, useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  NavLink,
  useHistory,
} from "react-router-dom";
import { Typography, Spin, Col, Row, Button, Modal } from "antd";
import styles from "./BannerDetails.module.scss";
import Details from "../components/BannerDetails/Details";
import BannerContentDetails from "../components/BannerDetails/BannerContentDetails";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { goBack } from "helpers/navigationHelper";
import {
  getBanner,
  deleteBanner,
  deleteBannerContent,
} from "features/banners/api/bannersApi";
import { BannerData } from "features/banners/types/BannersData";
import { errorResponseMessages } from "api/apiErrorHelper";

const { Title, Text } = Typography;

const BannerDetails = () => {
  let match = useRouteMatch();
  const history = useHistory();
  const { bannerId } = useParams<{ bannerId: string }>();

  const [banner, setBanner] = useState<BannerData | null>(null);
  const [loading, setLoading] = useState(false);

  const getBannerApi = useCallback(() => {
    setLoading(true);
    getBanner(bannerId)
      .then((res) => {
        setBanner(res);
      })
      .catch((err) => {
        errorResponseMessages(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleBannerDelete = useCallback(() => {
    const content = `Are you sure you want to delete this banner and related content items?`;
    Modal.confirm({
      title: "Confirm Deletion",
      content,
      onOk: () => {
        setLoading(true);
        deleteBanner(bannerId)
          .then(() => {
            handleGoBack();
          })
          .catch((err) => {
            errorResponseMessages(err);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      centered: true,
    });
  }, []);

  const handleBannerContentDelete = useCallback((bannerId, contentId) => {
    const content = `Are you sure you want to delete this banner content item?`;
    Modal.confirm({
      title: "Confirm Deletion",
      content,
      onOk: () => {
        setLoading(true);
        deleteBannerContent(bannerId, contentId)
          .then(() => {
            getBannerApi();
          })
          .catch((err) => {
            errorResponseMessages(err);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      centered: true,
    });
  }, []);

  useEffect(() => {
    getBannerApi();
  }, [bannerId]);

  if (loading) {
    return (
      <div className={`${styles.detailContainer} ${styles.spinCenter}`}>
        <Spin />
      </div>
    );
  }

  const handleGoBack = () => {
    goBack(history, "/banners", null);
  };

  return (
    <div className={styles.detailContainer}>
      <Button className={styles.backBtn} onClick={handleGoBack}>
        <ArrowLeftOutlined />
        <Text>All banners</Text>
      </Button>
      {banner ? (
        <>
          <Title level={2}>{banner?.name}</Title>
          <Row>
            <Col span={24} className={styles.linkWrapper}>
              <NavLink
                to={`${match.url}/details`}
                activeClassName={styles.active}
                className={styles.link}
              >
                Details
              </NavLink>
              <NavLink
                to={`${match.url}/desktop`}
                activeClassName={styles.active}
                className={styles.link}
              >
                Desktop
              </NavLink>
              <NavLink
                to={`${match.url}/mobile`}
                activeClassName={styles.active}
                className={styles.link}
              >
                Mobile
              </NavLink>
            </Col>
          </Row>
          <Switch>
            <Route path={`${match.path}/details`}>
              <Details
                banner={banner}
                updateCallback={getBannerApi}
                deleteCallback={handleBannerDelete}
              />
            </Route>
            <Route path={`${match.path}/desktop`}>
              <BannerContentDetails
                banner={banner}
                type="desktop"
                updateCallback={getBannerApi}
                deleteCallback={handleBannerContentDelete}
              />
            </Route>
            <Route path={`${match.path}/mobile`}>
              <BannerContentDetails
                banner={banner}
                type="mobile"
                updateCallback={getBannerApi}
                deleteCallback={handleBannerContentDelete}
              />
            </Route>
            <Redirect from="/" to={`${match.url}/details`} />
          </Switch>
        </>
      ) : (
        <Title level={3}>Banner Not Found</Title>
      )}
    </div>
  );
};

export default memo(BannerDetails);
