import { Col, List, Row, Upload, message } from "antd";
import { RcFile, UploadProps } from "antd/es/upload/interface";
import { useState, useCallback } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./uploaderCardImage.module.scss";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  if (!(img instanceof Blob)) {
    throw new Error("The provided image is not a Blob instance");
  }

  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

type UploaderCardImageProps = {
  uploadData: any;
  doneCallback: () => void | any;
  contentType?: string;
  dimensions?: { width: number; height: number };
};

const UploaderCardImage = ({
  uploadData,
  doneCallback,
  contentType = "image/jpeg",
  dimensions,
}: UploaderCardImageProps) => {
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file: RcFile): Promise<boolean> => {
    const isJpg = file.type === contentType;
    if (!isJpg) {
      message.error(`You can only upload ${contentType} file!`);
      return Promise.resolve(false);
    }

    if (dimensions) {
      return new Promise((resolve, reject) => {
        const tempImg = new Image();
        tempImg.onload = () => {
          if (
            tempImg.width !== dimensions.width ||
            tempImg.height !== dimensions.height
          ) {
            message.error(
              `Image size is not ${dimensions.width}x${dimensions.height}px`
            );
            resolve(false);
          } else {
            resolve(true);
          }
        };
        tempImg.onerror = () => {
          message.error("Error loading image file");
          resolve(false);
        };
        tempImg.src = URL.createObjectURL(file);
      });
    }

    return Promise.resolve(true);
  };

  const uploadToS3 = useCallback(
    async (file: RcFile) => {
      setLoading(true);
      const headers = new Headers();
      headers.append("Content-Type", contentType);

      try {
        const response = await fetch(uploadData?.presignedUploadUrl || "", {
          method: "PUT",
          body: file,
          headers,
        });

        if (response.ok) {
          message.success("Upload Successful");
          doneCallback();
        } else {
          message.error("Upload Failed");
        }
      } catch (error) {
        console.error("Error during file upload:", error);
        if (error instanceof Error) {
          message.error("Upload Failed: " + error.message);
        } else {
          message.error("Upload failed due to an unknown error");
        }
      } finally {
        setLoading(false);
      }
    },
    [uploadData, doneCallback]
  );

  const handleChange: UploadProps["onChange"] = ({ file }) => {
    if (file.status === "done") {
      getBase64(file.originFileObj as RcFile, (url) => {
        uploadToS3(file.originFileObj as RcFile);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <>
      <List
        size="default"
        itemLayout="vertical"
        className={styles.cardUploader}
      >
        <Row>
          <Col span={24}></Col>
        </Row>
        <Row>
          <Col span={24}>
            <Upload
              name={uploadData?.imageName}
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={({ file }) => {
                getBase64(file as RcFile, (url) => {
                  uploadToS3(file as RcFile);
                });

                return Promise.resolve();
              }}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {uploadButton}
            </Upload>
          </Col>
        </Row>
        <Row>
          <Col span={24}></Col>
        </Row>
      </List>
    </>
  );
};

export default UploaderCardImage;
