import React, { useState } from "react";
import { Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Form from "ui/Forms/Form";
import FormItem from "ui/Forms/FormItem";
import { Input } from "ui/Inputs/Inputs";
import { useHistory } from "react-router-dom";
import { PUBLIC_ROUTES } from "router/Router.config";
import UserPool from "../UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";

const ForgotPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const onFinish = (formData) => {
    setLoading(true);

    const user = new CognitoUser({
      Username: formData.email,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        console.log(1, data);

        message.info(
          "Check your mailbox for an email with verification code and reset password."
        );
        history.push(PUBLIC_ROUTES.SET_NEW_PASSWORD_CODE_SCREEN.path);
      },
      onFailure: function (err) {
        console.log(2, err);
        message.error(err.message);
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
    message.error("Something went wrong!");
  };

  return (
    <Form
      name="loginForms"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      layout={"vertical"}
      onFinishFailed={onFinishFailed}
    >
      <FormItem
        label={"Enter your email to request password reset"}
        name={"email"}
        key={"email"}
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input your e-mail!",
          },
        ]}
      >
        <Input
          type={"email"}
          prefix={<UserOutlined />}
          placeholder={"E-mail"}
          size={"large"}
        />
      </FormItem>
      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
};

export default ForgotPassword;
