import React, { PropsWithChildren } from "react";
import { Form } from "antd";
import { FormItemProps } from "antd/lib/form";
// import { useForm } from "antd/lib/form/util";

interface IProps extends FormItemProps {
  /** Form fields */
  children: any;
}

const FormItem = ({ children, ...rest }: PropsWithChildren<IProps>) => {
  // const [form] = useForm();
  return <Form.Item {...rest}>{children}</Form.Item>;
};

export default FormItem;
