import { memo, useEffect, useState } from "react";
import { errorResponseMessages } from "api/apiErrorHelper";
import { NO_VALUE } from "ui/uiConsts";
import { BannersData } from "features/banners/types/BannersData";
import { getPresignedUrlBannerImage } from "features/banners/api/bannersApi";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import BannerContentPopup from "features/banners/screens/components/BannerContentPopup/BannerContentPopup";
import {
  ZoomInOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Image, Tooltip, Button } from "antd";
import UploaderCardImage from "ui/UploaderCardImage/uploaderCardImage";
import { updateBanner } from "features/banners/api/bannersApi";

const formatText = (text) => <span>{text || NO_VALUE}</span>;

const formatImage = (url) => {
  if (!url) {
    return null;
  }

  const cacheBustingUrl = `${url}?${new Date().getTime()}`;

  return (
    <Image
      preview={{
        mask: (
          <Tooltip title="Preview">
            <ZoomInOutlined />
          </Tooltip>
        ),
      }}
      width="auto"
      height={102}
      src={cacheBustingUrl}
      alt="Banner image"
    />
  );
};

const filterContentItems = (banner, type) => {
  if (!banner || !type) {
    return [];
  }

  return banner.contentItems.filter((item) => item.platform === type);
};

const BannerContentDetails = ({
  banner,
  type,
  updateCallback,
  deleteCallback,
}) => {
  const [contentItems, setContentItems] = useState<BannersData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [url, setUrl] = useState(null);

  const [bannerPresignedUrl, setBannerPresignedUrl] = useState<any>({});

  useEffect(() => {
    if (type === "mobile") {
      setUrl(banner?.srcMobile);
    } else if (type === "desktop") {
      setUrl(banner?.src);
    }
  }, [banner, type]);

  const getBannerPresignedUrl = async () => {
    try {
      if (banner) {
        const res = await getPresignedUrlBannerImage(banner.id, {
          platform: type,
        });
        setBannerPresignedUrl(res?.data);
      }
    } catch (e) {
      errorResponseMessages(
        "Couldn't fetch the data from influencer PresignedUrl"
      );
    }
  };

  useEffect(() => {
    getBannerPresignedUrl();
  }, [type]);

  useEffect(() => {
    setContentItems(filterContentItems(banner, type));
    setTableLoading(false);
  }, [banner, type]);

  const handleUpdate = (record) => {
    setSelectedRecord(record);
    setIsPopupVisible(true);
  };

  const handleDelete = (record) => {
    deleteCallback(banner.id, record.id);
  };

  const showPopup = () => {
    setSelectedRecord(null);
    setIsPopupVisible(true);
  };

  const handleBannerUpload = () => {
    let data = {};
    if (type === "mobile") {
      data = { srcMobile: bannerPresignedUrl?.imageUrl };
    } else if (type === "desktop") {
      data = { src: bannerPresignedUrl?.imageUrl };
    }
    updateBanner(banner.id, data)
      .then(() => {
        updateCallback();
      })
      .catch((error) => {
        errorResponseMessages(`Error updating the banner: ${error?.message}`);
        console.error("Error updating the banner: ", error);
      });
  };

  const columns: ColumnsType = [
    {
      title: "Language code",
      dataIndex: "languageCode",
      key: "languageCode",
      width: 50,
      render: formatText,
    },
    {
      title: "Headline",
      dataIndex: "headline",
      key: "headline",
      width: 150,
      render: formatText,
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
      width: 300,
      render: formatText,
    },
    {
      title: "CTA",
      dataIndex: "cta",
      key: "cta",
      width: 100,
      render: formatText,
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      width: 100,
      render: (_, record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleUpdate(record)}
            style={{ marginRight: 8 }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record)}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {formatImage(url)}
        <UploaderCardImage
          uploadData={bannerPresignedUrl}
          doneCallback={handleBannerUpload}
          contentType="image/png"
        />
      </div>
      <Table
        loading={tableLoading}
        columns={columns}
        dataSource={contentItems}
        exportableDataTableName={`Banner ${type} content`}
        showColumnPicker={true}
      />
      <Button
        size="middle"
        onClick={showPopup}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        Create Banner Content
      </Button>
      <BannerContentPopup
        content={selectedRecord}
        banner={banner}
        type={type}
        visible={isPopupVisible}
        setVisible={setIsPopupVisible}
        successCallback={updateCallback}
      />
    </>
  );
};

export default memo(BannerContentDetails);
