import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";

export const getAppUsers = async (params?: object) => {
  const response = await api.get(Endpoints.APP_USERS, {
    params: { ...params },
  });
  return response.data;
};

export const getCardsPerAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/cards`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getOrdersPerAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/orders`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getNftTransactionsPerAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/nft-transactions`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getWithdrawRequestPerAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/withdraw-requests`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getPaymentsMethodsAppUser = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/payment-methods`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getAppUserTransfers = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/money-transfers`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getAppUserProfile = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/profile`;
  const response = await api.get(endpoint);
  return response.data;
};

export const updateRoleToUser = async (appUserId, role) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/role`;
  const response = await api.put(endpoint, { role });
  return response.data;
};

export const updateStatusToUser = async (appUserId, status, blockReason) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/status`;
  const response = await api.put(endpoint, {
    status,
    blockReason,
  });
  return response.data;
};

export const updateKYCStatus = async (
  appUserId,
  newStatus,
  comment,
  updatedBy
) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/kyc-result`;
  const response = await api.put(endpoint, {
    newStatus,
    comment,
    updatedBy,
  });
  return response.data;
};

export const getUserStatusHistory = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/status-history`;
  const response = await api.get(endpoint);
  return response.data;
};

export const getUserKycHistory = async (appUserId) => {
  const endpoint = `${Endpoints.APP_USER}/${appUserId}/kyc-history`;
  const response = await api.get(endpoint);
  return response.data;
};
