import React, { memo, PropsWithChildren } from "react";
import { Typography } from "antd";
// import cx from "classnames";
// import styles from "./Text.module.scss";

import { TextProps } from "antd/lib/typography/Text";

interface IProps extends TextProps {}

const Text = ({ children, ...rest }: PropsWithChildren<IProps>) => {
  // const classNames = cx({
  //   [styles.small]: size === ModalSize.SMALL,
  //   [styles.medium]: size === ModalSize.MEDIUM,
  //   [styles.large]: size === ModalSize.LARGE,
  // });
  const { Text: AntDText } = Typography;
  return (
    <div>
      <AntDText {...rest}>{children}</AntDText>
    </div>
  );
};

export default memo(Text);
