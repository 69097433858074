import React, { memo, useCallback, useEffect, useState } from "react";
import { message, List, Image, Popover, Card } from "antd";

import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import { AppUserType } from "features/appUsers/types/AppUserType";

import Loading from "ui/Loading/Loading";
import styles from "./AppUsersDetails.module.scss";

import { AppDispatch, RootState } from "store/store";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersPayments } from "store/usersSlice";

const Payments = () => {
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();

  const appUserPaymentsMethods = useSelector(
    (state: RootState) => state.users.detail.payments.entities
  );
  const appUserPaymentsMethodsLoading = useSelector(
    (state: RootState) => state.users.detail.payments.loading
  );

  const getAppUserPaymentMethodApi = useCallback(() => {
    if (userId) {
      dispatch(fetchUsersPayments(userId));
    }
  }, [userId]);

  useEffect(() => {
    getAppUserPaymentMethodApi();
  }, [getAppUserPaymentMethodApi]);

  const columns: ColumnsType = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
      width: "2%",
    },
    {
      title: "Payment Method Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <>
          <Popover
            style={{ width: 500 }}
            content={text}
            title="Payment Method Name"
            trigger="hover"
          >
            {text}
          </Popover>
        </>
      ),
      width: "8%",
    },
    {
      title: "Is Default",
      dataIndex: "isDefault",
      key: "isDefault",
      render: (text) => <span>{text ? "Yes" : "No"}</span>,
      width: "3%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => (
        <>
          <Popover
            style={{ width: 500 }}
            content={text}
            title="Type"
            trigger="hover"
          >
            {text}
          </Popover>
        </>
      ),
      width: "10%",
    },
    {
      title: "Metod name",
      dataIndex: "methodInfo",
      key: "methodInfo",
      width: "5%",
      render: (text) => <>{text?.name}</>,
    },
    {
      title: "Amount",
      dataIndex: "appUserAccount",
      key: "appUserAccount",
      render: (text) => <span>{text?.amount}</span>,
      width: "5%",
    },
    {
      title: "Amount Locked",
      dataIndex: "appUserAccount",
      key: "appUserAccount",
      render: (text) => <span>{text?.amountLocked}</span>,
      width: "5%",
    },
  ];

  return (
    <div className={styles.dropStatusHeadder}>
      {!appUserPaymentsMethodsLoading && appUserPaymentsMethods && (
        <>
          <Card>
            <Table
              loading={appUserPaymentsMethodsLoading}
              columns={columns}
              dataSource={appUserPaymentsMethods}
            />
          </Card>
        </>
      )}
      {appUserPaymentsMethodsLoading && (
        <>
          <Loading isFullscreen={false} />
        </>
      )}
    </div>
  );
};

export default memo(Payments);
