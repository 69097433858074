import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";

export const createBanner = async (data?: object) => {
  const response = await api.post(Endpoints.BANNERS, data);
  return response;
};

export const getBanners = (params?: object) => {
  return api
    .get(Endpoints.BANNERS, {
      params: { ...params },
    })
    .then((response) => response.data);
};

export const getBanner = (bannerId) => {
  return api
    .get(`${Endpoints.BANNERS}/${bannerId}`)
    .then((response) => response.data);
};

export const updateBanner = (bannerId, data) => {
  return api
    .patch(`${Endpoints.BANNERS}/${bannerId}`, data)
    .then((response) => response);
};

export const deleteBanner = (bannerId) => {
  return api
    .delete(`${Endpoints.BANNERS}/${bannerId}`)
    .then((response) => response);
};

export const createBannerContent = async (bannerId, data?: object) => {
  const response = await api.post(
    `${Endpoints.BANNERS}/${bannerId}/content`,
    data
  );
  return response;
};

export const updateBannerContent = (bannerId, contentId, data) => {
  return api
    .patch(`${Endpoints.BANNERS}/${bannerId}/content/${contentId}`, data)
    .then((response) => response);
};

export const deleteBannerContent = (bannerId, contentId) => {
  return api
    .delete(`${Endpoints.BANNERS}/${bannerId}/content/${contentId}`)
    .then((response) => response);
};

export const getPresignedUrlBannerImage = (bannerId, data) => {
  return api
    .post(`${Endpoints.BANNERS}/${bannerId}/generate-upload-s3-url`, data)
    .then((response) => response);
};
