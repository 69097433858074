import React, { useState, useContext } from "react";
import { Button, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import Form from "ui/Forms/Form";
import FormItem from "ui/Forms/FormItem";
import {
  setAccessToken,
  setIdToken,
  setRefreshToken,
  handleUserLevel,
} from "features/identity/authHelper";
import { errorResponseMessages } from "api/apiErrorHelper";
import { UserContext } from "features/identity/userContext";
import { Input, InputPassword } from "ui/Inputs/Inputs";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "router/Router.config";
import { Link, useHistory } from "react-router-dom";
import styles from "./Layout.module.scss";
import UserPool from "../UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

  const onFinish = (formData: {
    email: string;
    password: string;
    new_password?: string;
  }) => {
    setLoading(true);
    const email = formData.email;
    const password = formData.password;
    const newPassword = formData.new_password;

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const setAdminUser = (data) => {
      console.log(data, user);
      setAccessToken(data.getAccessToken().getJwtToken());
      setIdToken(data.getIdToken().getJwtToken());
      setRefreshToken(data.getRefreshToken().getToken());
      setUser({
        name: user.getUsername(),
        authenticated: true,
        email,
      });
      message.info("Logged in successfully!");
      history.push(PRIVATE_ROUTES.APP_USERS_SCREEN.path);
    };

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("success", data);
        setLoading(false);
        handleUserLevel(user);
        setAdminUser(data);
      },
      onFailure: (error) => {
        console.error("error", error);
        setLoading(false);
        errorResponseMessages(error);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        if (newPassword) {
          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.email; // <--- add this line
          requiredAttributes.phone_number_verified =
            userAttributes.userAttributes;
          requiredAttributes.phone_number = userAttributes.phone_number;
          delete requiredAttributes.phone_number;
          delete requiredAttributes.phone_number_verified;

          user.completeNewPasswordChallenge(newPassword, requiredAttributes, {
            onSuccess: (data) => {
              console.log("success", data);
              setIsFirstLogin(false);
              setAdminUser(data);
            },
            onFailure: (error) => {
              console.error("error", error);
              message.error("Something went wrong. Please try again. ");
            },
          });
        } else {
          setIsFirstLogin(true);
          message.info("Please update your password.");
        }
        setLoading(false);
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo.errorFields);
    // message.error("Something went wrong!");
    // errorResponseMessages(errorInfo.errorFields);
  };

  return (
    <Form
      name="loginForms"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <FormItem
        name={"email"}
        key={"email"}
        rules={[
          {
            required: true,
            message: "Please input your e-mail!",
          },
        ]}
      >
        <Input
          type={"email"}
          prefix={<UserOutlined />}
          placeholder={"E-mail"}
          size={"large"}
        />
      </FormItem>

      <FormItem
        name={"password"}
        key={"password"}
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <InputPassword
          prefix={<LockOutlined />}
          placeholder={"Password"}
          size="large"
        />
      </FormItem>

      {isFirstLogin && (
        <FormItem
          name={"new_password"}
          key={"new_password"}
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
        >
          <InputPassword
            prefix={<LockOutlined />}
            placeholder={"New password"}
            size="large"
          />
        </FormItem>
      )}

      <div className={styles.links}>
        <Link to={PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path}>
          Forgot password?
        </Link>
        {/* <Link to={PUBLIC_ROUTES.SIGN_UP_SCREEN.path}>Sign up</Link> */}
      </div>

      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Login
        </Button>
      </FormItem>
    </Form>
  );
};

export default Login;
