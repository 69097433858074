import React, { useState } from "react";
import { Button, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import Form from "ui/Forms/Form";
import FormItem from "ui/Forms/FormItem";
import { InputPassword, Input } from "ui/Inputs/Inputs";

import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../UserPool";
const SetNewPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (formData) => {
    const Username = formData.email;
    const oldPassword = formData.old_password;
    const newPassword = formData.password;
    setLoading(true);
    const user = new CognitoUser({
      Username,
      Pool,
    });
    const authDetails = new AuthenticationDetails({
      Username,
      Password: oldPassword,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("success", data);
      },
      onFailure: (error) => {
        console.error("error", error);
      },
      newPasswordRequired: (data) => {
        delete data.email_verified;
        user.completeNewPasswordChallenge(newPassword, data, {
          onSuccess: (data) => {
            console.log("success", data);
          },
          onFailure: (error) => {
            console.error("error", error);
          },
        });
      },
    });
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
    message.error("Something went wrong!");
  };

  return (
    <Form
      name="loginForms"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <FormItem
        name={"email"}
        key={"email"}
        rules={[
          {
            required: true,
            type: "email",
            message: "Please input your e-mail!",
          },
        ]}
      >
        <Input
          type={"email"}
          prefix={<UserOutlined />}
          placeholder={"E-mail"}
          size={"large"}
        />
      </FormItem>

      <FormItem
        name={"old_password"}
        key={"old_password"}
        rules={[
          {
            required: true,
            message: "Please input your old password!",
          },
        ]}
      >
        <InputPassword
          prefix={<LockOutlined />}
          placeholder={"Old password"}
          size="large"
        />
      </FormItem>

      <FormItem
        name={"password"}
        key={"password"}
        rules={[
          {
            required: true,
            message: "Please input your new password!",
          },
        ]}
      >
        <InputPassword
          prefix={<LockOutlined />}
          placeholder={"Password"}
          size="large"
        />
      </FormItem>

      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
};

export default SetNewPassword;
