import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";

export const updateWithdraw = async (id: number, data: object) => {
  const endpoint = `${Endpoints.WITHDRAW}/${id}`;
  const response = await api.put(endpoint, data);
  return response;
};

export const getWithdraws = (params?: object) => {
  return api
    .get(Endpoints.WITHDRAWS, {
      params: { ...params },
    })
    .then((response) => response.data);
};
