import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import Button from "ui/Buttons/Button";
import Table from "ui/Table/Table";
import Drawer from "ui/Drawer/Drawer";
import { AppUserType } from "features/appUsers/types/AppUserType";
import Loading from "ui/Loading/Loading";
import { getNftTransactionsPerAppUser } from "features/appUsers/api/appUsersApi";
import { errorResponseMessages } from "api/apiErrorHelper";
import { useLocation, useParams } from "react-router-dom";
import { AppUserNftTransaction } from "features/appUsers/types/AppUserTransaction";

import styles from "./AppUsersDetails.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { fetchUsersNftTransactions } from "store/usersSlice";
import { AppDispatch, RootState } from "store/store";

interface IProps {
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const Transactions = (props: IProps) => {
  const [appUserTransactionLoading, setAppUserNftTransactionLoading] =
    useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();
  const appUserTransaction = useSelector(
    (state: RootState) => state.users.detail.nftTransactions.entities
  );

  const location = useLocation();

  const geAppUserOrderAPI = useCallback(() => {
    // if (appUser) {
    setAppUserNftTransactionLoading(true);
    dispatch(fetchUsersNftTransactions(userId))
      .catch((e) => {
        errorResponseMessages(e);
      })
      .finally(() => {
        setAppUserNftTransactionLoading(false);
      });
    // getNftTransactionsPerAppUser(1578)
    //   .then((res) => {
    //     console.log(res);
    //     setAppUserNftTransactions(res);
    //   })
    // }
  }, [userId]);

  useEffect(() => {
    geAppUserOrderAPI();
  }, []);

  const showDrawerKycTransactions = (url?: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const columns: ColumnsType = [
    {
      title: (
        <>
          <Popover style={{ width: 200 }} content={"Token Id"} trigger="hover">
            Token Id
          </Popover>
        </>
      ),
      dataIndex: "tokenId",
      key: "tokenId",
      render: (text) => <span>{text}</span>,
    },
    {
      title: (
        <>
          <Popover
            style={{ width: 200 }}
            content={"Serial Code"}
            trigger="hover"
          >
            Serial Code
          </Popover>
        </>
      ),
      dataIndex: "serialCode",
      key: "serialCode",
      render: (text) => (
        <>
          <Popover
            style={{ width: 200 }}
            content={text}
            title="Name"
            trigger="hover"
          >
            {text}
          </Popover>
        </>
      ),
    },
    {
      title: (
        <>
          <Popover
            style={{ width: 200 }}
            content={"Transaction type"}
            trigger="hover"
          >
            Type
          </Popover>
        </>
      ),
      dataIndex: "transaction",
      key: "transaction",
      render: (text) => <span>{text.type}</span>,
    },
    {
      title: (
        <>
          <Popover
            style={{ width: 200 }}
            content={"Transaction status"}
            trigger="hover"
          >
            STATUS
          </Popover>
        </>
      ),
      dataIndex: "transaction",
      key: "transaction",
      render: (text) => <span>{text.status}</span>,
    },
    {
      title: (
        <>
          <Popover
            style={{ width: 200 }}
            content={"Card asset label"}
            trigger="hover"
          >
            Asset Label
          </Popover>
        </>
      ),
      dataIndex: "card",
      key: "card",
      render: (text) => <span>{text.assetLabel}</span>,
    },
    {
      title: (
        <>
          <Popover
            style={{ width: 200 }}
            content={"Transaction Id"}
            trigger="hover"
          >
            Transaction Id
          </Popover>
        </>
      ),
      dataIndex: "transaction",
      key: "transaction",
      render: (text) => <span>{text.transactionId}</span>,
    },
  ];

  return (
    <div className={styles.table}>
      {!appUserTransactionLoading && appUserTransaction && (
        <>
          <Card>
            <Table
              className={styles.table}
              loading={appUserTransactionLoading}
              columns={columns}
              dataSource={appUserTransaction}
              exportable={false}
              scroll={{
                y: 750,
              }}
            />
          </Card>
        </>
      )}
      {appUserTransactionLoading && (
        <>
          <Loading isFullscreen={false} />
        </>
      )}
    </div>
  );
};

export default memo(Transactions);
