import React, { memo, useCallback, useEffect, useState } from "react";
import { Card, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "ui/Table/Table";
import Drawer from "ui/Drawer/Drawer";
import { AppUserType } from "features/appUsers/types/AppUserType";
import Loading from "ui/Loading/Loading";
import { getOrdersPerAppUser } from "features/appUsers/api/appUsersApi";
import { errorResponseMessages } from "api/apiErrorHelper";
import { useLocation } from "react-router-dom";
import styles from "./OrdersHistoryDrawer.module.scss";
import { AppUserOrder } from "features/appUsers/types/AppUserOrder";

interface IProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: (e) => void;
  refreshApi?: () => void;
  onClose?: () => void;
  appUser?: AppUserType;
  showDrawer?: () => void;
}

const OrdersHistoryDrawer = ({
  visible,
  refreshApi,
  onClose,
  appUser,
}: IProps) => {
  const [appOrdersLoading, setappOrdersLoading] = useState<boolean>(false);
  const [appUserOrder, setappUserOrder] = useState<AppUserOrder[]>();
  const location = useLocation();

  const onCloseDrawer = () => {
    onClose && onClose();
  };

  const geAppUserOrderAPI = useCallback(() => {
    if (appUser) {
      setappOrdersLoading(true);
      getOrdersPerAppUser(appUser?.id)
        .then((res) => {
          setappUserOrder(res);
        })
        .catch((e) => {
          errorResponseMessages(e);
        })
        .finally(() => {
          setappOrdersLoading(false);
        });
    }
  }, [appUser, visible]);

  useEffect(() => {
    geAppUserOrderAPI();
  }, [geAppUserOrderAPI]);

  const columns: ColumnsType = [
    {
      title: "Id",
      dataIndex: "id",
      key: "naidme",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "CardId",
      dataIndex: "card",
      key: "card",
      render: (card) => <span>{card.id}</span>,
    },
    {
      title: "TokenId",
      dataIndex: "tokenId",
      key: "tokenId",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "price",
      dataIndex: "price",
      key: "price",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "totalValue",
      dataIndex: "totalValue",
      key: "totalValue",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <Drawer
      placement="right"
      closable
      onClose={onCloseDrawer}
      visible={visible}
      width={"90%"}
    >
      <div className={styles.dropStatusHeadder}>
        {!appOrdersLoading && (
          <>
            <Card>
              <Table
                loading={appOrdersLoading}
                columns={columns}
                dataSource={appUserOrder}
              />
            </Card>
          </>
        )}
        {appOrdersLoading && (
          <>
            <Loading isFullscreen={false} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default memo(OrdersHistoryDrawer);
