import { memo, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  NavLink,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchUsersProfile, clearDetail } from "store/usersSlice";
import { Typography, Spin, Col, Row, Button } from "antd";
import styles from "./AppUsersDetail.module.scss";
import Details from "./components/AppUsersDetails/Details";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Transactions from "./components/AppUsersDetails/Transactions";
import Cards from "./components/AppUsersDetails/Cards";
import Payments from "./components/AppUsersDetails/Payments";
import Transfers from "./components/AppUsersDetails/Transfers";
import Orders from "./components/AppUsersDetails/Orders";
import StatusHistory from "./components/AppUsersDetails/StatusHistory";
import { goBack } from "helpers/navigationHelper";
import KycHistory from "./components/AppUsersDetails/KycHistory";

const { Title, Text } = Typography;

const AppUsersDetail = () => {
  let match = useRouteMatch();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();

  const profile = useSelector(
    (state: RootState) => state.users.detail.entities
  );

  const loading = useSelector((state: RootState) => state.users.detail.loading);

  useEffect(() => {
    dispatch(fetchUsersProfile(userId));
    return () => {
      dispatch(clearDetail());
    };
  }, [userId]);

  if (loading) {
    return (
      <div className={`${styles.detailContainer} ${styles.spinCenter}`}>
        <Spin />
      </div>
    );
  }

  const handleGoBack = () => {
    goBack(history, "/app-users", "app-user-previous-params");
  };

  return (
    <div className={styles.detailContainer}>
      <Button className={styles.backBtn} onClick={handleGoBack}>
        <ArrowLeftOutlined />
        <Text>All users</Text>
      </Button>
      {profile ? (
        <>
          <Title level={2}>{profile?.username}</Title>
          <Row>
            <Col span={24} className={styles.linkWrapper}>
              <NavLink
                to={`${match.url}/details`}
                className={(isActive) =>
                  isActive ? styles.active : styles.link
                }
              >
                Details
              </NavLink>
              <NavLink
                to={`${match.url}/transactions`}
                className={(isActive) =>
                  isActive ? styles.active : styles.link
                }
              >
                Transactions
              </NavLink>
              <NavLink
                to={`${match.url}/transfers`}
                className={(isActive) =>
                  isActive ? styles.active : styles.link
                }
              >
                Transfers
              </NavLink>
              <NavLink
                to={`${match.url}/status-history`}
                className={(isActive) =>
                  isActive ? styles.active : styles.link
                }
              >
                Status History
              </NavLink>
              <NavLink
                to={`${match.url}/kyc-history`}
                className={(isActive) =>
                  isActive ? styles.active : styles.link
                }
              >
                KYC History
              </NavLink>
              <NavLink
                to={`${match.url}/cards`}
                className={(isActive) =>
                  isActive ? styles.active : styles.link
                }
              >
                Cards
              </NavLink>
              <NavLink
                to={`${match.url}/payments`}
                className={(isActive) =>
                  isActive ? styles.active : styles.link
                }
              >
                Payment methods
              </NavLink>
              <NavLink
                to={`${match.url}/orders`}
                className={(isActive) =>
                  isActive ? styles.active : styles.link
                }
              >
                Orders
              </NavLink>
            </Col>
          </Row>
          <Switch>
            <Route path={`${match.path}/details`}>
              <Details profile={profile} />
            </Route>
            <Route path={`${match.path}/transactions`}>
              <Transactions />
            </Route>
            <Route path={`${match.path}/cards`}>
              <Cards />
            </Route>
            <Route path={`${match.path}/payments`}>
              <Payments />
            </Route>
            <Route path={`${match.path}/transfers`}>
              <Transfers />
            </Route>
            <Route path={`${match.path}/status-history`}>
              <StatusHistory profile={profile} />
            </Route>
            <Route path={`${match.path}/kyc-history`}>
              <KycHistory profile={profile} />
            </Route>
            <Route path={`${match.path}/orders`}>
              <Orders />
            </Route>
            <Redirect from="/" to={`${match.url}/details`} />
          </Switch>
        </>
      ) : (
        <Title level={3}>Profile Not Found</Title>
      )}
    </div>
  );
};

export default memo(AppUsersDetail);
