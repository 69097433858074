import React, { useState } from "react";
import { Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Form from "ui/Forms/Form";
import FormItem from "ui/Forms/FormItem";
import { Input, InputPassword } from "ui/Inputs/Inputs";
import { useHistory } from "react-router-dom";
import { PUBLIC_ROUTES } from "router/Router.config";
import UserPool from "../UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";

const SetNewPasswordCode = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const onFinish = (formData) => {
    setLoading(true);

    const user = new CognitoUser({
      Username: formData.email,
      Pool: UserPool,
    });

    user.confirmPassword(formData.code, formData.password, {
      onSuccess() {
        console.log("Password confirmed!");
        message.info(
          "Password updated successfully! Login with your new password."
        );
        history.push(PUBLIC_ROUTES.LOGIN_SCREEN.path);
      },
      onFailure(err) {
        console.log("Password not confirmed!");
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
    message.error("Something went wrong!");
  };

  return (
    <Form
      name="loginForms"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      layout={"vertical"}
      onFinishFailed={onFinishFailed}
    >
      <FormItem
        label={"Enter your email to request password reset"}
        name={"email"}
        key={"email"}
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input your e-mail!",
          },
        ]}
      >
        <Input
          type={"email"}
          prefix={<UserOutlined />}
          placeholder={"E-mail"}
          size={"large"}
        />
      </FormItem>

      <FormItem
        name={"code"}
        key={"code"}
        rules={[
          {
            required: true,
            message: "Please input your verification code",
          },
        ]}
      >
        <Input placeholder={"Verification code"} size="large" />
      </FormItem>

      <FormItem
        name={"password"}
        key={"password"}
        rules={[
          {
            required: true,
            message: "Please input your new password!",
          },
        ]}
      >
        <InputPassword
          prefix={<LockOutlined />}
          placeholder={"Password"}
          size="large"
        />
      </FormItem>

      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
};

export default SetNewPasswordCode;
