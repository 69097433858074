import { IMenuItemType } from "router/routerType";
import { IdcardOutlined, UserSwitchOutlined } from "@ant-design/icons";
import BannerDetails from "../screens/BannerDetailsScreen/BannerDetails";
import BannersScreen from "../screens/BannersScreen/BannersScreen";

/** Screen: Dashboard Page */
export const BANNERS_SCREEN: IMenuItemType = {
  id: "banners",
  path: "/banners",
  component: BannersScreen,
  title: "Banners",
  icon: IdcardOutlined,
  permissions: [],
};

export const BANNERS_DETAIL_SCREEN: IMenuItemType = {
  id: "bannerDetail",
  path: `/banners/:bannerId`,
  component: BannerDetails,
  title: "Banner Detail",
  icon: UserSwitchOutlined,
  permissions: [],
};
