import React, { PropsWithChildren } from "react";
import List, { ListItemProps } from "antd/lib/list";
import classnames from "classnames/bind";
import styles from "./ListItem.module.scss";

export enum ListItemType {
  PRIMARY = "primary",
}

interface IProps extends ListItemProps {
  children: any;
  type: any;
}
const cx = classnames.bind(styles);

const ListItem = ({
  className,
  children,
  type,
  ...rest
}: PropsWithChildren<IProps>) => {
  const classNames = cx(
    {
      [styles.primary]: type === ListItemType.PRIMARY,
    },
    className
  );
  return (
    <List.Item className={classNames} {...rest}>
      {children}
    </List.Item>
  );
};

export default ListItem;
