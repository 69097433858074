import { IMenuItemType } from "router/routerType";
import { PictureOutlined } from "@ant-design/icons";
import customCardsScreen from "../screens/CustomCardsScreen/customCardsScreen";

export const CUSTOM_CARDS_SCREEN: IMenuItemType = {
  id: "customCards",
  path: "/custom-cards",
  component: customCardsScreen,
  title: "Limited edition",
  icon: PictureOutlined,
  permissions: [],
};
